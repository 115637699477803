.contact-us__wrapper{
    margin-top: 120px;
    @media (max-width: 1366px) {
        margin-top: 80px;
    }
    @media (max-width: 540px) {
        margin-top: 50px;
    }
    .background{
        position: relative;
        width: 100%;
        height: 944px;
        @media (max-width: 768px) {
            height: 658px;
        }
       
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .overlay{
            background: map-get($mau, main-op-52 );
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 100%;
            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                // width: 832px;
                height: 100%;
                justify-content: center;
                .title {
                    text-align: center;
                    justify-self: unset;
                    width: 60%;
                    text-transform: uppercase;
                    text-overflow: ellipsis;
                    @media (max-width: 769px) {
                        width: 90%;
                    }
                    &>*{
                        font-size: 48px;
                        @media (max-width: 1367px) {
                            font-size: 38px;
                        }
                        @media (max-width: 1201px) {
                            font-size: 34px;
                        }
                        @media (max-width: 1024px) {
                            font-size: 30px;
                        }
                        @media (max-width: 769px) {
                            font-size: 26px;
                        }
                        color: map-get($mau, white-1);
                    }
                    margin-bottom: 52px;
                }
                .form{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 70%;
                    @media (max-width: 769px) {
                        width: 90%;
                    }
                    .form-control{
                        margin-bottom: 12px;
                        width: 100%;
                        height: 52px;
                        @media (max-width: 769px) {
                            height: 40px;
                        }
                        background: map-get($mau, white-1 );
                        &:first-child{
                            position: relative;
                            &::after{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 14px;
                                width: 0px;
                                height:0px;
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                border-top: 12px solid #555;
                                border-top-color: map-get($mau, orange-1 );
                                content: "";
                            }
                        }
                        
                        select,input{
                            cursor: pointer;
                            // position: relative;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            height: 100%;
                            width: 100%;
                            border: 0;
                            background-color: transparent;
                            padding-left: 14px;
                            color: map-get($mau, black-2 );
                            z-index: 1;
                            &::placeholder{
                                color: map-get($mau, black-2);
                            }
                        }
                    }
                    .btn{
                        text-align: center;
                    }
                }
                
            }
           
        }
    }
}
// @media (max-width: 1366px) {
//     .contact-us__wrapper{
//         margin-top: 80px;
//         .background{
//             position: relative;
//             width: 100%;
//             height: 708px;
//             .overlay{
//                 .container {
//                     width: 732px;
//                     .form{
//                         .form-control{
//                             height: 42px;
//                         }
//                         .btn{
//                             text-align: center;
//                             padding: 10px 15px;
//                         }
//                     }
                    
//                 }
            
//             }
//         }
//     }
// }

// @media (max-width: 768px) {
//     .contact-us__wrapper{
//         margin-top: 80px;
//         .background{
//             position: relative;
//             width: 100%;
//             height: 658px;
//             .overlay{
//                 .container {
//                     width: 75%;
//                     .form{
//                         .form-control{
//                             height: 42px;
//                         }
//                         .btn{
//                             text-align: center;
//                             padding: 10px 15px;
//                         }
//                     }
                    
//                 }
            
//             }
//         }
//     }
// }

// @media (max-width: 540px) {
//     .contact-us__wrapper{
//         margin-top: 50px;
//         .background{
//             position: relative;
//             width: 100%;
//             height: 658px;
//             .overlay{
//                 .container {
//                     width: 94%;
//                     .form{
//                         .form-control{
//                             height: 40px;
//                         }
//                         .btn{
//                             text-align: center;
//                             padding: 7px 10px;
//                         }
//                     }
                    
//                 }
            
//             }
//         }
//     }
// }