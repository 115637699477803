.your-moments-noon__wrapper{
    width: 100%;
    .your-moments-noon__title{
        text-align: center;
        margin-bottom: 52px;
        text-transform: uppercase;
        &>*{
            text-align: center;
        }
    }
    .your-moments-noon__pic{
        display: flex;
        height: 872px;
        width: 100%;
        justify-content: space-evenly;
        .swiper-button-next{
            right: 30px;
            background: map-get($mau, white-1);
        }
        .swiper-button-prev{
            left: 30px;
            background: map-get($mau, white-1);
        }
        .nav-custom{
            width: 50%;
            .mySwiperNoon1{
                width: 100%;
                height: 100%;
                .swiper-wrapper{
                    .swiper-slide{
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        position: relative;
                        img{
                            width: 100%;
                            height:  32.5% ;
                            object-fit: cover;
                        }
                        &:nth-child(4n+1){
                            justify-content: space-evenly;
                            &::after{
                                position: absolute;
                                top: 0;
                                left: 0;
                                // transform: translateY(-50%);
                                content: "";
                                background: map-get($mau, main );
                                width: 100%;
                                height: 32.5%;
                            }  
                            &::before{
                                position: absolute;
                                bottom:  0;
                                left: 0;
                                // transform: translateY(-50%);
                                content: "";
                                background: map-get($mau, box-color-2);
                                width: 100%;
                                height: 32.5%;
                            }
                        }
                        &:nth-child(2n){
                            &::after{
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                content: "";
                                background: map-get($mau, orange-1 );
                                width: 100%;
                                height: 32.5%;
                            }
                        }
                        &:nth-child(4n+3){
                            justify-content: space-evenly;
                            &::after{
                                position: absolute;
                                top: 0;
                                left: 0;
                                // transform: translateY(-50%);
                                content: "";
                                background: map-get($mau, box-color-1 );
                                width: 100%;
                                height: 32.5%;
                            }  
                            &::before{
                                position: absolute;
                                bottom:  0;
                                left: 0;
                                // transform: translateY(-50%);
                                content: "";
                                background: map-get($mau, main);
                                width: 100%;
                                height: 32.5%;
                            }
                        }
                    }
                }
                
            }
            
        }
        
        .img-right{
            width: 49%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
@media (max-width: 1440px) {
    .your-moments-noon__wrapper{
        .your-moments-noon__pic{
            height: 100%;
           
        }
    }
}
// @media (max-width: 1366px) {
//     .your-moments-noon__wrapper{
//         .your-moments-noon__pic{
//             height: 572px;
           
//         }
//     }
// }
// @media (max-width: 1024px) {
//     .your-moments-noon__wrapper{
//         .your-moments-noon__pic{
//             height: 472px;
            
//         }
//     }
// }
@media (max-width: 768px) {
    .your-moments-noon__wrapper{
        .your-moments-noon__pic{
            .nav-custom{
                .swiper-button-next,
                .swiper-button-prev{
                    width: 30px;
                    height: 30px;
                    &::after{
                        background-size: 12px;
                        transform: unset;
                    }
                }
            }
        }
    }
}
@media (max-width: 430px) {
    .your-moments-noon__wrapper{
        .your-moments-noon__title{
            margin-bottom: 22px;
        }
        .your-moments-noon__pic{
            height: 372px;
            flex-direction: column;
            .nav-custom{
                width: 100%;
                height: 100%;
                .swiper-button-next,
                .swiper-button-prev{
                    display: block;
                    width: 30px;
                    height: 30px;
                    &::after{
                        display: block;
                        background-size: 12px;
                        transform: unset;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .img-right{
                display: none;
            }

            }
        }
    }
