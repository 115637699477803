.services-class__wrapper{
    overflow: hidden;
    .services-class {
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
            min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
            min-height: 250px;
        }
        .banner{
            overflow: hidden;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            img{
                // transform: translateY(60px);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .overlay-text-banner{
                // width: 100%;
                position: absolute;
                top: 60%;
                left: 100px;
                transform: translateY(-50%);
                z-index: 2;
                @media (max-width: 1024px) {
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    width: 80%;
                }
                .text-section{
                    width: 400px;
                    @media (max-width: 1024px) {
                        width: 100%;
                    }
                    .title{
                        text-transform: uppercase;
                    }
                    *:not(.text-48,:last-child){
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .overlay-pattern{
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            visibility: visible;
            @media (max-width: 1024px) {
                width: 160%;
            }
            img{
                min-width: 786px;
                @media (max-width: 1024px) {
                    width: 100%;
                }
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

// @media ( max-width: 1366px) {
//     .services-class__wrapper{
//         .services-class {
//             .banner{
//                 .overlay-text-banner{
//                     left: 60px;
//                     transform: translateY(-50%);
//                     z-index: 2;
//                     .text-section{
//                         width: 350px;
//                         .title{
//                             text-transform: uppercase;
//                         }
//                         *:not(.text-48,:last-child){
//                             margin-bottom: 30px;
//                         }
//                     }
//                 }
//             }
//             .overlay-pattern{
//                 height: 100%;
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 visibility: visible;
//                 img{
//                     height: 100%;
//                 }
//             }
//         }
//     }
// }
// @media ( max-width: 1024px) {
//     .services-class__wrapper{
//         .services-class {
//             height: 100%;
//             .banner{
//                 img{
//                     transform: unset;
//                 }
//                 .overlay-text-banner{
//                     top: 50%;
//                     left: 20px;
//                     .text-section{
                        
//                         width: 300px;
//                         *:not(.text-48,:last-child){
//                             margin-bottom: 10px;
//                         }
//                     }
//                 }
//             }
//             .overlay-pattern{
//                 img{
//                     height: 100%;
//                 }
//             }
//         }
//     }
// }
// @media ( max-width: 768px) {
//     .services-class__wrapper{
//         .services-class {
//             .banner{
//                 .overlay-text-banner{
//                     .text-section{
                        
//                         width: 200px;
//                         *:not(.text-48,:last-child){
//                             margin-bottom: 10px;
//                         }
//                         .title{
//                             display: -webkit-box;
//                             -webkit-line-clamp: 3;
//                             -webkit-box-orient: vertical;
//                             overflow: hidden;
//                         }
//                         .decs{
//                             display: -webkit-box;
//                             -webkit-line-clamp: 3;
//                             -webkit-box-orient: vertical;
//                             overflow: hidden;
//                         }
//                     }
//                 }
//             }
            
//         }
//     }
// }
// @media ( max-width: 540px) {
//     .services-class__wrapper{
//         .services-class {
//             .banner{
//                 .overlay-text-banner{
//                     .text-section{
//                         width: 120px;
//                     }
//                 }
//             }
            
//         }
//     }
// }
// @media ( max-width: 380px) {
//     .services-class__wrapper{
//         .services-class {
//             .banner{
//                 .overlay-text-banner{
//                     .text-section{
//                         width: 80px;
//                     }
//                 }
//             }
            
//         }
//     }
// }