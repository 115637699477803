.your-moments-night__wrapper{
    .your-moments-night__title{
        text-align: center;
        margin-bottom: 52px;
        text-transform: uppercase;
        &>*{
            text-align: center;
        }
    }
    .your-moments-night__pic{
        .swiper-button-next{
            right: 30px;
            background: map-get($mau, white-1);
        }
        .swiper-button-prev{
            left: 30px;
            background: map-get($mau, white-1);
        }
        height: 872px;
        .mySwiperNight1{
            margin-bottom: 10px;
            .swiper-wrapper{
                align-items: center;
                .swiper-slide-active{
                    transition: height 0.5s ease-in-out;
                    width: 60% !important;
                    height: 872px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .swiper-slide-next,
                .swiper-slide-prev{
                    width: 41vw !important;
                    height: 618px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            
        }
        .swiper-button-next{
            right: 20px;
        }
        .swiper-button-prev{
            left: 20px;
        }
    }
}

@media (max-width: 1440px) {
    .your-moments-night__wrapper{
        .your-moments-night__pic{
            height: 672px;
            .mySwiperNight1{
                .swiper-wrapper{
                    .swiper-slide-active{
                        height: 672px;
                    }
                    .swiper-slide-next,
                    .swiper-slide-prev{
                        height: 518px;
                    }
                }
                
            }
        }
    }
    
}
@media (max-width: 1366px) {
    .your-moments-night__wrapper{
        .your-moments-night__pic{
            height: 572px;
            .mySwiperNight1{
                .swiper-wrapper{
                    .swiper-slide-active{
                        height: 572px;
                        width: 55% !important;
                    }
                    .swiper-slide-next,
                    .swiper-slide-prev{
                        height: 418px;
                        width: 45vw !important;
                    }
                }
                
            }
        }
    }
    
}
@media (max-width: 1024px) {
    .your-moments-night__wrapper{
        .your-moments-night__pic{
            height: 472px;
            .mySwiperNight1{
                .swiper-wrapper{
                    .swiper-slide-active{
                        height: 472px;
                        width: 55% !important;
                    }
                    .swiper-slide-next,
                    .swiper-slide-prev{
                        height: 318px;
                        width: 45vw !important;
                    }
                }
                
            }
        }
    }
    
}
@media (max-width: 430px) {
    .your-moments-night__wrapper{
        .your-moments-night__title{
            margin-bottom: 22px;
        }
        .your-moments-night__pic{
            height: 272px;
            @media (max-width:380px) {
                height: 258px;
            }
            @media (max-width:320px) {
                height: 208px;
            }
            .mySwiperNight1{
                .swiper-wrapper{
                    .swiper-slide-active{
                        height: 272px;
                        @media (max-width:380px) {
                            height: 258px;
                        }
                        @media (max-width:320px) {
                            height: 208px;
                        }
                        width: 55% !important;
                    }
                    .swiper-slide-next,
                    .swiper-slide-prev{
                        height: 218px;
                        @media (max-width:380px) {
                            height: 188px;
                        }
                        @media (max-width:320px) {
                            height: 158px;
                        }
                        width: 42vw !important;
                    }
                }
                
            }
        }
    }
    
}
// @media (max-width: 430px) {
//     .your-moments-night__wrapper{
//         .your-moments-night__pic{
//             height: 272px;
//             .mySwiperNight1{
//                 .swiper-wrapper{
//                     .swiper-slide-active{
//                         width: 256px !important;
//                         height: 272px;
                    
//                     }
//                     .swiper-slide-next,
//                     .swiper-slide-prev{
//                         @media (max-width:380px) {
//                             width: 35% !important;
//                         }
//                         @media (max-width:320px) {
//                             width: 30% !important;
//                         }
//                         width: 40% !important;
//                         height: 218px;
//                     }
//                 }
                
//             }
//         }
//     }
    
// }