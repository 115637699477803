.services-class__complementary{
    text-align: center;
    
    .content{
        .nav-custom{
            @media (max-width: 540px) {
                height: 400px;
            }
            @media (max-width: 430px) {
                height: 350px;
            }
            @media (max-width: 380px) {
                height: 300px;
            }
            .mySwiperService-Class{
                height: 100%;
            }
            .swiper-button-next,
            .swiper-button-prev{
                @media (max-width: 1367px) {
                    width: 40px;
                    height: 40px;
                }
                @media (max-width: 630px) {
                    display: inline-flex;
                }
                @media (max-width: 540px) {
                    display:none;
                }
               
            }
            .swiper-button-prev{
                
                @media (max-width: 1120px) {
                    left: 10px;
                    
                }
            }
            .swiper-button-next{
                
                @media (max-width: 1120px) {
                    right: 10px;
                    
                }
            }
           
        }
        .swiper-wrapper{
            padding-bottom: 26px;
        }
        .swiper-slide{
            height: unset;
            @media (max-width: 540px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .box{
                display: flex;
                flex-direction: column;
                &:not(:last-child){

                    margin-bottom: 20px;
                    @media (max-width: 540px) {
                        margin-bottom: unset;
                    }
                }
                // height: 672px;
                height: 50%;
                width: 100%;
                @media (max-width: 540px) {
                    height: 100%;
                    width: 49%;
                }
                background-color: map-get($mau,main-1);
                cursor: pointer;
                box-shadow: 0 3px 6px rgba(0,0,0,.16);
                .images{
                    width: 100%;
                    padding-bottom: 60%;
                    position: relative;
                    overflow: hidden;
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        // object-fit: contain;
                    }
                }
                
                &:hover{
                    background-color: map-get($mau,white-1);
                }
                .text{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    // padding: 40px 0;
                    text-align: start;
                    padding: 40px 90px 20px 30px;
                    @media (max-width: 768px) {
                        padding: 20px;
                    }
                    
                    @media (max-width: 450px) {
                        padding: 15px;
                    }
                    
                    .title{
                        color: map-get($mau,text-gray-1);
                        background-color: none;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        font-family: ROBOTO-MEDIUM;
                    }
                    .subtext{
                        margin: 20px 0;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        @media (max-width: 768px) {
                            margin: 10px 0;
                       
                        }
                        
                        color: map-get($mau,text-gray-2); 
                    }
                    .btn{
                        border: none;
                        padding: 0;
                        color: map-get($mau, orange-1);
                        background: none;
                    }
                }
            }
        }
    }
}
