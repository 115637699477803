.home-banner__wrapper{


    .home-banner {
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
            min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
            min-height: 300px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
            min-height: 250px;
        }
        .banner{
            overflow: hidden;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            img{
                // transform: translate( 24px, 60px);
                // transform: translateX(2px);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .overlay-text-banner{
                width: 100%;
                position: absolute;
                top: 56%;
                left: 0;
                transform: translateY(-50%);
                z-index: 2;
                @media (max-width: 1660px) {
                    left: 15%;
                    width: 45%;
                }
                @media (max-width: 640px) {
                    left: 20%;
                    width: 60%;
                }
                .text-section{
                    width: 50%;
                    @media (max-width: 1660px) {
                        width: 100%;
                    }
                    
                    .text-24{
                        font-family: ROBOTO-MEDIUM;
                    }
                    .title{
                        
                        text-transform: uppercase;
                    }
                    .decs,.title{
                        display: -webkit-box ;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    *:not(.text-48,:last-child){
                        margin-bottom: 30px;
                        @media (max-width: 640px) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
        .overlay-pattern{
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: map-get($mau, main-op-52 );
            visibility: visible;
            img{
                height: 100%;
                object-fit: contain;
            }
        }
    }
}



