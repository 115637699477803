$mau: (
	main: #1D5632,
	main-op-80: rgba(29,86,50,0.8),
	main-1: #EBF0ED,
	main-2: #E7ECE9,
	main-op-65:rgba(1,42,16,0.65),
	main-op-56:rgba(29,86,50,0.56),
	main-op-52: rgba(1,42,16,0.52),
	main-op-20: rgba(29,86,50,0.2),
	main-gray: #D8E2DC,
	main-gray-op-17: rgba(216,226,220,0.17),
	text-gray-1:#757575,
	text-gray-2: #9E9E9E,
	text-gray-3: #E0E0E0,
	text-gray-4: #707070,
	orange-1:#FF8300,
	white-1: #ffffff,
	white-op-50: rgba(255,255,255,0.5),
	white-op-70: rgba(255,255,255,0.7),
	black-1: #000000,
	black-2:#212121,
	black-op-8: rgba(0,0,0,0.08),
	black-op-12: rgba(0,0,0,0.12),
	box-color-1: #728338,
	box-color-2: #B5AC3D,
	card-red:#811315,
	card-green: #275E3F,
	card-yellow: #A29D0E,
	card-purple: #5B2F50

);

// font
.text-14{
	font-size: 14px;
	@media (max-width: 1367px) {
		font-size: 14px;
	}
	@media (max-width: 1201px) {
		font-size: 12px;
	}
	@media (max-width: 769px) {
		font-size: 12px;
	}
	@media (max-width: 430px) {
		font-size: 9px;
	}
	@media (max-width: 320px) {
		font-size: 8px;
	}
}
.text-16{
	font-size: 16px;
	@media (max-width: 1367px) {
		font-size: 14px;
	}
	@media (max-width: 1201px) {
		font-size: 14px;
	}
	@media (max-width: 769px) {
		font-size: 14px;
	}
	@media (max-width: 430px) {
		font-size: 12px;
	}
	@media (max-width: 320px) {
		// font-size: 8px;
	}
}
.text-20{
	font-size: 20px;
	@media (max-width: 1367px) {
		font-size: 18px;
	}
	@media (max-width: 1201px) {
		font-size: 16px;
	}
	@media (max-width: 1024px) {
		font-size: 16px;
	}
	@media (max-width: 430px) {
		font-size: 13px;
	}
	@media (max-width: 320px) {
		font-size: 13px;
	}
}
.text-22{
	font-size: 22px;
	@media (max-width: 1367px) {
		font-size: 18px;
	}
	@media (max-width: 1201px) {
		font-size: 18px;
	}
	@media (max-width: 1024px) {
		font-size: 16px;
	}
	@media (max-width: 430px) {
		font-size: 13px;
	}
	@media (max-width: 320px) {
		font-size: 11px;
	}
}
.text-24{
	font-size: 24px;
	@media (max-width: 1367px) {
		font-size: 24px;
	}
	@media (max-width: 1201px) {
		font-size: 22px;
	}
	@media (max-width: 1025px) {
		font-size: 20px;
	}
	@media (max-width: 769px) {
		font-size: 18px;
	}
	@media (max-width: 430px) {
		font-size: 14px;
	}
	@media (max-width: 320px) {
		font-size: 13px;
	}
}
.text-48{
	font-size: 48px;
	@media (max-width: 1367px) {
		font-size: 38px;
	}
	@media (max-width: 1201px) {
		font-size: 34px;
	}
	@media (max-width: 1024px) {
		font-size: 30px;
	}
	@media (max-width: 769px) {
		font-size: 26px;
	}
	@media (max-width: 430px) {
		font-size: 20px;
	}
	@media (max-width: 320px) {
		font-size: 13px;
	}
}

// marign box
.margin-tb-104{
	margin: 104px 0;
	@media (max-width: 1367px) {
		margin: 84px 0;
	}
	
	@media (max-width: 1024px) {
		margin: 64px 0;
	}
	@media (max-width: 430px) {
		margin: 35px 0;
	}
	@media (max-width: 320px) {
		margin: 30px 0;
	}
}
.margin-tb-52{
	margin: 52px 0;
	@media (max-width: 1367px) {
		margin: 45px 0;
	}
	
	@media (max-width: 1024px) {
		margin: 38px 0;
	}
	@media (max-width: 430px) {
		margin: 17px 0;
	}
	@media (max-width: 320px) {
		margin: 10px 0;
	}
}

// padding box
.padding-tb-104{
	padding: 104px 0;
	@media (max-width: 1367px) {
		padding: 84px 0;
	}
	
	@media (max-width: 1024px) {
		padding: 64px 0;
	}
	@media (max-width: 430px) {
		padding: 22px 0;
	}
	@media (max-width: 320px) {
		// margin: 104px 0;
	}
}

// text-color
.text-white{
	color: map-get($mau, white-1);
}
.text-white-op-50{
	color: map-get($mau, white-op-50);
}
.text-white-op-70{
	color: map-get($mau, white-op-70);
}
.text-gray-1{
	color: map-get($mau, text-gray-1);
}
.text-gray-2{
	color: map-get($mau, text-gray-2);
}
.text-gray-3{
	color: map-get($mau, text-gray-3);
}
.text-black-2{
	color: map-get($mau, black-2);
}
.text-orange-1{
	color: map-get($mau, orange-1);
}

// border 
.border-radius-20{
	border-radius: 20px 0;
}
.border-radius-50{
	border-radius: 50px 0;
	@media (max-width: 430px) {
		border-radius: 20px 0;
	}
}