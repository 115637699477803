.ourservices-membership{
    // text-align: center;
    &__title{
        margin-bottom: 75px;
        @media (max-width: 540px) {
            margin-bottom: 10vw;
        }
        &>*{
            text-align: center;
        }
    }
    &__container{
        display: flex;
        // justify-content: center;
        flex-wrap: wrap;
        &:last-child{
            margin:none;
        }
        .box-content{
            @media (max-width: 768px) {
                margin-bottom: 12vw;
            }
            @media (max-width: 380px) {
                min-width: 85%;
                margin-left: auto;
                margin-right: auto;
            }
            &__card{
                cursor: pointer;
                box-shadow:  0 3px 6px rgba(0,0,0,.16);
                border-radius: 20px;
                transition: 0.3s ease-in-out;
                display: flex;
                flex-direction: column;
                height: 100%;
                &:hover{
                    background-color: map-get($mau,main-1);
                }
                .header{
                    position: relative;
                    padding-bottom: 53%;
                    background-color: map-get($mau,main-1);
                    border-radius: 20px 20px 0 0;
                    .card-view{
                        position: absolute;
                        top: -20%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 90%;
                        border-radius: 7px;
                        overflow: hidden;
                        height: 105%;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .footer{
                    margin-left: auto;
                    margin-right: auto;
                    width: 80%;
                    padding-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .title{
                        margin: 20px 0;
                        @media (max-width: 540px) {
                            margin: 3vw 0;
                        }
                        color: map-get($mau, text-gray-1);
                        font-family: ROBOTO-MEDIUM;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        overflow: hidden
                    }
                    .text{
                        color: map-get($mau, text-gray-2);
                        margin-bottom: 20px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 7;
                        display: -webkit-box;
                        overflow: hidden
                    }
                    .btn{
                        border: none;
                        padding: 0;
                        color: map-get($mau, orange-1);
                        background: none;
                        margin-top: auto;
                        display: inline-block;
                        span{
                            display: none;
                        }
                    }
                }
            }
            
        }
    }
}
#overlay-card.active{
    opacity: 1;
    visibility: visible;
}
#overlay-card{
    transition: 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    background: map-get($mau, card-red );
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    .btn-close{
        z-index: 1;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0) translateY(-50%);
        transform: translateZ(0) translateY(-50%);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden;
        background: map-get($mau, text-gray-1 );
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.6s;
        transition-duration: 0.6s;
        // @media (max-width: 1919px) {
        //    top: 2vh;
        //    width: 4vh;
        //    height: 4vh;
        // }
        width: 40px;
        height: 40px;
        top: 50%;
        right:-70px;
        border-radius: 5px;
        cursor: pointer;
        @media (max-width: 1140px) {
           top: 20px;
           right: 40px;
           width: 40px;
           height: 40px;
           -webkit-transform: translateZ(0) ;
            transform: translateZ(0) ;
        }
        @media (max-width: 540px) {
           top: 10px;
           right: 30px;
           width: 30px;
           height: 30px;
        }
        &::after{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: white;
            content: "\2716"
        }
        &:hover{
            &::after{
                color: black;
            }
        }
        &::before{
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: map-get($mau, orange-1 );
            border-radius: 100%;
            -webkit-transform: scale(0);
            transform: scale(0);
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.6s ;
            transition-duration: 0.6s ;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
           
        }
        &:hover:before, &:focus:before, &:active:before {
            -webkit-transform: scale(2);
            transform: scale(2);
          }
    }
    .card-section{
        width: 100%;
        overflow: hidden;
        height: 80%;
        background: map-get($mau, main-1 );
        .container{
            position: relative;
            background: map-get($mau, white-1 );
            background-image: url("../assets/images/our-services/bg-overlay-card.png");
            background-repeat: no-repeat;
            background-position-y: 100%;
            height: 100%;
            background-size: 100%;
            
            .nav-custom{
                .wrapper.active{
                    display: block;
                }
                .wrapper{
                    &>*{
                        color: #9e9e9e;;
                    }
                    @media (max-width: 1919px) {
                        height: 85vh;
                    }
                    width: 100%;
                    max-height: 764px;
                    position: unset;
                    overflow-y: scroll;
                    
                    display: none;
                    height: 80%;
                    @media (max-width: 768px) {
                        padding: 0 80px;
                    }
                    @media (max-width: 430px) {
                        padding: 0 20px;
                    }
                    padding: 0 100px;
                    .title{
                        text-align: center;
                        font-family: ROBOTO-MEDIUM;
                        @media (max-width: 768px) {
                            margin: 40px 0;
                        }
                        @media (max-width: 430px) {
                            margin: 25px 0;
                        }
                        margin: 50px 0;
                        text-transform: uppercase;
                        font-size: 32px;
                        @media (max-width: 768px) {
                            font-size: 26px;
                        }
                        @media (max-width: 430px) {
                            font-size: 20px;
                        }
                    }
                    .text-section{
                        display: flex;
                        justify-content: space-between;
                        @media (max-width:430px) {
                            flex-direction: column;
                        }
                        .decs{
                            width: 100%;
                            @media (max-width:430px) {
                                width: 100%;
                            }
                            p{
                                margin-bottom: 35px;
                                line-height: 1.4;
                            }
                        }
                    }
                
                    
                }
            }
        }
        
    }
    
}
