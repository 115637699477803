.home-2__wrapper {
    // padding: 100px 0;
    .container{
        position: relative;
        .img-section{
            width: 724px;
            height: 416px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .text-section{
            padding: 0 40px;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 724px;
            height: 304px;
            background: map-get($mau, white-1 );
            box-shadow: 1px 1px 7px 0 map-get($mau, black-op-8 );
            .text-24{
                font-family: ROBOTO-MEDIUM;
            }
            .text{
                width: 70%;
                *:not(:last-child){
                    margin-bottom: 20px;
                }
            }
        }
        .overlay{
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
@media (max-width: 1366px) {
    .home-2__wrapper {
        // padding: 100px 0;
        .container{
            position: relative;
            .img-section{
                width: 50%;
                height:100%;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .text-section{
                width: 624px;
                height: 250px;
            }
            .overlay{
                
                height: 100%;
                img{
                    height: 100%;
                }
            }
        }
    }
}
// @media (max-width: 1201px) {
//     font-size: 20px;
// }
@media (max-width: 1024px) {
    .home-2__wrapper {
        .container{
            position: relative;
            .text-section{
                width: 600px;
            }
        }
    }
}
@media (max-width: 768px) {
    .home-2__wrapper {
        .container{
            position: relative;
            .img-section{
                width: 100%;
                height: 100%;
            }
            .text-section{
                position: relative;
                transform: unset;
                width: 100%;
               
            }
            .overlay{
                position: absolute;
            }
        }
    }
}
@media (max-width: 768px) {
    .home-2__wrapper {
        margin-bottom: 150px;
        .container{
            position: relative;
            .text-section{
                top: unset;
                width: 90%;
                position: absolute;
                bottom: -100px;
                left: 50%;
                transform: translate(-50%);
                padding: 0 30px;
                height: 170px;
                .text{
                    *:not(:last-child){
                        margin-bottom: 10px;
                    }
                    .decs{
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
@media (max-width: 430px) {
    .home-2__wrapper {
        .container{
            .text-section{
                bottom: -120px;
            }
        }
    }
}
@media (max-width: 320px) {
    .home-2__wrapper {
        margin-bottom: 140px;
        .container{
            position: relative;
            .text-section{
                padding: 0 20px;
                height: 170px;
            }
        }
    }
}