.infor-news-events-banner__wrapper{
    .infor-news-events-banner {
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
            min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
            min-height: 250px;
        }
        .banner{
            overflow: hidden;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            img{
                // transform: translateY(30px);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .overlay-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 34%;
            background: map-get($mau, black-op-12 );
            position: absolute;
            bottom: 0;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            .title{
                text-transform: uppercase;
                width: 654px;
                margin-bottom: 20px;
            }
            .decs{
                width: 504px;
            }
        }
    }
}

@media ( max-width: 1366px) {
    .infor-news-events-banner__wrapper{
        .infor-news-events-banner {
            .banner{
                img{
                    transform: unset;
                    width: 100%;
                    height: 100%;
                }
            }
            .overlay-text{
                height: 240px;
                .title{
                    margin-bottom: 15px;
                }
            }
        }
    }
}
@media ( max-width: 1024px) {
    .infor-news-events-banner__wrapper{
        .infor-news-events-banner {
            height: 100%;
            .overlay-text{
              
                width: 100%;
                height: 34%;
                background: map-get($mau, black-op-12 );
                position: absolute;
                bottom: 0;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                .title{
                    text-transform: uppercase;
                    width: unset;
                    // margin-bottom: 20px;
                }
                .decs{
                    display: none;
                }
            }
        }
    }
}