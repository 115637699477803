.home-5__wrapper{
    .home-5{
        // background-attachment: fixed;
        // background-image: url("../assets/images/home-page/img-i-5.png");
        // background-position: center;
        // background-size: cover;
        // height: 585px;
        width: 100%;
        padding-bottom: 30%;
        position: relative;
        min-height: 200px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}