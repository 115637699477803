.news-events-2__wrapper{
    .container{
        display: flex;
        @media (max-width: 900px) {
            flex-direction: column;
            
        }
    }
    .news-events-2__left{
        width: 30%;
        @media (max-width: 900px) {
            width: 100%;

        }
        .list{
            transition: 0.3s ease-in-out;
            border-right: solid 1px map-get($mau, black-op-12 );
            width: 319px;
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            @media (max-width: 900px) {
                flex-direction: row;
                width: 100%;
                border-right: unset;
            }
            .items{
                padding: 2px 0;
                margin-left: 20px;
                margin-bottom: 80px;
                position: relative;
               
                @media (max-width: 900px) {
                    white-space: nowrap;
                    margin-bottom: unset;
                    display: flex;
                    align-items: center;
                    padding: 5px 40px 5px 10px;
                    margin-right: 10px;
                    @media (max-width: 540px) {
                        padding: 5px 20px 5px 10px;
                    }
                    &::before{
                        top: 50%;
                        // transform: translateY(-50%);
                        left: -15px;
                        border-top: 7px solid transparent;
                        border-left: 9px solid #555;
                        border-bottom: 7px solid transparent;
                        border-left-color: map-get($mau, orange-1 );
                    }
                    
                }
                &:hover{
                    background: map-get($mau , main-2 );
                    
                }
                &::before{
                    position: absolute;
                    top: 7px;
                    content: "";
                    left: -20px;
                    width: 0;
                    height: 0;
                    border-top: 8px solid transparent;
                    border-left: 10px solid #555;
                    border-bottom: 8px solid transparent;
                    border-left-color: map-get($mau, orange-1 );
               }
            }
            .active{
                background: map-get($mau , main-2 );
                a{
                    color: map-get($mau, black-2);
                }
            }
            &--items{
                font-family: ROBOTO-MEDIUM;
                color: map-get($mau, text-gray-2 );
                margin-left: 20px;
                @media (max-width: 900px) {
                    margin-left: 10px;
                }
                width: 100%;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }
    .news-events-2__right{
        width: 70%;
        @media (max-width: 900px) {
            width: 90%;
            margin-top: 40px;
            margin: auto;
        }
        &--items{
            // display: none;
            animation: fadeEffect 1s;
            // &.active-news{
            //     display: block;
            // }
            .nav-custom{
                max-height: 1080px;
                overflow: scroll;
                @media (max-width: 1024px) {
                    max-height: 650px;
                }
                @media (max-width: 540px) {
                    max-height: 450px;
                }
            }
        }
        @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
        }
        padding-left: 104px;
        @media (max-width: 1024px) {
            padding-left: 84px;
        }
        @media (max-width: 900px) {
            padding-left: 0;
        }
        .head-title{
            border-bottom: solid 1px map-get($mau, black-op-12 );
            *{
                margin-bottom: 22px;
            }
            .title{
                text-transform: uppercase;
                &:hover{
                    color: map-get($mau , main );
                }
            }
            .title-1{
                font-family: ROBOTO-MEDIUM;
            }
        }
        .news-events{
            .nav-custom{
                margin: 0 -8px;

            }
            .swiper-slide{
                &:not(:last-child){

                    border-bottom: solid 1px map-get($mau, black-op-12 );
                    margin-bottom: 52px;
                    padding-bottom: 52px;
                    @media (max-width: 640px) {
                        margin-bottom: 32px;
                        padding-bottom: 32px;
                    }
                    @media (max-width: 380px) {
                        margin-bottom: 22px;
                        padding-bottom: 22px;
                    }
                }
                // padding:52px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .decs{
                    width: 50%;
                    padding: 0 8px;
                    max-width: 300px;
                    *{
                        margin-bottom: 10px;
                    }
                    .title-1{
                        text-transform: uppercase;
                    }
                    .title{
                        transition: 0.2s ease-in-out;
                        font-family: ROBOTO-MEDIUM;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        &:hover{
                            color: map-get($mau , main );
                        }
                    }
                    &-text{
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                    }
                }
                .image{
                    padding: 0 8px;
                    width: 50%;
                    height: 100%;
                    overflow: hidden;
                    max-height: 280px;
                    padding-bottom: 35%;
                    position: relative;
                    @media (max-width: 900px) {
                        max-width: 350px;
                        padding-bottom: 30%
                    }
                    @media (max-width: 540px) {
                        padding-bottom: 35%
                    }
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .news-events__items{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    .decs{
                        width: 308px;
                        *{
                            margin-bottom: 10px;
                        }
                        .title-1{
                            text-transform: uppercase;
                        }
                        .title{
                            transition: 0.2s ease-in-out;
                            font-family: ROBOTO-MEDIUM;
                            &:hover{
                                color: map-get($mau , main );
                            }
                        }
                    }
                    .image{
                        display: flex;
                        align-items: center;
                        width: 408px;
                        height: 100%;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            
        }
    }
}
// @media (max-width: 1366px) {
//     .news-events-2__wrapper{
//         .news-events-2__left{
//             .list{
//                 transition: 0.3s ease-in-out;
//                 border-right: solid 1px map-get($mau, black-op-12 );
//                 width: 219px;
//                 padding-right: 20px;
//                 .items{
//                     margin-left: 15px;
//                     margin-bottom: 70px;
//                     &::before{
//                         top: 4px;
//                    }
//                 }
               
//                 &--items{
//                     margin-left: 0px;
//                 }
//             }
//         }
//         .news-events-2__right{
//             padding-left: 80px;
//             .head-title{
//                 *{
//                     margin-bottom: 20px;
//                 }
//             }
//         }
       
//     }
// }

// @media (max-width: 1201px) {
//     .news-events-2__wrapper{
//         .swiper-scrollbar{
//             @media (max-width: 1190px) {
//                 display: none;
//             }
//      }
       
//         .news-events-2__left{
//             .list{
//                 transition: 0.3s ease-in-out;
//                 border-right: solid 1px map-get($mau, black-op-12 );
//                 width: 219px;
//                 padding-right: 20px;
//                 .items{
//                     margin-left: 15px;
//                     margin-bottom: 70px;
//                     &::before{
//                         top: 4px;
//                    }
//                 }
               
//                 &--items{
//                     margin-left: 0px;
//                 }
//             }
//         }
//         .news-events-2__right{
//             padding-left: 80px;
//             .head-title{
//                 *{
//                     margin-bottom: 20px;
//                 }
//             }
//         }
//     }
    
// }
// @media (max-width: 1024px) {
//     .news-events-2__wrapper{
//             .swiper-scrollbar{
//                 right: 20px;
//                display: flex;
//             }
//         .container{
//             flex-direction: column;
//         }
//         .news-events-2__left{
//             overflow: scroll;
//             margin-bottom: 52px;
//             &::-webkit-scrollbar {
//                 display: none;
//             }
//             .list{
//                 width: unset;
//                 display: inline-flex;
//                 border-right: unset;
//                 .items{
//                     white-space: nowrap;
//                     margin-bottom: unset;
//                     display: flex;
//                     align-items: center;
//                     padding: 5px 40px 5px 10px;
//                     margin-right: 10px;
//                     &::before{
//                         top: 50%;
//                         transform: translateY(-50%);
//                         left: -15px;
//                         border-top: 7px solid transparent;
//                         border-left: 9px solid #555;
//                         border-bottom: 7px solid transparent;
//                         border-left-color: map-get($mau, orange-1 );
//                    }
//                 }
               
//                 &--items{
//                     margin-left: unset;
//                     width: 100%;
//                     text-transform: uppercase;
                   
//                 }
//             }
//         }
//         .news-events-2__right{
//             padding-left: 0;
//             .news-events{
//                 .myNewsEvent{
//                     height: 650px;
//                     width: 80%;
//                 }
//                 .swiper-slide{
//                     .image{
//                         display: flex;
//                         align-items: center;
//                         width: 308px;
//                         height: 100%;
//                         img{
//                             width: 100%;
//                             height: auto;
//                         }
//                     }
                    
//                 }
                
//             }
//         }
//     }
// }
// @media (max-width: 430px) {
//     .news-events-2__wrapper{
//         .container{
//             flex-direction: column;
//         }
//         .news-events-2__left{
//             overflow: scroll;
//             margin-bottom: 52px;
//             &::-webkit-scrollbar {
//                 display: none;
//             }
//             .list{
//                 width: unset;
//                 display: inline-flex;
//                 border-right: unset;
//                 .items{
//                     white-space: nowrap;
//                     margin-bottom: unset;
//                     display: flex;
//                     align-items: center;
//                     padding: 5px 40px 5px 10px;
//                     margin-right: 10px;
//                     &::before{
//                         top: 50%;
//                         transform: translateY(-50%);
//                         left: -15px;
//                         border-top: 7px solid transparent;
//                         border-left: 9px solid #555;
//                         border-bottom: 7px solid transparent;
//                         border-left-color: map-get($mau, orange-1 );
//                    }
//                 }
               
//                 &--items{
//                     margin-left: unset;
//                     width: 100%;
//                     text-transform: uppercase;
                   
//                 }
//             }
//         }
//         .news-events-2__right{
//             padding-left: 0;
//             .news-events{
//                 .myNewsEvent{
//                     height: 650px;
//                     width: 80%;
//                 }
//                 .swiper-slide{
//                     .image{
//                         display: flex;
//                         align-items: center;
//                         width: 308px;
//                         height: 100%;
//                         img{
//                             width: 100%;
//                             height: auto;
//                         }
//                     }
                    
//                 }
                
//             }
//         }
//     }
// }
// @media (max-width: 380px) {
//     .news-events-2__wrapper{
//         .news-events-2__right{
//             .news-events{
//                 .myNewsEvent{
//                     height: 600px;
//                 }
//                 .swiper-slide{
//                     padding: 30px 0;
//                     .image{
//                         display: flex;
//                         align-items: center;
//                         width: 258px;
//                         height: 100%;
//                         img{
//                             width: 100%;
//                             height: auto;
//                         }
//                     }
                    
//                 }
                
//             }
//         }
//     }
// }