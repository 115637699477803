.btn {
	margin-top: auto;
	display: inline-block;
	justify-content: center;
	align-items: center;
	border: 0;
	appearance: none;
	background: map-get($mau, orange-1 );
	border-radius: 20px 0 ;
	color: map-get($mau, white-1 );
	padding: 10px 15px;
	transition: 0.3s ease-in-out;
	cursor: pointer;
	&:hover{
		background: map-get($mau, main );
	}
	@media (max-width: 1366px) {
		padding: 7px 10px;
		
	}
	@media (max-width: 1366px) {
		padding: 7px 10px;
		border-radius: 15px 0 ;
	}
	@media (max-width: 430px) {
		border-radius: 10px 0 ;
		padding: 5px 12px;
	}
	@media (max-width: 320px) {
		padding: 3px 10px;
	}
}
