.home-3__wrapper {
    background: map-get($mau, main-op-56 );
    padding: 15px 10px;
    .home-3{
        .swiper-slide{
            width: 302px;
            // height: 280px;
            a{
                width: 100%;
                // height: unset;
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .home-3__wrapper {
        background: map-get($mau, main-op-56 );
        padding: 7px 10px;
    }
}