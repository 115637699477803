.ourservices-leasing{
    display: flex;
    flex-direction: column;
    text-align: center;
    .title{
        margin-top: 0;
        &>*{
            text-align: center;
        }
    }
    .row{
        
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .box{
            
            @media (max-width: 576px) {
                margin-bottom: 5vw;
            }
            &-content{
                display: flex;
                flex-direction: column;
                height: 100%;
                background-color: map-get($mau,main-1);
                cursor: pointer;
                &:hover{
                    background-color: map-get($mau,white-1);
                    box-shadow: 0 3px 6px rgba(0,0,0,.16);
                }
                
            }
            &-img{
                padding-bottom: 66%;
                position: relative;
                overflow: hidden;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .text{
                height: 100%;
                text-align: start;
                padding: 50px 35px;
                @media (max-width: 1024px) {
                    padding: 40px 25px;
                }
                @media (max-width: 800px) {
                    padding: 30px 25px;
                }
                @media (max-width: 540px) {
                    padding: 20px 15px;
                }
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                .title{
                    color: map-get($mau,text-gray-1);
                    background-color: none;
                    font-family: ROBOTO-MEDIUM;
                    
                }
                p{
                    margin-top: 20px;
                    color: map-get($mau,text-gray-2); 
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-bottom: 20px;
                    @media (max-width: 800px) {
                        -webkit-line-clamp: 4;
                        margin:  2.1vw 0;
                    }
                }
                .btn{
                    border: none;
                    padding: 0;
                    color: map-get($mau, orange-1);
                    background: none;
                    margin-top: auto;

                }
            }
        }
    }
}
// @media ( max-width:1440px){
//     .ourservices-leasing {
//         .row{
//             .box{
//                 width: 500px;
//                 height: 590px;
//                 margin-inline:15px;
//                 .text{
//                     margin: 0;
//                 }
//             }
//         }      
//     } 
// }
// @media ( max-width:1366px){
//     .ourservices-leasing {
//         .row{
//             .box{
//                 width: 500px;
//                 height: 555px;
//                 margin-inline:15px;
//                 .text{
//                     margin: 0;
//                 }
//             }
//         }      
//     } 
// }
// @media ( max-width:768px){
//     .ourservices-leasing {
//         .row{
//             .box{
//                 height: 465px;
//                 margin-inline:15px;
//                 .text{
//                     margin: 0;
//                     padding: 0 50px 0 30px;
//                 }
//             }
//         }      
//     }
// }
// @media ( max-width:540px){
//     .ourservices-leasing {
//         .row{
//             .box{
//                 width: 220px;
//                 height: 250px;
//                 margin-inline:5px;
//                 .text{
//                     margin: 0;
//                     padding: 0 25px 0 20px;
//                     height: 45%;
//                     .decs{
//                         display: -webkit-box;
//                         -webkit-line-clamp: 4;
//                         -webkit-box-orient: vertical;
//                         overflow: hidden;
//                         font-size: 8px;
//                     }
//                     p{
//                         margin-top: 5px;
//                         display: -webkit-box;
//                         -webkit-line-clamp: 7;
//                         -webkit-box-orient: vertical;
//                         overflow: hidden;
//                     }
//                     .btn{
//                         margin-top: 5px;
//                     }
//                 }
//             }
//         }      
//     } 
// }
// @media ( max-width:380px){
//     .ourservices-leasing {
//         .row{
//             .box{
//                 width: 200px;
//                 height: 250px;
//                 margin-inline:5px;
//                 .text{
//                     margin: 0;
//                     padding: 0 25px 0 20px;
//                     .decs{
//                         display: -webkit-box;
//                         -webkit-line-clamp: 4;
//                         -webkit-box-orient: vertical;
//                         overflow: hidden;
//                         font-size: 8px;
//                     }
//                     p{
//                         margin-top: 5px;
//                         display: -webkit-box;
//                         -webkit-line-clamp: 7;
//                         -webkit-box-orient: vertical;
//                         overflow: hidden;
//                     }
//                     .btn{
//                         margin-top: 5px;
//                     }
//                 }
//             }
//         }      
//     } 
// }
// @media ( max-width:320px){
//     .ourservices-leasing {
//         .row{
//             .box{
//                 height: 180px;
//             }
//         }      
//     } 
// }


