.leasing-officeforlease{
    .office{
        margin-top: 52px;
        .mySwiperLeasing{
            position: unset;
        }
        .swiper-wrapper{
            height: 1241px;
        }
        .swiper-slide{
            display: flex;
            justify-content: space-between;
            .box{
                position: relative;
                width: 49%;
                height: 568px;
                box-shadow: 0 3px 6px rgba(0,0,0,.16);
                cursor: pointer;
                overflow: hidden;
                &:hover{
                    &::after{
                        bottom: -1px;
                    }
                    .text{
                        bottom: 52px;
                       
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &::after{
                    transition: 0.3s ease-in-out;
                    position: absolute;
                    background-image: url("../assets/images/leasing/overlay-img.png");
                    content: "";
                    bottom: -100%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position-y: 100%;
                    
                    background-size: 100%;
                }
                .text{
                    transition: 0.3s ease-in-out;
                    width: 100%;
                    position: absolute;
                    bottom: -100%;
                    left: 0;
                    display: flex;
                    justify-content: space-evenly;
                    z-index: 3;
                    align-items: center;
                    &::after{
                        position: absolute;
                        background-image: url("../assets/images/leasing/overlay-img.png");
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 100%;
                       
                    }
                    .text-48{
                        position: relative;
                        &::after{
                            position: absolute;
                            top: 50%;
                            right: -10%;
                            transform: translateY(-50%);
                            content: "";
                            width: 1px;
                            height: 100%;
                            background: map-get($mau, text-gray-2 );
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1366px) {
    .leasing-officeforlease{
        .office{
            margin-top: 52px;
            .swiper-wrapper{
                height: 881px;
            }
            .swiper-slide{
                .box{
                    height: 368px;
                   
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .leasing-officeforlease{
        .swiper-scrollbar{
            right: 0;
        }
        
        .office{
            .nav-custom{
                padding: 0 40px;
            }
            .swiper-wrapper{
                height: 841px;
            }
            .swiper-slide{
                .box{
                    height: 368px;
                    &:hover{
                        .text{
                            bottom: 30px;
                           
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .leasing-officeforlease{
        .office{
            .nav-custom{
                padding: 0 40px;
            }
            .swiper-wrapper{
                height: 641px;
            }
            .swiper-slide{
                .box{
                    height: 268px;
                    .text{
                        .text-48{
                            font-size: 20px;
                        }
                        .subtext{
                            .text-24{
                                 font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 430px) {
    .leasing-officeforlease{
        .swiper-scrollbar{
            display: none;
        }
        .office{
            margin-top: 22px;
            .nav-custom{
                padding: 0 20px;
            }
            .swiper-wrapper{
                height: 401px;
            }
            .swiper-slide{
                .box{
                    height: 168px;
                    &:hover{
                        .text{
                            bottom: 10px;
                           
                        }
                    }
                    .text{
                        .text-48{
                            font-size: 14px;
                        }
                        .subtext{
                            .text-24{
                                 font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 380px) {
    .leasing-officeforlease{
        .swiper-scrollbar{
            display: none;
        }
        .office{
            margin-top: 7px;
            .nav-custom{
                padding: 0 20px;
            }
            .swiper-wrapper{
                height: 301px;
            }
            .swiper-slide{
                .box{
                    height: 128px;
                    &:hover{
                        .text{
                            bottom: 10px;
                           
                        }
                    }
                    .text{
                        .text-48{
                            font-size: 10px;
                        }
                        .subtext{
                            .text-24{
                                 font-size: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
