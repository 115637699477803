.map-mobile{
    display: none;
    @media (max-width: 1024px) {
        margin-top: 54px;
    }
    @media (max-width: 430px) {
        margin-top: 34px;
    }
    
}
.footer__wrapper{
   
    margin-top: 104px;
    @media (max-width: 1367px) {
        margin-top: 84px;
    }
    
    @media (max-width: 1024px) {
        margin-top: 54px;
    }
    @media (max-width: 430px) {
        margin-top: 34px;
    }
    background: map-get($mau, main );
    .container{
        position: relative;
        .backtoTop-overlay{
            top: 50%;
            right: -80px;
            position: absolute;
            cursor: pointer;
            transform: rotate(270deg);
            p{
                text-transform: uppercase;
                
                position: relative;
                &::after{
                    position: absolute;
                    right: -30px;
                    top: 50%;
                    transform: translateY(-50%);
                    content: "";
                    background-image: url("../assets/icons/next-btn.png");
                    width: 16px;
                    height:100%;
                    background-repeat: no-repeat;
                    background-position:center;
                }
            }
        }
        .footer-left{
            width: 50%;
            display: flex;
            align-items: center;
            height: 224px;
            padding-right: 20px;
            &__logo{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 30px;
                .Logo{
                    width: 136px;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .Social{
                    a:first-child{
                        position: relative;
                        padding: 5px 19px;
                        border-radius: 5px;
                        border: solid 1px map-get($mau, white-1 );
                        background: none;
                        img{
                            position: absolute;
                            top: 50%;
                            left: 54%;
                            transform: translate(-50%,-50%);
                            width: 60%;
                            height: 60%;
                            object-fit: contain;
                        }
                    }
                    a:not(:first-child){
                        position: relative;
                        padding: 15px;
                        border-radius: 50%;
                        border: solid 1px map-get($mau, white-1 );
                        background: none;
                        img{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            width: 60%;
                            height: 60%;
                            object-fit: contain;
                        }
                    }
                    a:hover{
                        background:  map-get($mau, white-1 );
                    }
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                }
                .Language{
                    align-items: center;
                    display: flex;
                    *{
                        cursor: pointer;
                        margin-right: 10px;
                        text-transform: uppercase;
                        font-family: ROBOTO-MEDIUM;
                    }
                    span{
                        cursor: unset;
                    }
                }
                .active{
                    color: map-get($mau, white-op-70 );
                }
            }
            &__infor{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .text-24{
                    font-family: ROBOTO-MEDIUM;
                }
                .infor{
                    &__items{
                        display: flex;
                        align-items: flex-start;
                        margin-top: 10px;
                        img{
                            margin-top: 2px;
                            margin-right: 10px;
                        }
                    }
                }
                .infor--link{
                    ul{
                        padding-inline-start: 0;
                        li{
                        list-style-type: none;
                        margin-top: 10px;
                        font-family: ROBOTO-MEDIUM;
                            &::before{
                                content: "";
                                background-color: map-get($mau, orange-1 );
                                font-weight: bold;
                                display: inline-block; 
                                width: 9px;
                                height: 9px;
                                border-radius: 50%;
                                margin-left: -10px;
                                margin-right: 17px;
                                border: solid 1px map-get($mau, text-gray-4 );
                           }
                           &:hover{
                            a{
                                color: map-get($mau, white-1);
                            }
                           }
                        }
                    }
                    .active{
                        a{
                            color: map-get($mau, white-1);
                        }
                    }
                }
            }
        }
        .footer-right{
            padding: 0;
            width: 50%;
            iframe{
                width: 100%;
            }
        }
        // .col, [class*=col-]{
        //     padding: 0;
        // }
    }
   
}

@media (max-width: 1366px) {
    .footer__wrapper{
        
        background: map-get($mau, main );
        .container{
            position: relative;
            .backtoTop-overlay{
                top: 50%;
                right: -80px;
                position: absolute;
                cursor: pointer;
                transform: rotate(270deg);
                p{
                    text-transform: uppercase;
                    
                    position: relative;
                    &::after{
                        position: absolute;
                        right: -30px;
                        top: 50%;
                        transform: translateY(-50%);
                        content: "";
                        background-image: url("../assets/icons/next-btn.png");
                        width: 16px;
                        height:100%;
                        background-repeat: no-repeat;
                        background-position:center;
                    }
                }
            }
            .footer-left{
                display: flex;
                align-items: center;
                height: 200px;
                &__logo{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-right: 30px;
                    .Logo{
                        width: 96px;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    .Social{
                        a:first-child{
                            padding: 3px 14px;
                          
                        }
                        a:not(:first-child){
                            padding: 12px;
                           
                        }
                    }
                    .Language{
                        align-items: center;
                        display: flex;
                        *{
                            cursor: pointer;
                            margin-right: 10px;
                            text-transform: uppercase;
                        }
                        span{
                            cursor: unset;
                        }
                    }
                    .active{
                        color: map-get($mau, white-op-70 );
                    }
                }
                &__infor{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .infor{
                        &__items{
                            display: flex;
                            align-items: flex-start;
                            margin-top: 10px;
                            img{
                                margin-top: 2px;
                                margin-right: 10px;
                            }
                        }
                    }
                    .infor--link{
                        ul{
                            padding-inline-start: 0;
                            li{
                            list-style-type: none;
                            margin-top: 10px;
                                &::before{
                                    content: "";
                                    background-color: map-get($mau, orange-1 );
                                    font-weight: bold;
                                    display: inline-block; 
                                    width: 9px;
                                    height: 9px;
                                    border-radius: 50%;
                                    margin-left: -10px;
                                    margin-right: 17px;
                                    border: solid 1px map-get($mau, text-gray-4 );
                               }
                            }
                        }
                        .active{
                            a{
                                color: map-get($mau, white-1);
                            }
                            
                        }
                    }
                }
            }
            // .footer-right{
            //     iframe{
            //         width: 50%;
            //     }
            // }
        }
       
    }
}
@media (max-width: 1200px) {
    .footer__wrapper{
        .container{
            .backtoTop-overlay{
                right: -50px;
                
            }
            .footer-right{
                iframe{
                    width: 100%;
                }
            }

        }
       
    }
}
@media (max-width: 1024px) {
    .map-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        iframe{
            width: 100%;
        }
        width: 100%;
        
    }
    .footer__wrapper{
        
        .container{
            flex-direction: column-reverse;
            .backtoTop-overlay{
                top: 50%;
                right: -20px;
            }
            .footer-left{
                width: 100%;
                justify-content: center;
                margin-top: 30px;
            }
            .footer-right{
                display: none;
                
                iframe{
                width: 100%;
            }
            }
        }
       
    }
}

@media (max-width: 430px) {
    .footer__wrapper{
        .container{
            
            position: relative;
            .backtoTop-overlay{
                right: -10px;
                p{
                    &::after{
                        position: absolute;
                    }
                }
            }
            .footer-left{
                margin-bottom: 30px;
                height: 150px;
                &__logo{
                    margin-right: 15px;
                    .Logo{
                        width: 80px;
                    }
                    .Social{
                        a:first-child{
                            padding: 3px 12px;
                          
                        }
                        a:not(:first-child){
                            padding: 10px;
                           
                        }
                    }
                }
                &__infor{
                    
                    .infor{
                        &__items{
                            margin-top: 10px;
                            img{
                                width: 10px;
                            }
                        }
                    }
                    .infor--link{
                        ul{
                            li{
                                &::before{
                                    width: 7px;
                                    height: 7px;
                                    margin-right: 10px;
                               }
                            }
                        }
                    }
                }
            }
            .footer-right{
                display: none;
                iframe{
                    height: 160px;
                }
            }
        }
       
    }
}

@media (max-width: 380px) {
    .footer__wrapper{
        .container{
            position: relative;
            .backtoTop-overlay{
                // top: 80px;
                right: -20px;
               
            }
            .footer-left{
                display: flex;
                // flex-direction: column;
                align-items: flex-start;
                margin-bottom: 10px;
                height: 100%;
                &__logo{
                    margin-bottom: 30px;
                    height: 130px;
                }
               
            }
            
        }
       
    }
}
@media (max-width: 320px) {
    .footer__wrapper{
        .container{
            position: relative;
            .backtoTop-overlay{
                // top: 80px;
                right: -20px;
               
            }
            .footer-left{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 10px;
                height: 100%;
                &__logo{
                    margin-bottom: 30px;
                    height: 130px;
                }
               
            }
            
        }
       
    }
}