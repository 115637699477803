.home-6__wrapper{
    // padding: 100px 0;
    .home-6{
        background: map-get($mau , main );
        display: flex;
        // align-items: center;
        height: auto;
        position: relative;
        @media (max-width:640px) {
            flex-direction: column;
            background:unset;
        }
        .img-section{
            width: 45%;
            @media (max-width:640px) {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;
                &::after{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    background: map-get($mau , main );
                    height: 30%;
                    content: "";
                    z-index: -1;
                }
            }
            // height: 100%;
            img{
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (max-width:640px) {
                    width: 65%;
                }
            }
        }
        .text-section{
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            // flex
            // height: 100%;
            width: 55%;
            position: relative;
            @media (max-width:640px) {
                width: 100%;
                background: map-get($mau , main );
            }
            .overlay-section{
                margin-top: auto;
            }
            .text{
                height: 50%;
                padding-left: 20px;
                padding-right: 90px;
                padding-bottom: 30px;
                // margin-bottom: 60px;
                @media (max-width:1024px) {
                    padding: 20px;
                }
                .text-24{
                    font-family: ROBOTO-MEDIUM;
                }
                *{
                    margin-bottom: 30px;
                    @media (max-width:540px) {
                        margin-bottom: 20px;
                    }
                }
                .title{
                    text-transform: uppercase;
                }
                .decs{
                    // display: -webkit-box;
                    // -webkit-line-clamp: 4;
                    // -webkit-box-orient: vertical;
                    // overflow: hidden;
                }
            }
            .overlay{
                width: 80%;
                // position: absolute;
                // top: 0;
                // right: 0;
                display: inline-flex;
                align-self: flex-end;
                @media (max-width:640px) {
                    display: none;
                }
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
// @media (max-width: 1366px) {
//     .home-6__wrapper{
//         .home-6{
//             height: 528px;
//             .img-section{
//                 img{
//                     object-fit: cover;
//                 }
//             }
//             .text-section{
//                 .text{
//                     height: 50%;
//                     padding-left: 20px;
//                     padding-right: 90px;
//                     margin-bottom: 30px;
//                     *{
//                         margin-bottom: 20px;
//                     }
//                 }
//                 .overlay{
//                     position: absolute;
//                     top: 0;
//                     right: 0;
//                     display: inline-flex;
//                     justify-content: flex-end;
//                     img{
//                         width: 80%;
//                         // height: 100%;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 1024px) {
//     .home-6__wrapper{
//         .home-6{
//             height: unset;
//             .img-section{
//                 img{
//                     height: auto;
//                 }
//             }
//             .text-section{
//                 height: unset;
//                 .text{
//                     margin-bottom: 35px;
//                     padding-right: 50px;
//                     *{
//                         margin-bottom: 20px;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 768px) {
//     .home-6__wrapper{
//         .home-6{
//             .text-section{
//                 .text{
//                     margin-bottom: 20px;
//                     padding-right: 40px;
//                     *{
//                         margin-bottom: 20px;
//                     }
//                 }
//                 .overlay{
//                     img{
//                         width: 60%;
//                     }
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 430px) {
//     .home-6__wrapper{
//         .home-6{
//             .img-section{
//                 display: none;
//             }
//             .text-section{
//                 margin-bottom: 20px;

//                 .overlay-section{
//                     // padding-right: 70px;
//                     // padding-left: 20px;
//                     padding:  0 20px;
//                 }
//                 height: 260px;
//                 .text{

//                     margin-bottom: 10px;
//                     padding-left: 10px;
//                     padding-right: 20px;
//                     .title{
//                         display: -webkit-box;
//                         -webkit-line-clamp: 2;
//                         -webkit-box-orient: vertical;
//                         overflow: hidden;
//                         margin-bottom: 20px;
//                     }
//                     *{
//                         margin-bottom: 7px;
//                     }
//                     .decs{
//                         -webkit-line-clamp: 3;
//                     }
//                 }
//                 .overlay{
//                     img{
//                         width: 60%;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 320px) {
//     .home-6__wrapper{
//         .home-6{
//             .text-section{
//                 height: 230px;
//             }
//         }
//     }
// }