header{
    position: relative;
    .nav-Bar{
        transition: 0.4s ease-in-out;
        position: fixed;
        top: 80px;
        right: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        background: map-get($mau, white-op-50 );
        z-index: 998;
        visibility: hidden;
        ul{
            transition: 0.4s ease-in-out;
            position: absolute;
            right: 0;
            width: 50%;
            top: -100%;
            // height: 100%;
            padding-inline-start: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            background: map-get($mau, white-1);
            // flex-wrap: wrap;
            li{
                // width: 50%;
                padding: 20px 0;
                list-style-type: none;
                width: 100%;
                text-align: center;
                position: relative; 
                &:not(:last-child)::after{
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "";
                    width: 50%;
                    height: 0.3px;
                    background: map-get($mau,text-gray-3 );
                }
            }
        }
    }
    .nav-active{
        opacity: 1;
        visibility: visible;
        ul{
            top: 0;
        }
    }
    .nav-scroll{
        top: 46px !important;
        
        ul{
            background: map-get($mau, main );
            li{
                a{
                    color: map-get($mau, white-op-70 );
                }
            }
        }
    }
    .header__wrapper{
        transition: all 0.4s ease-in-out;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 125px;
        background: map-get($mau, white-1 );
        padding: 10px 0;
        z-index: 999;
       
        .container{
            height: 100%;
            .header {
                height: 100%;
                
                .headerNav-section{
                    height: 100%;
                    position: relative;
                    flex-direction: column;
                    display: flex;
                    align-self: center;
                    justify-content: center;
                    ul{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-inline-start: 0;
                        li{
                           
                            list-style-type: none;
                            .Logo{
                                width: 100%;
                                height: 100%;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .Logo-mobile{
                               display: none;
                            }
                            a{
                                font-family: ROBOTO-MEDIUM;
                            }
                            a:hover:not(.Logo){
                                transition: color 0.3s ease-in-out;
                                color: map-get($mau, black-1 );
                                border-bottom: solid 3px map-get($mau, orange-1 );
                            }
                            .active-link:not(.Logo){
                                color: map-get($mau, black-1 );
                                border-bottom: solid 3px map-get($mau, orange-1 );
                            }
                        }
                        .logo-section{
                            text-align: center;
                            width: 90px;
                        }   
                        .hamburger-Navbar{
                            display: none;
                            .bar1,.bar2,.bar3{
                                border-radius: 10px;
                                width: 33px;
                                height: 5px;
                                background-color: map-get($mau, main );
                                margin: 6px 0;
                                transition: 0.4s;
                            }
                            
                        }     
                        .change .bar1 {
                            -webkit-transform: rotate(-45deg) translate(-9px, 6px);
                            transform: rotate(-45deg) translate(-8px, 6px);
                        }
                        .change .bar2 {opacity: 0;}
                        
                        .change .bar3 {
                        -webkit-transform: rotate(45deg) translate(-8px, -8px);
                        transform: rotate(45deg) translate(-9px, -8px);
                        }        
                    }
                    
                }
                
            }
            
        }
        
    }
    .active{
        height: 56px !important;
        background: map-get($mau, main );
        .container{
            .header {
                .headerNav-section{
                    ul{
                        li{
                            a{
                                color: map-get($mau, white-op-70 );
                            }
                            a:hover:not(.Logo){
                                transition: color 0.3s ease-in-out;
                                color: map-get($mau, white-1 );
                            }
                            .active-link:not(.Logo){
                                color: map-get($mau, white-1 );
                            }
                        }
                        .logo-section{
                            display: none;
                        }        
                    }
                    
                }
            }
        }
        
    }
    
}
@media ( max-width: 1366px) {
    header{
        .header__wrapper{
            height: 80px;
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            li{
                                .Logo{
                                    img{
                                        width: 70%;
                                        height: auto;
                                    }
                                }
                                
                            }    
                        }
                        
                    }
                }
            }
            
        }
        .active{
            height: 46px !important;
            background: map-get($mau, main );
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            li{
                                a{
                                    color: map-get($mau, white-op-70 );
                                }
                                a:hover:not(.Logo){
                                    transition: color 0.3s ease-in-out;
                                    color: map-get($mau, white-1 );
                                }
                                .Logo{
                                    display: none;
                                }
                                .Logo-mobile{
                                    display: block;
                                }
                                .active-link:not(.Logo){
                                    color: map-get($mau, white-1 );
                                }
                            }
                            .logo-section{
                                display: none;
                            }        
                        }
                        
                    }
                }
            }
        }
    }
    
}
@media ( max-width: 768px) {
    header{
        .header__wrapper{
            // height: 90px;
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            padding: 0 40px;
                            li{
                                &:not(.logo-section){
                                    display: none;
                                }
                            }
                            .logo-section{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .Logo-mobile{
                                    width: 30%;
                                }
                            }
                            .hamburger-Navbar{
                                display: block;
                            } 
                        }
                        
                    }
                }
            }
        }
        .active{
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            .hamburger-Navbar{
                                .bar1,.bar2,.bar3{
                                    background-color: map-get($mau, white-1 );
                                }
                            }   
                            .logo-section{
                                .Logo{
                                    width: 50px;
                                    height: 100%;
                                    img{
                                        width: 40%;
                                    }
                                }
                            }        
                        }
                        
                    }
                }
            }
            
        }
    }
}
@media ( max-width: 430px) {
    header{
        .nav-scroll{
            ul{
                li{
                    a{
                        color: map-get($mau, white-op-70 );
                    }
                }
            }
        }
        .nav-Bar{
            top: 70px;
            ul{
                display: flex;
                flex-direction: column;
            }
        }
        .header__wrapper{
            height: 70px;
           
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            padding: 0 10px;
                            li{
                                .Logo{
                                    
                                    img{
                                        width: 60%;
                                        height: auto;
                                    }
                                }
                                
                            }
                            .hamburger-Navbar{
                                .bar1,.bar2,.bar3{
                                    width: 25px;
                                    height: 4px;
                                    margin: 3px 0;
                                }
                            }  
                            .change .bar1 {
                                -webkit-transform: rotate(-45deg) translate(-1px,9px);
                                transform: rotate(-45deg) translate(-1px,9px);
                            }
                            .change .bar3 {
                                -webkit-transform: rotate(45deg) translate(-1px,-9px);
                                transform: rotate(45deg) translate(-1px,-9px);
                            }         
                        }
                        
                    }
                }
            }
        }
    }
}
@media ( max-width: 380px) {
    header{
        .nav-Bar{
            top: 55px;
            // width: 100%;
            ul{
                display: flex;
                flex-direction: column;
            }
        }
        .header__wrapper{
            height: 55px;
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            li{
                                .Logo{
                                    img{
                                        width: 50%;
                                        height: auto;
                                    }
                                }
                                
                            }
                        }
                        
                        
                    }
                }
            }
        }
        .active{
            .container{
                .header {
                    .headerNav-section{
                        ul{
                            .hamburger-Navbar{
                                .bar1,.bar2,.bar3{
                                    background-color: map-get($mau, white-1 );
                                }
                            }           
                            li{
                                .Logo{
                                    width: 50px;
                                    // height: 102px;
                                    img{
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
            
        }
    }
}