.our-club-4__wrapper{
    .our-club-4{
        &--items{
            display: flex;
            align-items: center;
            margin-bottom: 104px;
            @media (max-width:900px) {
                 margin-bottom: 64px;
            }
            @media (max-width:768px) {
                 margin-bottom: 54px;
            }
           
            @media (max-width:540px) {
                flex-direction: column;
                margin-bottom: 34px;
            }
            &__left{
                width: 52%;
                @media (max-width:540px) {
                    width: 100%;
                }
                .image{
                    width: 100%;
                    padding-bottom: 60%;
                    position: relative;
                    overflow: hidden;
                    img{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &__right{
                width: calc(100% - 52%);
                @media (max-width:540px) {
                    width: 100%;
                    background: map-get($mau, main-2 );
                    padding: 20px;
                }
                .text-section {
                    padding-left: 104px;
                    @media (max-width:900px) {
                        padding-left: 64px;
                    }
                    @media (max-width:768px) {
                        padding-left: 44px;
                    }
                    @media (max-width:540px) {
                        padding-left: unset;
                    }
                    .title{
                        font-family: ROBOTO-MEDIUM;
                        
                    }
                    .decs{
                        margin-top: 40px;
                        @media (max-width:768px) {
                            margin-top: 30px;
                        }
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
// @media (max-width: 1366px) {
//     .our-club-4__wrapper{
//         .our-club-4{
//             &--items{
//                 margin-bottom: 104px;
//                 &__left{
//                     width: 52%;
//                     .image{
//                         height: 268px;
//                         overflow: hidden;
//                         img{
//                             width: 100%;
//                             height: 100%;
//                             object-fit: contain;
//                         }
//                     }
//                 }
//                 &__right{
//                     width: calc(100% - 52%);
//                     .text-section {
//                         padding-left: 40px;
//                         .title{
//                             margin-bottom: 40px;
//                         }
                       
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 1024px) {
//     .our-club-4__wrapper{
//         .our-club-4{
//             &--items{
//                 &__left{
//                     .image{
//                         width: 100%;
//                         height: 100%;
//                         overflow: hidden;
//                     }
//                 }
                
//             }
//         }
//     }
// }
// @media (max-width:768px) {
//     .our-club-4__wrapper{
//         .our-club-4{
          
//             &--items{
               
//                 &__right{
//                     .text-section {
//                        .decs{
//                             margin-top: 20px;
//                             -webkit-line-clamp: 4;
//                             margin-bottom: 5px;
//                        }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width:540px) {
//     .our-club-4__wrapper{
//         .our-club-4{
//             display: flex;
//             flex-wrap: wrap;
//             justify-content: space-between;
//             &--items:not(:nth-child(1n+3)){
//                 margin-bottom: 34px;
//             }
//             &--items{
//                 display: flex;
//                 align-items: flex-start;
//                 flex-direction: column;
//                 margin-bottom: 0;
//                 width: 48%;
//                 &__left{
//                     width: 100%;
//                     .image{
//                         width: 100%;
//                         height: 100%;
//                         overflow: hidden;
//                         img{
//                             width: 100%;
//                             height: 100%;
//                             object-fit: contain;
//                         }
//                     }
//                 }
//                 &__right{
//                     width: 100%;
//                     .text-section {
//                         padding: 10px 0;
//                        .decs{
//                             margin-top: 10px;
//                             -webkit-line-clamp: 4;
//                             margin-bottom: 5px;
//                        }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width:380px) {
//     .our-club-4__wrapper{
//         .our-club-4{
//             &--items{
//                 &__right{
//                     width: 100%;
//                     .text-section {
//                         .title{
//                             margin-bottom: 10px;
//                         }
//                        .decs{
//                             -webkit-line-clamp: 3;
//                        }
//                     }
//                 }
//             }
//         }
//     }
// }