

.object-cover {
	object-fit: cover;
	object-position: center;
	background-size: cover;
}

.object-contain {
	object-fit: contain;
	object-position: center;
	background-size: contain;
}


#phone-call{
	position: fixed;
	top: 50%;
	right: 90px;
	z-index: 9999;
	@media (max-width: 1366px) {
		right: 70px;
		bottom:200px;
		top:unset;
	}
	@media (max-width: 430px) {
		right: 30px;
		top:unset;
		bottom:110px;
	}
	.phone{
		position: relative;
		a{
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

		}
		@media (max-width: 1366px) {
			width: 48px;
			height:	48px;
			background-size: 25px;
		}
		@media (max-width: 430px) {
			width: 38px;
			height:	38px;
			background-size: 25px;
		}
		width: 58px;
		height: 58px;
		border-radius: 50%;
		background:#006E29;
		cursor: pointer;
		background-image: url(../assets/icons/phone.png);
		background-repeat: no-repeat;
		background-position: center;
		animation-name: rotate ;
		animation-duration: .8s ;
		animation-iteration-count: infinite;
		animation-delay: 1.4s;
		transition: .3s ease;
		&:hover{
			background: map-get($mau, orange-1 );
			background-image: url(../assets/icons/phone.png);
			background-repeat: no-repeat;
			background-position: center;
			@media (max-width: 1366px) {
				background-size: 25px;
			}
		}
	}
	
	@keyframes rotate {
		0%  {
				transform: rotate(0deg);
			}
		10% {
				transform: rotate(30deg);
	  		}
		20% {
				transform: rotate(-20deg);
			}
		30% {
				transform: rotate(30deg);
			}
		40% {
				transform: rotate(-20deg);
			}
		50% {
				transform: rotate(0deg);
			}
		100% {
				transform: rotate(0deg);
			}
			
	}
	.phone-bg{
		position: absolute;
		transform: translate(-50%,-50%);
		top: 50%;
		left: 50%;
		border-radius: 50%;
		background:  rgba(43,128,75,0.35);
		z-index: -1;
		animation: mymove 2.5s infinite;
		@media (max-width: 1366px) {
			animation: mymove-1366 2.5s infinite;
		}
	}
	
	@keyframes mymove-1366 {
		0%  {
				width: 38px;
				height: 38px;
			}
		50% {
				width: 78px;
				height:78px;
	  		}
			  
		100% {
				width: 38px;
				height: 38px;
	  		}
	}
	@keyframes mymove {
		0%  {
				width: 58px;
				height: 58px;
			}
		50% {
				width: 98px;
				height:98px;
	  		}
			  
		100% {
				width: 58px;
				height: 58px;
	  		}
	}
	.phone-border{
		position: absolute;
		transform: translate(-50%,-50%);
		top: 50%;
		left: 50%;
		border-radius: 50%;
		background: none;
		z-index: -1;
		animation: border-move 1.3s infinite;
		border: solid 1px rgba(43,128,75,0.35);
	}
	@keyframes border-move {
		0%  {
				width: 48px;
				height:48px;
			}
		100% {
				width: 158px;
				height:158px;
	  		}
			  
	}
	.phone-infor{
		display: none;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		top: 50%;
		right: 20px;
		padding: 30px;
		border-radius: 40px 0px 40px 0;
		background:map-get($mau,  main-op-52 );
		transition: 0.4s ease-in-out;
		z-index: -1;
		
		@media (max-width: 900px) {
			right: 20px;
			padding: 25px;
			transform: translateY(-100%);
			display: none !important;
		}
		// @media (max-width: 380px) {
		// 	right: -20px;
		// 	padding: 10px;
		// 	transform: translateY(-115%);
		// }
		&{
            animation: fadeEffect 0.5s;
        }
        @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
        }
		.title{
			font-family: ROBOTO-MEDIUM;
			margin-bottom: 6px;
			color: map-get($mau, orange-1 );
		}
		.infor{
			margin-left: auto;
			width: 100%;
			color: map-get($mau, white-1 );
		}
		.btn{
			width: 100%;
			text-align: center;
		}
		.items{
			
			&:not(:last-child){
				margin-bottom: 15px;
			}
		}
	}

	//// fix
	&:hover{
		.phone-infor{
			display: flex;
		}
	}
}


a{
	color: map-get($mau, text-gray-1 );
}
.nav-custom{
	position: relative;
	.swiper-button-next,
	.swiper-button-prev{
		background: map-get($mau , main-op-20 );
		border-radius: 50%;
		width: 48px;
		height: 48px;
		@media (max-width: 1367px) {
			width: 38px;
			height: 38px;
		}
		@media (max-width: 630px) {
			display: none;
		}
	}
	.swiper-scrollbar{
		top: 50%;
		transform: translateY(-50%);
		height: 136px;
		display: flex;
		justify-content: center;
		right: -60px;
		width: 24px;
		background: map-get($mau, white-1 );
		box-shadow: 0px 5px 10px 4px map-get($mau, black-op-8 );
		.swiper-scrollbar-drag{
			cursor: pointer;
			width: 70%;
			background: map-get($mau, orange-1 );
		}
	}
}



.swiper-button-prev{
	left: -100px;
	&::after{
		transform: translateX(-2px);
		color: map-get($mau, orange-1 );
		background-image: url("../assets/icons/pre-btn.png");
		background-repeat: no-repeat;
		background-position:center;
		width: 50%;
		height: 50%;
		content: "";
		justify-content: center;
	}
	@media (max-width: 1367px) {
		left: -55px;
		&::after{
			width: 40%;
			height: 40%;
		}
	}
	// @media (max-width: 1200px) {
	// 	left: 0;
	// }
}
.swiper-button-next{
	
	right: -100px;
	&::after{
		transform: translateX(2px);
		color: map-get($mau, orange-1 );
		background-image: url("../assets/icons/next-btn.png");
		background-repeat: no-repeat;
		background-position:center;
		width: 50%;
		height: 50%;
		content: "";
		justify-content: center;
	}
	@media (max-width: 1367px) {
		right: -55px;
		&::after{
			width: 40%;
			height: 40%;
		}
	}
	// @media (max-width: 1200px) {
	// 	right: 0;
	// }
}

.link-facebook{
	&_share{
		position: relative;
		cursor: pointer;
		background-color: #0078FF ;
		padding: 5px 12px;
		svg{
			margin-right: 10px;
			path{
				fill: white;
			}
		}
		span{
			color: white;
			text-transform: uppercase;
			line-height: 1;
		}
		display: inline-flex;
		align-items: center;
	}
}
// .banner{
// 	position: absolute !important;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// }