*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	// font-size: 52.5%;
	// @media (min-width: 768px) {
	// 	font-size: 57.5%;
	// }
	// @media (min-width: 1200px) {
	// 	font-size: 62.5%;
	// }
}

body {
	scroll-behavior: smooth;
	position: relative;
	margin: 0;
	font-family: ROBOTO-REGULAR;
	font-weight: 400;
	line-height: 1.2;
	color: #000000;
	background-color: #ffffff;
	font-size: 18px;
	@media (max-width: 1366px) {
		font-size: 14px;
	}
	@media (max-width: 1024px) {
		font-size: 14px;
	}
	@media (max-width: 430px) {
		font-size: 11px;
	}
	// @media (max-width: 321px) {
	// 	font-size: 8px;
	// }
}
.body-overflow{
	overflow: hidden;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

// main{
// 	overflow: hidden;
// }

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

p {
	margin-top: 0;
	margin-bottom: 0;
	// text-align: justify;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 0;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0;
	margin-left: 0;
}

blockquote {
	margin: 0;
}

b,
strong {
	font-weight: bolder;
	font-weight: 700;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	text-decoration: none;
	background-color: transparent;
}

pre,
code,
kbd,
samp {
	font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
		monospace;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 0;
	overflow: auto;
}

figure {
	margin: 0;
}

img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0;
	padding-bottom: 0;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-bottom: 0;
}

button {
	border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	// appearance: none;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

select {
	word-wrap: normal;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

*:focus,
*:active {
	outline: none;
}
