.otherservices-clinical{
    .head-title{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: map-get($mau, text-gray-2 );
        text-transform: uppercase;
    }
    .content{
        color: map-get($mau, text-gray-2 );
        p{
            list-style-type: none;
            margin: 20px 0;
            line-height: 1.6;
        }
        .image{
            text-align: center;
            p{
                margin-top: 30px ;
            }
        }
    }
}                    
                    