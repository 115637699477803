.your-moments-afternoon__wrapper{
    .your-moments-afternoon__title{
        text-align: center;
        margin-bottom: 52px;
        text-transform: uppercase;
        &>*{
            text-align: center;
        }
    }
    .your-moments-afternoon__pic{
        .mySwiperAfternoon1{
            margin-bottom: 10px;
            .swiper-slide{
                cursor: pointer;
                img{
                    width: 100%;
                    height: 872px;
                    object-fit: cover;
                }
            }
        }
        .nav-custom{
            .mySwiperAfternoon-1{
                .swiper-slide{
                    cursor: pointer;
                    img{
                        width: 302px;
                        height: 320px;
                        object-fit: cover;
                    }
                }
            }
        }
        
    }
}
@media (max-width: 1366px) {
    .your-moments-afternoon__wrapper{
        .your-moments-afternoon__pic{
            .mySwiperAfternoon1{
                .swiper-slide{
                    img{
                        height: 572px;
                    }
                }
            }
            .nav-custom{
                .mySwiperAfternoon-1{
                    .swiper-slide{
                        img{
                            height: 220px;
                        }
                    }
                }
            }
            
        }
    }
}
@media (max-width: 1024px) {
    .your-moments-afternoon__wrapper{
        .your-moments-afternoon__pic{
            .swiper-button-next,
            .swiper-button-prev{
                display: none;
            }
            .mySwiperAfternoon1{
                .swiper-slide{
                    img{
                        height: 522px;
                    }
                }
            }
            .nav-custom{
                .mySwiperAfternoon-1{
                    .swiper-slide{
                        img{
                            height: 200px;
                        }
                    }
                }
            }
            
        }
    }
}
@media (max-width: 768px) {
    .your-moments-afternoon__wrapper{
        .your-moments-afternoon__pic{
            .swiper-button-next,
            .swiper-button-prev{
                display: none;
            }
            .mySwiperAfternoon1{
                .swiper-slide{
                    img{
                        height: 482px;
                    }
                }
            }
            .nav-custom{
                .mySwiperAfternoon-1{
                    .swiper-slide{
                        img{
                            height: 180px;
                        }
                    }
                }
            }
            
        }
    }
}
@media (max-width: 430px) {
    .your-moments-afternoon__wrapper{
        .your-moments-afternoon__title{
            margin-bottom: 22px;
        }
        .your-moments-afternoon__pic{
            .mySwiperAfternoon1{
                .swiper-slide{
                    img{
                        height: 322px;
                    }
                    @media (max-width:380px) {
                        height: 280px;
                    }
                }
            }
            .nav-custom{
                .mySwiperAfternoon-1{
                    .swiper-slide{
                        img{
                            height: 100px;
                        }
                        @media (max-width:380px) {
                            height: 80px;
                        }
                    }
                }
            }
            
        }
    }
}
