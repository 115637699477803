.news-events-banner__wrapper{
    .news-events-banner {
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
            min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
            min-height: 250px;
        }
        .banner{
            // position: relative;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            img{
                // transform: translateY(60px);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
        }
       
    }
}

