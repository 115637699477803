.infor-news-events-2__wrapper{
    .infor-news-events-2{
        display: flex;
        &__left{
            padding-right: 100px;
            width: 65.6%;
            &--title{
                margin: 40px 0;
                p{
                    margin-bottom: 18px;
                }
                .title{
                    text-transform: uppercase;
                }
               
            }
            &--decs{
                display: flex;
                flex-direction: column;
                // align-items: center;
                color: #9e9e9e;
                line-height: 1.6;
                .decs{
                    margin-bottom: 22px;
                    line-height: 1.6;
                }
                .image-section{
                    margin: 40px auto;
                    text-align: center;
                    width: 514px;
                    height: 100%;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    .image-decs{
                        margin-top: 20px;
                    }
                }
            }
        }
        &__right{
            width: calc(100% - 65.6%);
            margin-left: auto;
            .title{
                margin-bottom:40px ;
                
                font-family: ROBOTO-MEDIUM;
            }
            .new-events__Mobile{
                display: none;
            }
            .news-events{
               
                .news-items{
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 100px;
                    position: relative;
                    &:not(:last-child)::after{
                        // &:after{
                            content: "";
                            bottom: -30px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: map-get($mau, main );
                            position: absolute;
                        // }
                    }
                    &:hover{
                        .text-24{
                            color: map-get($mau, main );
                        }
                    }
                    &__image{
                        width: 122px;
                        height: 104px;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    &__decs{
                        
                        display: flex;
                        flex-direction: column;
                        align-content: space-between;
                        width: 268px;
                        height:100%;
                        .text-24{
                            transform: translateY(-6px);
                            transition: .2s ease-in-out;
                            font-family: ROBOTO-MEDIUM;
                        }
                        .text-16{
                            transform: translateY(6px);
                        }
                    }
                }
            }
            
        }
    }
}
@media (max-width:1366px) {
.infor-news-events-2__wrapper{
    .infor-news-events-2{
        display: flex;
        &__left{
            padding-right: 80px;
            
        }
        &__right{
            width: calc(100% - 65.6%);
            margin-left: auto;
            .title{
                margin-bottom:40px ;
            }
            .new-events__Mobile{
                display: none;
            }
            .news-events{
                .news-items{
                    margin-bottom: 90px;
                    &__image{
                        width: 92px;
                        height: 100%;
                        img{
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    &__decs{
                        width: 238px;
                        
                    }
                }
            }
            
        }
    }
}
}
@media (max-width:1365px) {
    .infor-news-events-2__wrapper{
        .infor-news-events-2{
            &__left{
                padding-right: 80px;
            }
            &__right{
                width: calc(100% - 65.6%);
                .news-events{
                    .news-items{
                        width: 100%;
                        &:hover{
                            .text-24{
                                color: map-get($mau, main );
                            }
                        }
                        &__image{
                            width: 100px;
                            height: 100%;
                        }
                    }
                }
                
            }
        }
    }
}

@media (max-width: 1200px) {
    .infor-news-events-2__wrapper{
        .infor-news-events-2{
            display: flex;
          
            &__right{
                .news-events{
                    .news-items{
                        margin-bottom: 70px;
                        &__image{
                            width: 30%;
                            height: unset;
                        }
                        &__decs{
                            display: flex;
                            flex-direction: column;
                            align-content: space-between;
                            width: 65%;
                            height: 100%;
                            .text-24{
                                transition: .2s ease-in-out;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 1199px) {
    .infor-news-events-2__wrapper{
        .infor-news-events-2{
            display: unset;
            &__left{
                padding-right: 0;
                width: 100%;
                &--title{
                    margin: 30px 0;
                    p{
                        margin-bottom: 10px;
                    }
                }
                &--decs{
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    .image-section{
                        margin: 40px auto;
                        text-align: center;
                        width: 60%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: auto;
                        }
                        .image-decs{
                            margin-top: 15px;
                        }
                    }
                }
            }
            &__right{
                width: 100%;
                .title{
                    margin-top: 40px;
                }
                .new-events__Mobile{
                    display: block;
                    align-items: center;
                    .swiper-slide{
                        .news-items{
                            &__image{
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                margin-bottom: 20px;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            &__decs{
                                display: flex;
                                flex-direction: column;
                                align-content: space-between;
                                width: 100%;
                                height:100%;
                                .text-24{
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    margin-bottom: 10px;
                                    transition: .2s ease-in-out;
                                }
                            }
                        }
                        
                    }
                }
                .news-events{
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 430px) {
    .infor-news-events-2__wrapper{
        .infor-news-events-2{
            display: unset;
            &__left{
                padding-right: 0;
                width: 100%;
                &--title{
                    margin: 20px 0;
                    // .link-facebook{
                    //     iframe{
                    //         width: 30px;
                    //     }
                    // }
                }
                &--decs{
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    .image-section{
                        margin: 15px auto;
                        text-align: center;
                        width: 60%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: auto;
                        }
                        .image-decs{
                            margin-top: 15px;
                        }
                    }
                }
            }
            &__right{
                width: 100%;
                .title{
                    margin-top: 40px;
                }
                .new-events__Mobile{
                    display: block;
                    align-items: center;
                    .swiper-slide{
                        .news-items{
                            &__image{
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                margin-bottom: 20px;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            &__decs{
                                display: flex;
                                flex-direction: column;
                                align-content: space-between;
                                width: 100%;
                                height:100%;
                                .text-24{
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    margin-bottom: 10px;
                                    transition: .2s ease-in-out;
                                     font-family: ROBOTO-MEDIUM;
                                }
                            }
                        }
                        
                    }
                }
                .news-events{
                    display: none;
                }
            }
        }
    }
}