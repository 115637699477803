.ourservice-business{
    .title{
        text-align: center;  
        &>*{
            text-align: center;
        }
    }
    .content{
        display: flex;
        flex-direction: row;
        // height: 1111x;
        max-width: 1920px;
        margin: auto;
        flex-wrap: wrap;
        height: 100%;
        &-left{
            width: 50%;
            padding: 0;
            display: flex;
            flex-direction: column;
            .top{
                height: 50%;
                display: flex;
                flex-direction: row;
                &-right{
                    background-color:map-get($mau, main-gray);
                    width: 50%;
                    position: relative;
                    .text{
                        position: absolute;
                        // width: 322px;
                        // bottom: 100px;
                        left: 8%;
                        bottom: 8%;
                        padding-right: 50px;
                        @media (max-width: 768px ) {
                            padding-right: 8%;
                        }
                        p{
                            // text-align: justify;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 7;
                            @media (max-width: 1024px ) {
                                -webkit-line-clamp: 4;
                            }
                            
                            overflow: hidden;
                            line-height: 1.6;
                            font-weight: normal;
                            color: map-get($mau, text-gray-2);
                            font-weight: normal;
                            margin-top: 15px;
                            @media (max-width: 768px ) {
                                margin-top: 10px;
                            }
                        }
                        .text-24{
                            color: map-get($mau, text-gray-1);
                            font-family: ROBOTO-MEDIUM;
                        }
                        .btn{
                            border: none;
                            padding: 0;
                            color: map-get($mau, orange-1);
                            background: none;
                            margin-top: 20px;
                            @media (max-width: 768px ) {
                                margin-top: 10px;
                            }
                        }
                    }
                }
                &-left{
                    width: 50%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .bot{
                height: 50%;
                display: flex;
                flex-direction: row;
                &-left{
                    position: relative;
                    background-color:map-get($mau, main);
                    width: 50%;
                    color: white;
                    .text{
                        position: absolute;
                        left: 8%;
                        bottom: 8%;
                        padding-right: 50px;
                        @media (max-width: 768px ) {
                            padding-right: 8%;
                        }
                        p{
                            // text-align: justify;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 7;
                            @media (max-width: 1024px ) {
                                -webkit-line-clamp: 4;
                            }
                            
                            line-height: 1.6;
                            overflow: hidden;
                            font-weight: normal;
                            color: map-get($mau, text-gray-2);
                            font-weight: normal;
                            margin-top: 15px;
                            @media (max-width: 768px ) {
                                margin-top: 10px;
                            }
                        }
                        .text-24{
                            color: map-get($mau, text-gray-3);
                            font-family: ROBOTO-MEDIUM;
                        }
                        .btn{
                            border: none;
                            padding: 0;
                            color: map-get($mau, orange-1);
                            background: none;
                            margin-top: 20px;
                            @media (max-width: 768px ) {
                                margin-top: 10px;
                            }
                        }
                    }
                    
                }
                &-right{
                    width: 50%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    }
            }
        }
        &-right{
            overflow: hidden;
            position: relative;
            vertical-align:middle ;
            align-items: center;
            width: 50%;
            background-color: map-get($mau,main-gray );
            padding: 0;
            @media (max-width: 576px ) {
               padding: 20px 0;
            }
            &::after{
                position: absolute;
                top: 0;
                right: 20%;
                width: 65%;
                height: 100%;
                transform: skew(-30deg);
                background: map-get($mau, white-1);
                content: "";
                box-shadow: 2px 2px 7px 0 map-get($mau, text-gray-1 );
                // z-index: -1;
            }
            .image-contain{
                margin-top: auto;
                margin-bottom: auto;
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 0 1.4vw;
                justify-content: center;
                @media (max-width: 576px ) {
                    display: none;
                }
                .bg-overlay{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 100%;
                    z-index: 1;
                }
                .inner-image{
                    position: relative;
                    z-index: 2;
                    .title-header{
                        padding-left: 35px;
                        margin-bottom: 10px;
                        color: white;
                    }
                    .name-tag{
                        padding: 0 1.4vw;
                        margin-bottom: 2.8vw;
                        .partner-wrapper{
                            padding-bottom: 140%;
                            background-color: white;
                            position: relative;
                            border-radius: 1vw;
                            overflow: hidden;
                            &__items{
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                overflow: hidden;
                                width: 100%;
                                .img-overlay{
                                    width: 100%;
                                    height: 100%;
                                   
                                    top: 0;
                                    @media (max-width: 1440px) {
                                        top: -10px;
                                    }
                                    left: 0;
                                    position: absolute;
                                    content: "";
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    img{
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                                .img-wrapper{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    position: relative;
                                    width: 50%;
                                    padding-bottom: 48%;
                                    border-radius: 50%;
                                    margin-bottom: 30px;
                                    overflow: hidden;
                                    @media (max-width: 900px) {
                                        margin-bottom: 15px;
                                    }
                                    border: solid 3px map-get($mau, main );
                                    z-index: 1;
                                    .image{
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        // width: 50%;
                                        // height: 50%;
                                        
                                        img{
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }
                                    }
                                }
                                .name-wrapper{
                                    position: relative;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
                // .inner-image{
                //     display: flex;
                //     flex-direction: column;
                //     white-space: wrap;
                //     justify-content: space-evenly;
                //     .row{
                //         display: flex;
                //         flex-direction: row;
                //         justify-content: space-evenly;
                //         // margin: 30px 0;
                //         .name-tag{
                           
                //             .partner-wrapper{
                //                 width: 100%;
                //                 padding-bottom: 60%;
                //                 text-align: center;
                //                 background-color: white;
                //                 border-radius: 25px;
                //                 position: relative;
                //                 display: flex;
                //                 flex-direction: column;
                //                 align-items: center;
                //                 justify-content: space-evenly;
                //                 overflow: hidden;
                //                 // margin-bottom: 20px;
                //                 &__items{
                //                     position: absolute;
                //                     top: 0;
                //                     left: 0;
                //                     bottom: 0;
                //                     right: 0;
                //                     width: 100%;
                //                     height: 100%;
                //                 }
                //                 &::after{
                //                     width: 100%;
                //                     height: 100%;
                //                     top: -10px;
                //                     left: 0;
                //                     position: absolute;
                //                     content: "";
                //                     background: url("../assets/images/our-services/overlay-partner-big.png");
                //                     background-repeat: no-repeat;
                //                     background-position: center;
                //                 }
                //             }
                            
                //             .img-wrapper{
                //                 // width: 70%;
                //                 // height: 70%;
                //                 border-radius: 50%;
                //                 border: solid 3px map-get($mau, main );
                //                 display: flex;
                //                 align-items: center;
                //                 justify-content: center;
                //                 z-index: 3;
                //                 .image{
                //                     width: 50%;
                //                     height: 50%;
                //                     img{
                //                         width: 100%;
                //                         height: 100%;
                //                         object-fit: contain;
                //                     }
                //                 }
                               
                //             }
                //             .name-wrapper{
                //                 z-index: 3;
                                
                               
                //             }
                //         }
                //     }
                // }
            }
            .mobile{
                display: none;
                @media (max-width: 576px ) {
                    display: block;
                }
                &.swiper-container{
                    background-image:url("../assets/images/our-services/business-img3.png") ;
                    background-repeat: no-repeat;
                    background-position:center ;
                    background-size: 100%;
                    // height: 90%;
                }
                .swiper-wrapper{
                    padding: 10px 0;
                    align-items: center;
                    .swiper-slide{
                        background: map-get($mau, white-1 );
                        height: 100%;
                        box-shadow: 1px 1px 7px 0 map-get($mau, black-op-8 );
                        position: relative;
                        border-radius: 8px;
                        padding-bottom: 33%;
                        @media (max-width: 380px ) {
                            padding-bottom: 40%;
                        }
                        &::after{
                            width: 100%;
                            height: 100%;
                            top: -10px;
                            left: 0;
                            position: absolute;
                            content: "";
                            background: url("../assets/images/home-page/overlay-partner.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100% auto;
                        }
                        .slide{
                            
                            position: absolute;
                            z-index: 3;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            .img-wrapper{
                                width: 46px;
                                height: 46px;
                                @media (max-width: 380px) {
                                    border: solid 1px map-get($mau, main );
                                }
                                border-radius: 50%;
                                border: solid 2px map-get($mau, main );
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .image{
                                    width: 50%;
                                    height: 50%;
                                    img{
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                                
                            }
                        }
                    }
                }

            }
        }
    }
}
                
