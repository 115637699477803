.text-14 {
  font-size: 14px; }
  @media (max-width: 1367px) {
    .text-14 {
      font-size: 14px; } }
  @media (max-width: 1201px) {
    .text-14 {
      font-size: 12px; } }
  @media (max-width: 769px) {
    .text-14 {
      font-size: 12px; } }
  @media (max-width: 430px) {
    .text-14 {
      font-size: 9px; } }
  @media (max-width: 320px) {
    .text-14 {
      font-size: 8px; } }

.text-16 {
  font-size: 16px; }
  @media (max-width: 1367px) {
    .text-16 {
      font-size: 14px; } }
  @media (max-width: 1201px) {
    .text-16 {
      font-size: 14px; } }
  @media (max-width: 769px) {
    .text-16 {
      font-size: 14px; } }
  @media (max-width: 430px) {
    .text-16 {
      font-size: 12px; } }

.text-20 {
  font-size: 20px; }
  @media (max-width: 1367px) {
    .text-20 {
      font-size: 18px; } }
  @media (max-width: 1201px) {
    .text-20 {
      font-size: 16px; } }
  @media (max-width: 1024px) {
    .text-20 {
      font-size: 16px; } }
  @media (max-width: 430px) {
    .text-20 {
      font-size: 13px; } }
  @media (max-width: 320px) {
    .text-20 {
      font-size: 13px; } }

.text-22 {
  font-size: 22px; }
  @media (max-width: 1367px) {
    .text-22 {
      font-size: 18px; } }
  @media (max-width: 1201px) {
    .text-22 {
      font-size: 18px; } }
  @media (max-width: 1024px) {
    .text-22 {
      font-size: 16px; } }
  @media (max-width: 430px) {
    .text-22 {
      font-size: 13px; } }
  @media (max-width: 320px) {
    .text-22 {
      font-size: 11px; } }

.text-24 {
  font-size: 24px; }
  @media (max-width: 1367px) {
    .text-24 {
      font-size: 24px; } }
  @media (max-width: 1201px) {
    .text-24 {
      font-size: 22px; } }
  @media (max-width: 1025px) {
    .text-24 {
      font-size: 20px; } }
  @media (max-width: 769px) {
    .text-24 {
      font-size: 18px; } }
  @media (max-width: 430px) {
    .text-24 {
      font-size: 14px; } }
  @media (max-width: 320px) {
    .text-24 {
      font-size: 13px; } }

.text-48 {
  font-size: 48px; }
  @media (max-width: 1367px) {
    .text-48 {
      font-size: 38px; } }
  @media (max-width: 1201px) {
    .text-48 {
      font-size: 34px; } }
  @media (max-width: 1024px) {
    .text-48 {
      font-size: 30px; } }
  @media (max-width: 769px) {
    .text-48 {
      font-size: 26px; } }
  @media (max-width: 430px) {
    .text-48 {
      font-size: 20px; } }
  @media (max-width: 320px) {
    .text-48 {
      font-size: 13px; } }

.margin-tb-104 {
  margin: 104px 0; }
  @media (max-width: 1367px) {
    .margin-tb-104 {
      margin: 84px 0; } }
  @media (max-width: 1024px) {
    .margin-tb-104 {
      margin: 64px 0; } }
  @media (max-width: 430px) {
    .margin-tb-104 {
      margin: 35px 0; } }
  @media (max-width: 320px) {
    .margin-tb-104 {
      margin: 30px 0; } }

.margin-tb-52 {
  margin: 52px 0; }
  @media (max-width: 1367px) {
    .margin-tb-52 {
      margin: 45px 0; } }
  @media (max-width: 1024px) {
    .margin-tb-52 {
      margin: 38px 0; } }
  @media (max-width: 430px) {
    .margin-tb-52 {
      margin: 17px 0; } }
  @media (max-width: 320px) {
    .margin-tb-52 {
      margin: 10px 0; } }

.padding-tb-104 {
  padding: 104px 0; }
  @media (max-width: 1367px) {
    .padding-tb-104 {
      padding: 84px 0; } }
  @media (max-width: 1024px) {
    .padding-tb-104 {
      padding: 64px 0; } }
  @media (max-width: 430px) {
    .padding-tb-104 {
      padding: 22px 0; } }

.text-white {
  color: #ffffff; }

.text-white-op-50 {
  color: rgba(255, 255, 255, 0.5); }

.text-white-op-70 {
  color: rgba(255, 255, 255, 0.7); }

.text-gray-1 {
  color: #757575; }

.text-gray-2 {
  color: #9E9E9E; }

.text-gray-3 {
  color: #E0E0E0; }

.text-black-2 {
  color: #212121; }

.text-orange-1 {
  color: #FF8300; }

.border-radius-20 {
  border-radius: 20px 0; }

.border-radius-50 {
  border-radius: 50px 0; }
  @media (max-width: 430px) {
    .border-radius-50 {
      border-radius: 20px 0; } }

@keyframes Start {
  from {
    visibility: hidden; }
  to {
    visibility: visible; } }

@keyframes Preloader {
  100% {
    transform: rotate(360deg); } }

@keyframes Play {
  from {
    background-position: 0; }
  to {
    background-position: -360px; } }

@keyframes Ani {
  0% {
    box-shadow: 0 0 0 rgba(220, 220, 220, 0);
    border: 1px solid rgba(220, 220, 220, 0);
    transform: scale(0); }
  70% {
    box-shadow: 0 0 50px gainsboro;
    border: 1px solid gainsboro;
    transform: scale(1); }
  100% {
    box-shadow: 0 0 60px rgba(220, 220, 220, 0);
    border: 0 solid rgba(220, 220, 220, 0);
    transform: scale(2); } }

@keyframes BgColor {
  from {
    background-color: #004835; }
  to {
    background-color: #b05c1e; } }

@keyframes Leftpage {
  from {
    left: -100%; }
  to {
    left: 100%; } }

@keyframes Rotate {
  from {
    transform: rotate(0) translateZ(0); }
  to {
    transform: rotate(360deg) translateZ(0); } }

@keyframes empty {
  0% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg) scale(0.5);
    opacity: 0; }
  100% {
    transform: perspective(400px) rotateX(0) scale(1);
    opacity: 1; } }

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0) scale(1);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateX(90deg) scale(0.5);
    opacity: 0; } }

@keyframes scaleZoom {
  0% {
    opacity: 0;
    transform: rotate(15deg) scale(0.8); }
  100% {
    opacity: 1;
    transform: rotate(0) scale(1); } }

@keyframes scaleSmall {
  0% {
    opacity: 0;
    transform: scale(0.6); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes scaleLarge {
  0% {
    opacity: 0;
    transform: scale(2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes scaleLarge2 {
  0% {
    opacity: 0;
    transform: scale(1.1); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes trackBallSlide {
  0%,
  100%,
  30%,
  60% {
    opacity: 1;
    transform: translateY(-12px); }
  15%,
  50% {
    opacity: 0;
    transform: translateY(8px); } }

@keyframes goHeight {
  from {
    transform: scale3d(1, 0, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

@keyframes goWidth {
  from {
    transform: scale3d(0, 1, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

@keyframes aniHeight {
  from {
    transform: scale3d(1, 1, 1); }
  to {
    transform: scale3d(1, 0, 1); } }

@keyframes aniWidth {
  from {
    transform: scale3d(1, 1, 1); }
  to {
    transform: scale3d(0, 1, 1); } }

@keyframes moveLeft {
  100% {
    transform: translateX(-100%); } }

@keyframes moveRight {
  100% {
    transform: translateX(100%); } }

@keyframes toRight {
  0% {
    opacity: 0;
    right: -100px; }
  100% {
    opacity: 1;
    right: 0; } }

@keyframes toLeft {
  0% {
    opacity: 0;
    left: -100px; }
  100% {
    opacity: 1;
    left: 0; } }

@keyframes goRight {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes goLeft {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes DrawStroke {
  0% {
    stroke-dashoffset: 1300; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes DrawStroke1 {
  0% {
    stroke-dashoffset: 2500; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes DrawStroke2 {
  0% {
    stroke-dashoffset: 4000; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes goBg {
  0% {
    transform: translate3d(-50%, 0, 0); }
  100% {
    transform: none; } }

@keyframes bounceInDown {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    opacity: 1; } }

@keyframes bounceInLeft {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-500px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes bounceInRight {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(500px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes bounceLeft {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1; }
  0%,
  to {
    transform: translate3d(0, 0, 0); }
  60%,
  90% {
    transform: translate3d(10px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); } }

@keyframes bounceRight {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1; }
  from,
  to {
    transform: translate3d(0, 0, 0); }
  60%,
  90% {
    transform: translate3d(-10px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); } }

@keyframes Color2 {
  0%,
  60% {
    background-color: #daa244; }
  20% {
    background-color: #0f4f55; } }

@keyframes Border {
  0%,
  60% {
    border-top-color: #daa244; }
  20% {
    border-top-color: #0f4f55; } }

@keyframes Border1 {
  0%,
  60% {
    border-color: #daa244; }
  20% {
    border-color: #0f4f55; } }

@keyframes Shake {
  from,
  to {
    transform: translate3d(0, 0, 0) rotate(0); }
  0%,
  50% {
    transform: translate3d(-10px, 0, 0) rotate(15deg); }
  25% {
    transform: translate3d(10px, 0, 0) rotate(-15deg); } }

@keyframes Bounce {
  0% {
    transform: translate3d(0, 0, 0) rotate(0); }
  50% {
    transform: translate3d(50px, 20px, 0) rotate(125deg); }
  100% {
    transform: translate3d(0, 0, 0) rotate(360deg); } }

@keyframes StrokeLine {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -200; } }

@keyframes StrokeLine1 {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 200; } }

@keyframes BorderBox {
  0%,
  100% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; } }

@keyframes MaskPlay {
  from {
    mask-position: 0 0; }
  to {
    mask-position: 100% 0; } }

@keyframes Move-Arrow {
  25% {
    opacity: 1; }
  33.3% {
    opacity: 1;
    transform: translateY(10px); }
  66.6% {
    opacity: 1;
    transform: translateY(20px); }
  100% {
    opacity: 0;
    transform: translateY(30px) scale(0.5); } }

@keyframes RotateNew {
  0%,
  100% {
    transform: rotate(0) scale(1);
    opacity: 1; }
  33% {
    transform: rotate(90deg) scale(0.8);
    opacity: 0.3; }
  60% {
    transform: rotate(135deg) scale(1.1);
    opacity: 0.6; }
  80% {
    transform: rotate(35deg) scale(0.9);
    opacity: 1; } }

@keyframes RotateNew1 {
  0%,
  100% {
    transform: rotate(0);
    opacity: 1; }
  33% {
    transform: rotate(90deg);
    opacity: 0.9; }
  80% {
    transform: rotate(135deg);
    opacity: 0.8; } }

@keyframes Show100 {
  from {
    stroke-dashoffset: 537; }
  to {
    stroke-dashoffset: 0; } }

.animated {
  animation-fill-mode: both; }

.fadein,
.fadeout,
.flipoutx {
  animation-fill-mode: forwards; }

.slideout-Up {
  animation-name: slideOutUp; }

.slidein-Up {
  animation-name: slideInUp; }

.fade-In {
  animation-name: fadeIn; }

.fade-Out {
  animation-name: fadeOut; }

.fadein {
  animation-name: fadeIn; }

.fadeout {
  animation-name: fadeOut;
  animation-duration: 1s; }

.flipinx,
.flipoutx {
  backface-visibility: visible !important;
  animation-duration: 0.8s; }

.flipoutx {
  animation-name: flipOutX; }

.flipinx {
  animation-name: flipInX;
  animation-fill-mode: forwards; }

.fadeindown,
.fadeinup,
.fadeoff,
.fadeon,
.goleft,
.goright {
  animation-duration: 1s;
  animation-fill-mode: forwards; }

.fadeindown {
  animation-name: fadeInDown; }

.fadeinup {
  animation-name: fadeInUp; }

.fadeoff {
  animation-name: fadeOff; }

.fadeon {
  animation-name: fadeOn; }

.goleft {
  animation-name: goLeft; }

.goright {
  animation-name: goRight; }

.scalelarge {
  animation: scaleLarge;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

.scalesmall {
  animation: scaleSmall;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

.moveleft,
.moveright {
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  pointer-events: none; }

.moveleft {
  animation-name: moveLeft; }

.moveright {
  animation-name: moveRight; }

.contact-form,
.footer,
.go-top,
.header,
.register-form {
  animation: Start 1s steps(1, end) 0s 1 normal both; }

.slide-fade-out {
  animation: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

.slide-fade-in {
  animation: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  z-index: 10; }

.hotline,
.logo,
.nav-click,
.subscribe,
.title-page h1 {
  opacity: 0;
  pointer-events: none; }

@media screen and (min-width: 1100px) {
  .company-info::after,
  .home-location .content-main::after,
  .location .content-main::after,
  .title-main h2::after,
  .title-main h2::before {
    transform: scale3d(0, 1, 1); }
  .go-back.show,
  .show .degree,
  .show .hotline,
  .show .logo,
  .show .nav-click,
  .show .subscribe {
    pointer-events: auto; }
  .bg-cover::after,
  .bg-cover::before,
  .bg-home,
  .bg-home::after,
  .box-nav,
  .close-video {
    opacity: 0; }
  .box-nav.show {
    animation: toLeft;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .close-video.show {
    animation: toRight;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-text .bg-home {
    animation: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .home-news.show-text .bg-cover::before {
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards; }
  .show-text .bg-cover::after {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .bg-cover::before {
    animation: fadeIn;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .bg-cover.show::before {
    animation: fadeOut;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .container::before {
    opacity: 0;
    transition: opacity 0.6s ease-in-out; }
  .container.show::before {
    opacity: 1; }
  .logo-center .icon-logo,
  .logo-center .text-logo,
  .register-form .input-area,
  .register-form .input-but,
  .register-form .input-text,
  .slogan::after,
  .slogan::before {
    opacity: 0; }
  .show .hotline {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show .subscribe {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show .nav-click {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .show .logo {
    animation: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .title-page.on-show h1 {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .logo-center.show .icon-logo {
    animation: scaleLarge;
    animation-duration: 2s;
    animation-fill-mode: forwards; }
  .logo-center.show .text-logo {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .logo-center.show .text-logo:last-child {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .logo-center.go-up {
    margin-top: calc(-100vw / 5); }
  .register-form.show .title-main {
    animation: fadeInUp;
    animation-duration: 0.6s;
    animation-delay: 1.3s;
    animation-fill-mode: forwards; }
  .register-form.show .input-text {
    animation: fadeInUp;
    animation-duration: 0.6s;
    animation-delay: 1.4s;
    animation-fill-mode: forwards; }
  .register-form.show .input-area {
    animation: fadeInUp;
    animation-duration: 0.6s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards; }
  .register-form.show .input-but {
    animation: fadeInUp;
    animation-duration: 0.6s;
    animation-delay: 1.6s;
    animation-fill-mode: forwards; }
  .register-form.show .overlay-form span:nth-child(1) {
    animation-name: RotateNew;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  .register-form.show .overlay-form span:nth-child(2) {
    animation-name: RotateNew;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  .register-form.show .overlay-form::after {
    animation-name: RotateNew1;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  .show-text .slogan::before {
    animation: scaleLarge;
    animation-duration: 1.2s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards; }
  .show-text .slogan::after {
    animation: fadeInUp;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .group-central .slide-buttons,
  .group-central .slide-pagination {
    opacity: 0;
    transition: opacity 0.3s ease-in-out; }
  .show-text .slide-buttons,
  .show-text .slide-pagination {
    opacity: 1; }
  .slide-pics .txt-banner {
    opacity: 0;
    right: 0;
    transition: all 2s cubic-bezier(0.23, 1, 0.38, 1); }
  .slide-pics .active .txt-banner {
    opacity: 1;
    right: 10vw;
    animation-delay: 1s; }
  .about-overview .bg-cover,
  .apartment-pic,
  .block-title,
  .box-contact-main::after,
  .box-text,
  .box-txt h3,
  .box-txt > p,
  .brochure-library::after,
  .close-news,
  .colum-box-news,
  .company-info h3,
  .company-info p,
  .company-info span,
  .compass2,
  .contact::before,
  .copyright,
  .degree,
  .go-back,
  .home-contact::before,
  .home-facilities .bg-cover,
  .house-des,
  .house-detail .overlay-box,
  .house-model,
  .item-album-home,
  .item-news-home,
  .key-block,
  .keyplan,
  .link-page,
  .logo-investor,
  .map-background,
  .note-facilities,
  .num,
  .overlay-box,
  .partner-info,
  .picture-library::after,
  .product-home,
  .share,
  .slide-library,
  .social,
  .text-plan,
  .title-box,
  .title-main,
  .title-tel,
  .video-library::after,
  .view-more {
    opacity: 0; }
  .show .copyright {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show .degree,
  .show .social {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .show-text .title-main h2::after,
  .show-text .title-main h2::before {
    animation: goWidth;
    animation-duration: 1s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards; }
  .show-text .title-main {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .box-text {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-text .box-txt h3 {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .show-text .box-txt > p {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show-text .view-more {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards; }
  .box-txt > p:nth-child(1) {
    animation-delay: 0.4s; }
  .box-txt > p:nth-child(2) {
    animation-delay: 0.5s; }
  .box-txt > p:nth-child(3) {
    animation-delay: 0.6s; }
  .box-txt > p:nth-child(4) {
    animation-delay: 0.7s; }
  .box-txt > p:nth-child(5) {
    animation-delay: 0.8s; }
  .box-txt > p:nth-child(6) {
    animation-delay: 0.9s; }
  .box-txt > p:nth-child(7) {
    animation-delay: 1s; }
  .box-txt > p:nth-child(8) {
    animation-delay: 1.2s; }
  .box-txt > p:nth-child(9) {
    animation-delay: 1.3s; }
  .show-text .product-home {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards; }
  .show-text .item-news-home:nth-child(even) {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-text .item-news-home:nth-child(odd) {
    animation: goRight;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-text .item-album-home {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.9s;
    animation-fill-mode: forwards; }
  .show-text .overlay-box {
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .home-facilities.show-text .bg-cover {
    animation: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .box-facilities {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show-text .overlay-box span:nth-child(1),
  .show-text .overlay-box span:nth-child(2),
  .show-text .overlay-box.text-include::after,
  .show-text .overlay-box:not(.text-include)::after {
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  .show-text .overlay-box span:nth-child(1) {
    animation-name: RotateNew;
    animation-duration: 10s; }
  .show-text .overlay-box span:nth-child(2) {
    animation-name: RotateNew;
    animation-duration: 15s; }
  .show-text .overlay-box:not(.text-include)::after {
    animation-name: RotateNew;
    animation-duration: 20s; }
  .show-text .overlay-box.text-include::after {
    animation-name: Rotate;
    animation-duration: 20s; }
  .show-text .overlay-box.pause span:nth-child(1),
  .show-text .overlay-box.pause span:nth-child(2),
  .show-text .overlay-box.pause::after {
    animation-play-state: paused; }
  .contact.show-text::after,
  .home-contact.show-text::after {
    animation: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .box-contact-main::after {
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards; }
  .show-text .company-info::after {
    animation: goWidth;
    animation-duration: 1s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards; }
  .show-text .company-info span {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .company-info h3 {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .show-text .company-info p {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards; }
  .show-text .partner-info {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .partner-info:nth-child(3) {
    animation-delay: 0.6s; }
  .partner-info:nth-child(4) {
    animation-delay: 0.7s; }
  .partner-info:nth-child(5) {
    animation-delay: 0.8s; }
  .partner-info:nth-child(6) {
    animation-delay: 0.9s; }
  .buttons,
  .compass,
  .dot-p,
  .home-location .box-cover-right,
  .light,
  .location .viewer,
  .pointer-map {
    opacity: 0; }
  .show-text .light {
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    /* animation-iteration-count: infinite; */ }
  .home-location.show-text .box-cover-right {
    animation: scaleZoom;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .buttons {
    animation: goRight;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .show-text .compass {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards; }
  .pointer-map.show,
  .show-text .pointer-map {
    animation: bounceInDown;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .home-location.show-text::after,
  .location.show-text::after {
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards; }
  .home-location.show-text::before,
  .location.show-text::before {
    animation: goLeft 2s forwards, RotateNew 25s 2s infinite alternate; }
  .home-location.show-text .content-main::after,
  .location.show-text .content-main::after {
    animation: goWidth;
    animation-duration: 1s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards; }
  .show-text .dot-p {
    animation: bounceInDown;
    animation-duration: 1.5s;
    animation-fill-mode: forwards; }
  .dot-p[data-dot="dot-01"] {
    animation-delay: 0.5s; }
  .dot-p[data-dot="dot-02"] {
    animation-delay: 0.6s; }
  .dot-p[data-dot="dot-03"] {
    animation-delay: 0.7s; }
  .dot-p[data-dot="dot-04"] {
    animation-delay: 0.8s; }
  .dot-p[data-dot="dot-05"] {
    animation-delay: 0.9s; }
  .dot-p[data-dot="dot-06"] {
    animation-delay: 1s; }
  .dot-p[data-dot="dot-07"] {
    animation-delay: 1.1s; }
  .dot-p[data-dot="dot-08"] {
    animation-delay: 1.2s; }
  .dot-p[data-dot="dot-09"] {
    animation-delay: 1.3s; }
  .dot-p[data-dot="dot-10"] {
    animation-delay: 1.4s; }
  .dot-p[data-dot="dot-11"] {
    animation-delay: 1.5s; }
  .dot-p[data-dot="dot-12"] {
    animation-delay: 1.6s; }
  .dot-p[data-dot="dot-13"] {
    animation-delay: 1.7s; }
  .about-investor.show-text::after,
  .about-overview.show-text .bg-cover {
    animation: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .logo-investor {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .show-text .compass2,
  .show-text .note-facilities {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .dot-num {
    transform: scale(0); }
  .show-text .note-facilities {
    animation-delay: 0.8s; }
  .show-text .compass2 {
    animation-delay: 0.6s; }
  .show-text .dot-num.show span.circle {
    animation: Ani 2s infinite; }
  .show-text .dot-num.show {
    transform: scale(1); }
  #apartment-page .show-text .title-main {
    animation: goRight;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards; }
  .show-text .text-plan {
    animation: scaleSmall;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .key-block {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .show-text .map-background {
    animation: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .block-title.Aqua,
  .show-text .block-title.Eden {
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-text .block-title.Eden {
    animation-name: bounceInLeft; }
  .show-text .block-title.Aqua {
    animation-name: bounceInRight; }
  .show-text .block-title.Eden.show {
    animation: bounceLeft 3s infinite; }
  .show-text .block-title.Aqua.show {
    animation: bounceRight 3s infinite; }
  .show-house .title-tel {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show-house .title-box {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-house .house-des {
    animation-name: toLeft;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .show-house .apartment-pic {
    animation-name: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .show-house .house-model,
  .show-house .keyplan,
  .show-house .share {
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .go-back.show {
    animation-name: toRight;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show-house .house-model {
    animation-name: goRight; }
  .show-house .keyplan,
  .show-house .share {
    animation-name: goLeft; }
  .show-house .overlay-box {
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards; }
  .show-house .overlay-box span:nth-child(1),
  .show-house .overlay-box span:nth-child(2),
  .show-house .overlay-box::after {
    animation-name: RotateNew;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  .show-house .overlay-box span:nth-child(1) {
    animation-duration: 15s; }
  .show-house .overlay-box span:nth-child(2) {
    animation-duration: 25s; }
  .show-house .overlay-box::after {
    animation-duration: 22s; }
  .show-house .overlay-box.pause span:nth-child(1),
  .show-house .overlay-box.pause span:nth-child(2),
  .show-house .overlay-box.pause::after {
    animation-play-state: paused; }
  .brochure-library.show-text::after,
  .picture-library.show-text::after,
  .video-library.show-text::after {
    animation: scaleLarge2;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show-text .slide-library {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .news-section .bg-cover {
    transition: width 1s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .news-section .bg-cover.show {
    transition: width 3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .link-page.show {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
  .close-news {
    pointer-events: none; }
  .colum-box-news.show .close-news {
    animation: fadeIn;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    pointer-events: auto; }
  .news-list.hide {
    animation: fadeOut;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .colum-box-news.show {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; } }

@media screen and (max-width: 1100px) {
  .bg-cover,
  .content-house,
  .content-main,
  .go-back {
    opacity: 0; }
  .go-back.show,
  .show .hotline,
  .show .logo,
  .show .nav-click,
  .show .subscribe {
    pointer-events: auto; }
  .show .hotline {
    animation: goRight;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .show .subscribe {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards; }
  .show .nav-click {
    animation: goLeft;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .show .logo {
    animation: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show .bg-cover {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show .content-main,
  .title-page.on-show h1 {
    animation: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .go-back.show,
  .show-house .content-house {
    animation-duration: 1s;
    animation-fill-mode: forwards; }
  .show-house .content-house {
    animation-name: fadeInUp; }
  .go-back.show {
    animation-name: goLeft;
    animation-delay: 1s; } }

.map-svg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.light {
  animation: fadeIn;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.st-line {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 1;
  stroke-dasharray: 10;
  stroke-dashoffset: 0;
  animation: StrokeLine 3s infinite linear;
  stroke: white; }

.dot-p, .dot-top {
  transform-origin: center center; }

.animation__wrapper {
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.4); }

.box-txt {
  width: 20vw;
  max-width: 700px;
  height: 30vw;
  left: 18%;
  top: 40%;
  position: absolute; }

.overlay-box {
  animation: fadeIn;
  animation-duration: 2s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  mix-blend-mode: multiply;
  width: 45vw;
  max-width: 700px;
  height: 30vw;
  left: 5%;
  top: 50%;
  margin: -15vw 0 0;
  position: absolute;
  pointer-events: none;
  z-index: 1; }
  .overlay-box span {
    animation-iteration-count: infinite;
    animation-direction: alternate;
    width: 150%;
    height: 150%;
    left: -25%;
    top: -25%;
    position: absolute;
    z-index: -2; }
  .overlay-box span:nth-child(1) {
    animation-name: RotateNew;
    animation-duration: 10s;
    background: url(../assets/img/danang/item-1.svg) center center/contain no-repeat; }
  .overlay-box span:nth-child(2) {
    animation-name: RotateNew;
    animation-duration: 15s;
    background: url(../assets/img/danang/item-2.svg) center center/contain no-repeat; }
  .overlay-box::after {
    content: "";
    animation-name: RotateNew;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: url(../assets/img/danang/item-3.svg) center center/contain no-repeat;
    z-index: 1; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  scroll-behavior: smooth;
  position: relative;
  margin: 0;
  font-family: ROBOTO-REGULAR;
  font-weight: 400;
  line-height: 1.2;
  color: #000000;
  background-color: #ffffff;
  font-size: 18px; }
  @media (max-width: 1366px) {
    body {
      font-size: 14px; } }
  @media (max-width: 1024px) {
    body {
      font-size: 14px; } }
  @media (max-width: 430px) {
    body {
      font-size: 11px; } }

.body-overflow {
  overflow: hidden; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

@font-face {
  font-family: ROBOTO-BLACK;
  src: url("../fonts/Roboto-Black.otf"); }

@font-face {
  font-family: ROBOTO-BLACKITALIC;
  src: url("../fonts/Roboto-BlackItalic.otf"); }

@font-face {
  font-family: ROBOTO-BOLD;
  src: url("../fonts/Roboto-Bold.otf"); }

@font-face {
  font-family: ROBOTO-BOLDITALIC;
  src: url("../fonts/Roboto-BoldItalic.otf"); }

@font-face {
  font-family: ROBOTO-ITALIC;
  src: url("../fonts/Roboto-Italic.otf"); }

@font-face {
  font-family: ROBOTO-LIGHT;
  src: url("../fonts/Roboto-Light.otf"); }

@font-face {
  font-family: ROBOTO-LIGHTITALIC;
  src: url("../fonts/Roboto-LightItalic.otf"); }

@font-face {
  font-family: ROBOTO-MEDIUM;
  src: url("../fonts/Roboto-Medium.otf"); }

@font-face {
  font-family: ROBOTO-MEDIUMITALIC;
  src: url("../fonts/Roboto-MediumItalic.otf"); }

@font-face {
  font-family: ROBOTO-REGULAR;
  src: url("../fonts/Roboto-Regular.otf"); }

@font-face {
  font-family: ROBOTO-THIN;
  src: url("../fonts/Roboto-Thin.otf"); }

@font-face {
  font-family: ROBOTO-THINITALIC;
  src: url("../fonts/Roboto-ThinItalic.otf"); }

.object-cover {
  object-fit: cover;
  object-position: center;
  background-size: cover; }

.object-contain {
  object-fit: contain;
  object-position: center;
  background-size: contain; }

#phone-call {
  position: fixed;
  top: 50%;
  right: 90px;
  z-index: 9999; }
  @media (max-width: 1366px) {
    #phone-call {
      right: 70px;
      bottom: 200px;
      top: unset; } }
  @media (max-width: 430px) {
    #phone-call {
      right: 30px;
      top: unset;
      bottom: 110px; } }
  #phone-call .phone {
    position: relative;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background: #006E29;
    cursor: pointer;
    background-image: url(../assets/icons/phone.png);
    background-repeat: no-repeat;
    background-position: center;
    animation-name: rotate;
    animation-duration: .8s;
    animation-iteration-count: infinite;
    animation-delay: 1.4s;
    transition: .3s ease; }
    #phone-call .phone a {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    @media (max-width: 1366px) {
      #phone-call .phone {
        width: 48px;
        height: 48px;
        background-size: 25px; } }
    @media (max-width: 430px) {
      #phone-call .phone {
        width: 38px;
        height: 38px;
        background-size: 25px; } }
    #phone-call .phone:hover {
      background: #FF8300;
      background-image: url(../assets/icons/phone.png);
      background-repeat: no-repeat;
      background-position: center; }
      @media (max-width: 1366px) {
        #phone-call .phone:hover {
          background-size: 25px; } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  10% {
    transform: rotate(30deg); }
  20% {
    transform: rotate(-20deg); }
  30% {
    transform: rotate(30deg); }
  40% {
    transform: rotate(-20deg); }
  50% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(0deg); } }
  #phone-call .phone-bg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(43, 128, 75, 0.35);
    z-index: -1;
    animation: mymove 2.5s infinite; }
    @media (max-width: 1366px) {
      #phone-call .phone-bg {
        animation: mymove-1366 2.5s infinite; } }

@keyframes mymove-1366 {
  0% {
    width: 38px;
    height: 38px; }
  50% {
    width: 78px;
    height: 78px; }
  100% {
    width: 38px;
    height: 38px; } }

@keyframes mymove {
  0% {
    width: 58px;
    height: 58px; }
  50% {
    width: 98px;
    height: 98px; }
  100% {
    width: 58px;
    height: 58px; } }
  #phone-call .phone-border {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: none;
    z-index: -1;
    animation: border-move 1.3s infinite;
    border: solid 1px rgba(43, 128, 75, 0.35); }

@keyframes border-move {
  0% {
    width: 48px;
    height: 48px; }
  100% {
    width: 158px;
    height: 158px; } }
  #phone-call .phone-infor {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    right: 20px;
    padding: 30px;
    border-radius: 40px 0px 40px 0;
    background: rgba(1, 42, 16, 0.52);
    transition: 0.4s ease-in-out;
    z-index: -1; }
    @media (max-width: 900px) {
      #phone-call .phone-infor {
        right: 20px;
        padding: 25px;
        transform: translateY(-100%);
        display: none !important; } }
    #phone-call .phone-infor {
      animation: fadeEffect 0.5s; }

@keyframes fadeEffect {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
    #phone-call .phone-infor .title {
      font-family: ROBOTO-MEDIUM;
      margin-bottom: 6px;
      color: #FF8300; }
    #phone-call .phone-infor .infor {
      margin-left: auto;
      width: 100%;
      color: #ffffff; }
    #phone-call .phone-infor .btn {
      width: 100%;
      text-align: center; }
    #phone-call .phone-infor .items:not(:last-child) {
      margin-bottom: 15px; }
  #phone-call:hover .phone-infor {
    display: flex; }

a {
  color: #757575; }

.nav-custom {
  position: relative; }
  .nav-custom .swiper-button-next,
  .nav-custom .swiper-button-prev {
    background: rgba(29, 86, 50, 0.2);
    border-radius: 50%;
    width: 48px;
    height: 48px; }
    @media (max-width: 1367px) {
      .nav-custom .swiper-button-next,
      .nav-custom .swiper-button-prev {
        width: 38px;
        height: 38px; } }
    @media (max-width: 630px) {
      .nav-custom .swiper-button-next,
      .nav-custom .swiper-button-prev {
        display: none; } }
  .nav-custom .swiper-scrollbar {
    top: 50%;
    transform: translateY(-50%);
    height: 136px;
    display: flex;
    justify-content: center;
    right: -60px;
    width: 24px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 4px rgba(0, 0, 0, 0.08); }
    .nav-custom .swiper-scrollbar .swiper-scrollbar-drag {
      cursor: pointer;
      width: 70%;
      background: #FF8300; }

.swiper-button-prev {
  left: -100px; }
  .swiper-button-prev::after {
    transform: translateX(-2px);
    color: #FF8300;
    background-image: url("../assets/icons/pre-btn.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
    height: 50%;
    content: "";
    justify-content: center; }
  @media (max-width: 1367px) {
    .swiper-button-prev {
      left: -55px; }
      .swiper-button-prev::after {
        width: 40%;
        height: 40%; } }

.swiper-button-next {
  right: -100px; }
  .swiper-button-next::after {
    transform: translateX(2px);
    color: #FF8300;
    background-image: url("../assets/icons/next-btn.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
    height: 50%;
    content: "";
    justify-content: center; }
  @media (max-width: 1367px) {
    .swiper-button-next {
      right: -55px; }
      .swiper-button-next::after {
        width: 40%;
        height: 40%; } }

.link-facebook_share {
  position: relative;
  cursor: pointer;
  background-color: #0078FF;
  padding: 5px 12px;
  display: inline-flex;
  align-items: center; }
  .link-facebook_share svg {
    margin-right: 10px; }
    .link-facebook_share svg path {
      fill: white; }
  .link-facebook_share span {
    color: white;
    text-transform: uppercase;
    line-height: 1; }

[hidden],
.hidden,
.d-none {
  display: none !important; }

@media (max-width: 1024px) {
  .desktop {
    display: none !important; } }

@media (min-width: 1025px) {
  .mobile {
    display: none !important; } }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.d-flex {
  display: flex; }

.d-inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.align-center {
  align-items: center; }

.align-end {
  align-items: flex-end; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: flex-end; }

.justify-space-between {
  justify-content: space-between; }

.flex-center {
  justify-content: center;
  align-items: center; }

.list-style-none {
  list-style-type: none; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-right: auto;
  margin-left: auto; }

.overflow-hidden {
  overflow: hidden; }

.font-italic {
  font-style: italic; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.field-validation-error {
  display: inline-block;
  margin: 0.5rem 0;
  font-size: 12px;
  color: red; }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1024px) {
    .container {
      max-width: 990px; } }
  @media (min-width: 1201px) {
    .container {
      max-width: 1024px; } }
  @media (min-width: 1367px) {
    .container {
      max-width: 1256px; } }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1025px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1360px) {
  .col-2xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-2xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-2xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-2xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-2xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-2xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-2xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-2xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-2xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-2xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-2xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-2xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-2xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-2xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.col,
[class*="col-"] {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.row {
  display: flex;
  flex-wrap: wrap; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

header {
  position: relative; }
  header .nav-Bar {
    transition: 0.4s ease-in-out;
    position: fixed;
    top: 80px;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 998;
    visibility: hidden; }
    header .nav-Bar ul {
      transition: 0.4s ease-in-out;
      position: absolute;
      right: 0;
      width: 50%;
      top: -100%;
      padding-inline-start: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ffffff; }
      header .nav-Bar ul li {
        padding: 20px 0;
        list-style-type: none;
        width: 100%;
        text-align: center;
        position: relative; }
        header .nav-Bar ul li:not(:last-child)::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 50%;
          height: 0.3px;
          background: #E0E0E0; }
  header .nav-active {
    opacity: 1;
    visibility: visible; }
    header .nav-active ul {
      top: 0; }
  header .nav-scroll {
    top: 46px !important; }
    header .nav-scroll ul {
      background: #1D5632; }
      header .nav-scroll ul li a {
        color: rgba(255, 255, 255, 0.7); }
  header .header__wrapper {
    transition: all 0.4s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 125px;
    background: #ffffff;
    padding: 10px 0;
    z-index: 999; }
    header .header__wrapper .container {
      height: 100%; }
      header .header__wrapper .container .header {
        height: 100%; }
        header .header__wrapper .container .header .headerNav-section {
          height: 100%;
          position: relative;
          flex-direction: column;
          display: flex;
          align-self: center;
          justify-content: center; }
          header .header__wrapper .container .header .headerNav-section ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-inline-start: 0; }
            header .header__wrapper .container .header .headerNav-section ul li {
              list-style-type: none; }
              header .header__wrapper .container .header .headerNav-section ul li .Logo {
                width: 100%;
                height: 100%; }
                header .header__wrapper .container .header .headerNav-section ul li .Logo img {
                  width: 100%;
                  height: auto; }
              header .header__wrapper .container .header .headerNav-section ul li .Logo-mobile {
                display: none; }
              header .header__wrapper .container .header .headerNav-section ul li a {
                font-family: ROBOTO-MEDIUM; }
              header .header__wrapper .container .header .headerNav-section ul li a:hover:not(.Logo) {
                transition: color 0.3s ease-in-out;
                color: #000000;
                border-bottom: solid 3px #FF8300; }
              header .header__wrapper .container .header .headerNav-section ul li .active-link:not(.Logo) {
                color: #000000;
                border-bottom: solid 3px #FF8300; }
            header .header__wrapper .container .header .headerNav-section ul .logo-section {
              text-align: center;
              width: 90px; }
            header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar {
              display: none; }
              header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar .bar1, header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar .bar2, header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar .bar3 {
                border-radius: 10px;
                width: 33px;
                height: 5px;
                background-color: #1D5632;
                margin: 6px 0;
                transition: 0.4s; }
            header .header__wrapper .container .header .headerNav-section ul .change .bar1 {
              -webkit-transform: rotate(-45deg) translate(-9px, 6px);
              transform: rotate(-45deg) translate(-8px, 6px); }
            header .header__wrapper .container .header .headerNav-section ul .change .bar2 {
              opacity: 0; }
            header .header__wrapper .container .header .headerNav-section ul .change .bar3 {
              -webkit-transform: rotate(45deg) translate(-8px, -8px);
              transform: rotate(45deg) translate(-9px, -8px); }
  header .active {
    height: 56px !important;
    background: #1D5632; }
    header .active .container .header .headerNav-section ul li a {
      color: rgba(255, 255, 255, 0.7); }
    header .active .container .header .headerNav-section ul li a:hover:not(.Logo) {
      transition: color 0.3s ease-in-out;
      color: #ffffff; }
    header .active .container .header .headerNav-section ul li .active-link:not(.Logo) {
      color: #ffffff; }
    header .active .container .header .headerNav-section ul .logo-section {
      display: none; }

@media (max-width: 1366px) {
  header .header__wrapper {
    height: 80px; }
    header .header__wrapper .container .header .headerNav-section ul li .Logo img {
      width: 70%;
      height: auto; }
  header .active {
    height: 46px !important;
    background: #1D5632; }
    header .active .container .header .headerNav-section ul li a {
      color: rgba(255, 255, 255, 0.7); }
    header .active .container .header .headerNav-section ul li a:hover:not(.Logo) {
      transition: color 0.3s ease-in-out;
      color: #ffffff; }
    header .active .container .header .headerNav-section ul li .Logo {
      display: none; }
    header .active .container .header .headerNav-section ul li .Logo-mobile {
      display: block; }
    header .active .container .header .headerNav-section ul li .active-link:not(.Logo) {
      color: #ffffff; }
    header .active .container .header .headerNav-section ul .logo-section {
      display: none; } }

@media (max-width: 768px) {
  header .header__wrapper .container .header .headerNav-section ul {
    padding: 0 40px; }
    header .header__wrapper .container .header .headerNav-section ul li:not(.logo-section) {
      display: none; }
    header .header__wrapper .container .header .headerNav-section ul .logo-section {
      display: flex;
      justify-content: center;
      align-items: center; }
      header .header__wrapper .container .header .headerNav-section ul .logo-section .Logo-mobile {
        width: 30%; }
    header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar {
      display: block; }
  header .active .container .header .headerNav-section ul .hamburger-Navbar .bar1, header .active .container .header .headerNav-section ul .hamburger-Navbar .bar2, header .active .container .header .headerNav-section ul .hamburger-Navbar .bar3 {
    background-color: #ffffff; }
  header .active .container .header .headerNav-section ul .logo-section .Logo {
    width: 50px;
    height: 100%; }
    header .active .container .header .headerNav-section ul .logo-section .Logo img {
      width: 40%; } }

@media (max-width: 430px) {
  header .nav-scroll ul li a {
    color: rgba(255, 255, 255, 0.7); }
  header .nav-Bar {
    top: 70px; }
    header .nav-Bar ul {
      display: flex;
      flex-direction: column; }
  header .header__wrapper {
    height: 70px; }
    header .header__wrapper .container .header .headerNav-section ul {
      padding: 0 10px; }
      header .header__wrapper .container .header .headerNav-section ul li .Logo img {
        width: 60%;
        height: auto; }
      header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar .bar1, header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar .bar2, header .header__wrapper .container .header .headerNav-section ul .hamburger-Navbar .bar3 {
        width: 25px;
        height: 4px;
        margin: 3px 0; }
      header .header__wrapper .container .header .headerNav-section ul .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-1px, 9px);
        transform: rotate(-45deg) translate(-1px, 9px); }
      header .header__wrapper .container .header .headerNav-section ul .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-1px, -9px);
        transform: rotate(45deg) translate(-1px, -9px); } }

@media (max-width: 380px) {
  header .nav-Bar {
    top: 55px; }
    header .nav-Bar ul {
      display: flex;
      flex-direction: column; }
  header .header__wrapper {
    height: 55px; }
    header .header__wrapper .container .header .headerNav-section ul li .Logo img {
      width: 50%;
      height: auto; }
  header .active .container .header .headerNav-section ul .hamburger-Navbar .bar1, header .active .container .header .headerNav-section ul .hamburger-Navbar .bar2, header .active .container .header .headerNav-section ul .hamburger-Navbar .bar3 {
    background-color: #ffffff; }
  header .active .container .header .headerNav-section ul li .Logo {
    width: 50px; }
    header .active .container .header .headerNav-section ul li .Logo img {
      width: 100%;
      height: auto; } }

.map-mobile {
  display: none; }
  @media (max-width: 1024px) {
    .map-mobile {
      margin-top: 54px; } }
  @media (max-width: 430px) {
    .map-mobile {
      margin-top: 34px; } }

.footer__wrapper {
  margin-top: 104px;
  background: #1D5632; }
  @media (max-width: 1367px) {
    .footer__wrapper {
      margin-top: 84px; } }
  @media (max-width: 1024px) {
    .footer__wrapper {
      margin-top: 54px; } }
  @media (max-width: 430px) {
    .footer__wrapper {
      margin-top: 34px; } }
  .footer__wrapper .container {
    position: relative; }
    .footer__wrapper .container .backtoTop-overlay {
      top: 50%;
      right: -80px;
      position: absolute;
      cursor: pointer;
      transform: rotate(270deg); }
      .footer__wrapper .container .backtoTop-overlay p {
        text-transform: uppercase;
        position: relative; }
        .footer__wrapper .container .backtoTop-overlay p::after {
          position: absolute;
          right: -30px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          background-image: url("../assets/icons/next-btn.png");
          width: 16px;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center; }
    .footer__wrapper .container .footer-left {
      width: 50%;
      display: flex;
      align-items: center;
      height: 224px;
      padding-right: 20px; }
      .footer__wrapper .container .footer-left__logo {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 30px; }
        .footer__wrapper .container .footer-left__logo .Logo {
          width: 136px; }
          .footer__wrapper .container .footer-left__logo .Logo img {
            width: 100%;
            height: auto; }
        .footer__wrapper .container .footer-left__logo .Social {
          display: flex;
          align-content: center;
          justify-content: space-between; }
          .footer__wrapper .container .footer-left__logo .Social a:first-child {
            position: relative;
            padding: 5px 19px;
            border-radius: 5px;
            border: solid 1px #ffffff;
            background: none; }
            .footer__wrapper .container .footer-left__logo .Social a:first-child img {
              position: absolute;
              top: 50%;
              left: 54%;
              transform: translate(-50%, -50%);
              width: 60%;
              height: 60%;
              object-fit: contain; }
          .footer__wrapper .container .footer-left__logo .Social a:not(:first-child) {
            position: relative;
            padding: 15px;
            border-radius: 50%;
            border: solid 1px #ffffff;
            background: none; }
            .footer__wrapper .container .footer-left__logo .Social a:not(:first-child) img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 60%;
              height: 60%;
              object-fit: contain; }
          .footer__wrapper .container .footer-left__logo .Social a:hover {
            background: #ffffff; }
        .footer__wrapper .container .footer-left__logo .Language {
          align-items: center;
          display: flex; }
          .footer__wrapper .container .footer-left__logo .Language * {
            cursor: pointer;
            margin-right: 10px;
            text-transform: uppercase;
            font-family: ROBOTO-MEDIUM; }
          .footer__wrapper .container .footer-left__logo .Language span {
            cursor: unset; }
        .footer__wrapper .container .footer-left__logo .active {
          color: rgba(255, 255, 255, 0.7); }
      .footer__wrapper .container .footer-left__infor {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .footer__wrapper .container .footer-left__infor .text-24 {
          font-family: ROBOTO-MEDIUM; }
        .footer__wrapper .container .footer-left__infor .infor__items {
          display: flex;
          align-items: flex-start;
          margin-top: 10px; }
          .footer__wrapper .container .footer-left__infor .infor__items img {
            margin-top: 2px;
            margin-right: 10px; }
        .footer__wrapper .container .footer-left__infor .infor--link ul {
          padding-inline-start: 0; }
          .footer__wrapper .container .footer-left__infor .infor--link ul li {
            list-style-type: none;
            margin-top: 10px;
            font-family: ROBOTO-MEDIUM; }
            .footer__wrapper .container .footer-left__infor .infor--link ul li::before {
              content: "";
              background-color: #FF8300;
              font-weight: bold;
              display: inline-block;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              margin-left: -10px;
              margin-right: 17px;
              border: solid 1px #707070; }
            .footer__wrapper .container .footer-left__infor .infor--link ul li:hover a {
              color: #ffffff; }
        .footer__wrapper .container .footer-left__infor .infor--link .active a {
          color: #ffffff; }
    .footer__wrapper .container .footer-right {
      padding: 0;
      width: 50%; }
      .footer__wrapper .container .footer-right iframe {
        width: 100%; }

@media (max-width: 1366px) {
  .footer__wrapper {
    background: #1D5632; }
    .footer__wrapper .container {
      position: relative; }
      .footer__wrapper .container .backtoTop-overlay {
        top: 50%;
        right: -80px;
        position: absolute;
        cursor: pointer;
        transform: rotate(270deg); }
        .footer__wrapper .container .backtoTop-overlay p {
          text-transform: uppercase;
          position: relative; }
          .footer__wrapper .container .backtoTop-overlay p::after {
            position: absolute;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            background-image: url("../assets/icons/next-btn.png");
            width: 16px;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center; }
      .footer__wrapper .container .footer-left {
        display: flex;
        align-items: center;
        height: 200px; }
        .footer__wrapper .container .footer-left__logo {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 30px; }
          .footer__wrapper .container .footer-left__logo .Logo {
            width: 96px; }
            .footer__wrapper .container .footer-left__logo .Logo img {
              width: 100%;
              height: auto; }
          .footer__wrapper .container .footer-left__logo .Social a:first-child {
            padding: 3px 14px; }
          .footer__wrapper .container .footer-left__logo .Social a:not(:first-child) {
            padding: 12px; }
          .footer__wrapper .container .footer-left__logo .Language {
            align-items: center;
            display: flex; }
            .footer__wrapper .container .footer-left__logo .Language * {
              cursor: pointer;
              margin-right: 10px;
              text-transform: uppercase; }
            .footer__wrapper .container .footer-left__logo .Language span {
              cursor: unset; }
          .footer__wrapper .container .footer-left__logo .active {
            color: rgba(255, 255, 255, 0.7); }
        .footer__wrapper .container .footer-left__infor {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .footer__wrapper .container .footer-left__infor .infor__items {
            display: flex;
            align-items: flex-start;
            margin-top: 10px; }
            .footer__wrapper .container .footer-left__infor .infor__items img {
              margin-top: 2px;
              margin-right: 10px; }
          .footer__wrapper .container .footer-left__infor .infor--link ul {
            padding-inline-start: 0; }
            .footer__wrapper .container .footer-left__infor .infor--link ul li {
              list-style-type: none;
              margin-top: 10px; }
              .footer__wrapper .container .footer-left__infor .infor--link ul li::before {
                content: "";
                background-color: #FF8300;
                font-weight: bold;
                display: inline-block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin-left: -10px;
                margin-right: 17px;
                border: solid 1px #707070; }
          .footer__wrapper .container .footer-left__infor .infor--link .active a {
            color: #ffffff; } }

@media (max-width: 1200px) {
  .footer__wrapper .container .backtoTop-overlay {
    right: -50px; }
  .footer__wrapper .container .footer-right iframe {
    width: 100%; } }

@media (max-width: 1024px) {
  .map-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .map-mobile iframe {
      width: 100%; }
  .footer__wrapper .container {
    flex-direction: column-reverse; }
    .footer__wrapper .container .backtoTop-overlay {
      top: 50%;
      right: -20px; }
    .footer__wrapper .container .footer-left {
      width: 100%;
      justify-content: center;
      margin-top: 30px; }
    .footer__wrapper .container .footer-right {
      display: none; }
      .footer__wrapper .container .footer-right iframe {
        width: 100%; } }

@media (max-width: 430px) {
  .footer__wrapper .container {
    position: relative; }
    .footer__wrapper .container .backtoTop-overlay {
      right: -10px; }
      .footer__wrapper .container .backtoTop-overlay p::after {
        position: absolute; }
    .footer__wrapper .container .footer-left {
      margin-bottom: 30px;
      height: 150px; }
      .footer__wrapper .container .footer-left__logo {
        margin-right: 15px; }
        .footer__wrapper .container .footer-left__logo .Logo {
          width: 80px; }
        .footer__wrapper .container .footer-left__logo .Social a:first-child {
          padding: 3px 12px; }
        .footer__wrapper .container .footer-left__logo .Social a:not(:first-child) {
          padding: 10px; }
      .footer__wrapper .container .footer-left__infor .infor__items {
        margin-top: 10px; }
        .footer__wrapper .container .footer-left__infor .infor__items img {
          width: 10px; }
      .footer__wrapper .container .footer-left__infor .infor--link ul li::before {
        width: 7px;
        height: 7px;
        margin-right: 10px; }
    .footer__wrapper .container .footer-right {
      display: none; }
      .footer__wrapper .container .footer-right iframe {
        height: 160px; } }

@media (max-width: 380px) {
  .footer__wrapper .container {
    position: relative; }
    .footer__wrapper .container .backtoTop-overlay {
      right: -20px; }
    .footer__wrapper .container .footer-left {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      height: 100%; }
      .footer__wrapper .container .footer-left__logo {
        margin-bottom: 30px;
        height: 130px; } }

@media (max-width: 320px) {
  .footer__wrapper .container {
    position: relative; }
    .footer__wrapper .container .backtoTop-overlay {
      right: -20px; }
    .footer__wrapper .container .footer-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
      height: 100%; }
      .footer__wrapper .container .footer-left__logo {
        margin-bottom: 30px;
        height: 130px; } }

::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.7); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9E9E9E;
  border-radius: 0; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(29, 86, 50, 0.8); }

.btn {
  margin-top: auto;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border: 0;
  appearance: none;
  background: #FF8300;
  border-radius: 20px 0;
  color: #ffffff;
  padding: 10px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer; }
  .btn:hover {
    background: #1D5632; }
  @media (max-width: 1366px) {
    .btn {
      padding: 7px 10px; } }
  @media (max-width: 1366px) {
    .btn {
      padding: 7px 10px;
      border-radius: 15px 0; } }
  @media (max-width: 430px) {
    .btn {
      border-radius: 10px 0;
      padding: 5px 12px; } }
  @media (max-width: 320px) {
    .btn {
      padding: 3px 10px; } }

.tab-container .list-tab .item {
  cursor: pointer; }
  .tab-container .list-tab .item.active {
    color: #1D5632; }

.tab-container .tab-content .content {
  display: none;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.2, 0.5, 0.5, 0.8); }
  .tab-container .tab-content .content.show {
    opacity: 1; }

#loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff; }
  #loading-container .loading-wrapper {
    position: absolute;
    padding-bottom: 75px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 450px;
    padding: 0 20px; }
  #loading-container .progress {
    height: 15px;
    background-color: #e9ecef;
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px black;
    border-radius: 8px;
    overflow: hidden; }
  #loading-container .progress-status {
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 600; }
  #loading-container #progress-bar {
    max-width: 100%;
    width: 0%;
    height: 100%;
    background-color: #ff9e0c;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    background-repeat: repeat;
    animation: background 10s linear infinite forwards; }
  #loading-container #loading-logo {
    text-align: center;
    padding: 20px 0; }
    #loading-container #loading-logo img,
    #loading-container #loading-logo svg {
      max-width: 200px;
      width: 100%; }

@keyframes slideDown {
  0% {
    transform: translate3d(0, 100%, 0); }
  100% {
    transform: translate3d(0, 0%, 0); } }

@keyframes slideUp {
  0% {
    transform: translate3d(0, 0%, 0); }
  100% {
    transform: translate3d(0, 100%, 0); } }

.fp-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden; }

.fp-wrapper {
  width: 100%;
  height: 100%;
  position: relative; }

.fp-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #e6e6e6;
  box-shadow: 0 0 10px black;
  left: 0;
  top: 0;
  z-index: 1; }
  .fp-slide.active {
    z-index: 2; }
  .fp-slide.changing {
    z-index: 3; }

.fp-dots {
  position: fixed;
  z-index: 1001;
  right: 30px;
  bottom: 50px;
  width: 20px; }
  .fp-dots .fp-dot {
    width: 8px;
    height: 8px;
    border: 1px solid;
    margin: 5px auto;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .fp-dots .fp-dot .fp-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      color: white;
      display: none; }
    .fp-dots .fp-dot .fp-title {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      margin-right: -15px;
      padding-right: 10px;
      white-space: nowrap;
      opacity: 0;
      transition: all 0.5s ease;
      display: none; }
    .fp-dots .fp-dot.active {
      background-color: transparent;
      height: 16px; }
    @media (min-width: 1025px) {
      .fp-dots .fp-dot:hover .fp-title {
        margin-right: 0;
        opacity: 1; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; }
  .breadcrumb--items {
    font-family: ROBOTO-MEDIUM;
    color: #9E9E9E;
    position: relative;
    font-size: 24px;
    cursor: pointer; }
    @media (max-width: 1366px) {
      .breadcrumb--items {
        font-size: 20px; } }
    @media (max-width: 1201px) {
      .breadcrumb--items {
        font-size: 18px; } }
    .breadcrumb--items:not(:last-child) {
      margin-right: 60px; }
    .breadcrumb--items:not(:last-child):after {
      position: absolute;
      top: 47%;
      transform: translateY(-50%);
      content: "";
      right: -35px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-left: 10px solid #555;
      border-bottom: 8px solid transparent;
      border-left-color: #9E9E9E; }
  @media (max-width: 1366px) {
    .breadcrumb--items:not(:last-child) {
      margin-right: 50px; }
    .breadcrumb--items:not(:last-child):after {
      right: -30px;
      border-top: 7px solid transparent;
      border-left: 9px solid #555;
      border-bottom: 7px solid transparent;
      border-left-color: #9E9E9E; } }
  @media (max-width: 768px) {
    .breadcrumb--items {
      font-size: 18px; }
      .breadcrumb--items:not(:last-child) {
        margin-right: 30px; }
      .breadcrumb--items:not(:last-child):after {
        right: -20px;
        border-top: 7px solid transparent;
        border-left: 9px solid #555;
        border-bottom: 7px solid transparent;
        border-left-color: #9E9E9E; } }
  @media (max-width: 540px) {
    .breadcrumb--items:not(:last-child):after {
      right: -18px;
      border-top: 5px solid transparent;
      border-left: 7px solid #555;
      border-bottom: 5px solid transparent;
      border-left-color: #9E9E9E; } }
  @media (max-width: 320px) {
    .breadcrumb--items {
      margin-right: 30px; }
      .breadcrumb--items:not(:last-child):after {
        right: -15px;
        border-top: 5px solid transparent;
        border-left: 7px solid #555;
        border-bottom: 5px solid transparent;
        border-left-color: #9E9E9E; } }

.home-banner__wrapper .home-banner {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }
  @media (max-width: 1366px) {
    .home-banner__wrapper .home-banner {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .home-banner__wrapper .home-banner {
      height: 100%;
      min-height: 350px; } }
  @media (max-width: 430px) {
    .home-banner__wrapper .home-banner {
      margin-top: 70px;
      min-height: 300px; } }
  @media (max-width: 380px) {
    .home-banner__wrapper .home-banner {
      margin-top: 55px;
      min-height: 250px; } }
  .home-banner__wrapper .home-banner .banner {
    overflow: hidden;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .home-banner__wrapper .home-banner .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .home-banner__wrapper .home-banner .banner .overlay-text-banner {
      width: 100%;
      position: absolute;
      top: 56%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2; }
      @media (max-width: 1660px) {
        .home-banner__wrapper .home-banner .banner .overlay-text-banner {
          left: 15%;
          width: 45%; } }
      @media (max-width: 640px) {
        .home-banner__wrapper .home-banner .banner .overlay-text-banner {
          left: 20%;
          width: 60%; } }
      .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section {
        width: 50%; }
        @media (max-width: 1660px) {
          .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section {
            width: 100%; } }
        .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section .text-24 {
          font-family: ROBOTO-MEDIUM; }
        .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section .title {
          text-transform: uppercase; }
        .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section .decs, .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section .title {
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden; }
        .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section * {
          margin-bottom: 30px; }
          @media (max-width: 640px) {
            .home-banner__wrapper .home-banner .banner .overlay-text-banner .text-section * {
              margin-bottom: 15px; } }
  .home-banner__wrapper .home-banner .overlay-pattern {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(1, 42, 16, 0.52);
    visibility: visible; }
    .home-banner__wrapper .home-banner .overlay-pattern img {
      height: 100%;
      object-fit: contain; }

.home-2__wrapper .container {
  position: relative; }
  .home-2__wrapper .container .img-section {
    width: 724px;
    height: 416px; }
    .home-2__wrapper .container .img-section img {
      width: 100%;
      height: 100%; }
  .home-2__wrapper .container .text-section {
    padding: 0 40px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 724px;
    height: 304px;
    background: #ffffff;
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.08); }
    .home-2__wrapper .container .text-section .text-24 {
      font-family: ROBOTO-MEDIUM; }
    .home-2__wrapper .container .text-section .text {
      width: 70%; }
      .home-2__wrapper .container .text-section .text *:not(:last-child) {
        margin-bottom: 20px; }
  .home-2__wrapper .container .overlay {
    position: absolute;
    top: 0;
    right: 0; }

@media (max-width: 1366px) {
  .home-2__wrapper .container {
    position: relative; }
    .home-2__wrapper .container .img-section {
      width: 50%;
      height: 100%; }
      .home-2__wrapper .container .img-section img {
        width: 100%;
        height: auto; }
    .home-2__wrapper .container .text-section {
      width: 624px;
      height: 250px; }
    .home-2__wrapper .container .overlay {
      height: 100%; }
      .home-2__wrapper .container .overlay img {
        height: 100%; } }

@media (max-width: 1024px) {
  .home-2__wrapper .container {
    position: relative; }
    .home-2__wrapper .container .text-section {
      width: 600px; } }

@media (max-width: 768px) {
  .home-2__wrapper .container {
    position: relative; }
    .home-2__wrapper .container .img-section {
      width: 100%;
      height: 100%; }
    .home-2__wrapper .container .text-section {
      position: relative;
      transform: unset;
      width: 100%; }
    .home-2__wrapper .container .overlay {
      position: absolute; } }

@media (max-width: 768px) {
  .home-2__wrapper {
    margin-bottom: 150px; }
    .home-2__wrapper .container {
      position: relative; }
      .home-2__wrapper .container .text-section {
        top: unset;
        width: 90%;
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translate(-50%);
        padding: 0 30px;
        height: 170px; }
        .home-2__wrapper .container .text-section .text *:not(:last-child) {
          margin-bottom: 10px; }
        .home-2__wrapper .container .text-section .text .decs {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden; } }

@media (max-width: 430px) {
  .home-2__wrapper .container .text-section {
    bottom: -120px; } }

@media (max-width: 320px) {
  .home-2__wrapper {
    margin-bottom: 140px; }
    .home-2__wrapper .container {
      position: relative; }
      .home-2__wrapper .container .text-section {
        padding: 0 20px;
        height: 170px; } }

.home-3__wrapper {
  background: rgba(29, 86, 50, 0.56);
  padding: 15px 10px; }
  .home-3__wrapper .home-3 .swiper-slide {
    width: 302px; }
    .home-3__wrapper .home-3 .swiper-slide a {
      width: 100%; }
      .home-3__wrapper .home-3 .swiper-slide a img {
        width: 100%;
        height: auto; }

@media (max-width: 430px) {
  .home-3__wrapper {
    background: rgba(29, 86, 50, 0.56);
    padding: 7px 10px; } }

.home-4__wrapper .home-4 {
  display: flex;
  align-items: center;
  position: relative; }
  .home-4__wrapper .home-4 .img-section {
    width: 40%;
    padding-bottom: 26%;
    position: relative; }
    @media (max-width: 900px) {
      .home-4__wrapper .home-4 .img-section {
        width: 53%; } }
    @media (max-width: 768px) {
      .home-4__wrapper .home-4 .img-section {
        padding-bottom: 30%; } }
    @media (max-width: 640px) {
      .home-4__wrapper .home-4 .img-section {
        width: 100%;
        padding-bottom: 60%; } }
    .home-4__wrapper .home-4 .img-section img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .home-4__wrapper .home-4 .text-section {
    width: 60%;
    padding-right: 90px;
    padding-left: 30px; }
    .home-4__wrapper .home-4 .text-section .text-24 {
      font-family: ROBOTO-MEDIUM; }
    .home-4__wrapper .home-4 .text-section .text-gray-2 {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: justify; }
    .home-4__wrapper .home-4 .text-section *:not(:last-child) {
      margin-bottom: 20px; }
      @media (max-width: 768px) {
        .home-4__wrapper .home-4 .text-section *:not(:last-child) {
          margin-bottom: 10px; } }
    @media (max-width: 900px) {
      .home-4__wrapper .home-4 .text-section {
        padding-right: 30px; } }
    @media (max-width: 640px) {
      .home-4__wrapper .home-4 .text-section {
        background: rgba(1, 42, 16, 0.52);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .home-4__wrapper .home-4 .text-section .text p {
          color: #ffffff; }
        .home-4__wrapper .home-4 .text-section .text-gray-2 {
          -webkit-line-clamp: 5; }
        .home-4__wrapper .home-4 .text-section *:not(:last-child) {
          margin-bottom: 10px; } }

.home-5__wrapper .home-5 {
  width: 100%;
  padding-bottom: 30%;
  position: relative;
  min-height: 200px; }
  .home-5__wrapper .home-5 img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.home-6__wrapper .home-6 {
  background: #1D5632;
  display: flex;
  height: auto;
  position: relative; }
  @media (max-width: 640px) {
    .home-6__wrapper .home-6 {
      flex-direction: column;
      background: unset; } }
  .home-6__wrapper .home-6 .img-section {
    width: 45%; }
    @media (max-width: 640px) {
      .home-6__wrapper .home-6 .img-section {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative; }
        .home-6__wrapper .home-6 .img-section::after {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          background: #1D5632;
          height: 30%;
          content: "";
          z-index: -1; } }
    .home-6__wrapper .home-6 .img-section img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 640px) {
        .home-6__wrapper .home-6 .img-section img {
          width: 65%; } }
  .home-6__wrapper .home-6 .text-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 55%;
    position: relative; }
    @media (max-width: 640px) {
      .home-6__wrapper .home-6 .text-section {
        width: 100%;
        background: #1D5632; } }
    .home-6__wrapper .home-6 .text-section .overlay-section {
      margin-top: auto; }
    .home-6__wrapper .home-6 .text-section .text {
      height: 50%;
      padding-left: 20px;
      padding-right: 90px;
      padding-bottom: 30px; }
      @media (max-width: 1024px) {
        .home-6__wrapper .home-6 .text-section .text {
          padding: 20px; } }
      .home-6__wrapper .home-6 .text-section .text .text-24 {
        font-family: ROBOTO-MEDIUM; }
      .home-6__wrapper .home-6 .text-section .text * {
        margin-bottom: 30px; }
        @media (max-width: 540px) {
          .home-6__wrapper .home-6 .text-section .text * {
            margin-bottom: 20px; } }
      .home-6__wrapper .home-6 .text-section .text .title {
        text-transform: uppercase; }
    .home-6__wrapper .home-6 .text-section .overlay {
      width: 80%;
      display: inline-flex;
      align-self: flex-end; }
      @media (max-width: 640px) {
        .home-6__wrapper .home-6 .text-section .overlay {
          display: none; } }
      .home-6__wrapper .home-6 .text-section .overlay img {
        object-fit: cover;
        width: 100%;
        height: 100%; }

.home-7__wrapper {
  background: rgba(216, 226, 220, 0.17); }
  .home-7__wrapper .home-7 {
    position: relative; }
    .home-7__wrapper .home-7 .label {
      font-family: ROBOTO-MEDIUM;
      top: -40px;
      position: absolute; }
    .home-7__wrapper .home-7 .nav-custom {
      position: relative; }
      .home-7__wrapper .home-7 .nav-custom .swiper-wrapper {
        padding: 10px 0; }
        .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide {
          background: #ffffff;
          width: 172px;
          height: 216px;
          box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.08);
          position: relative; }
          .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide::after {
            width: 100%;
            height: 100%;
            top: -10px;
            left: 0;
            position: absolute;
            content: "";
            background: url("../assets/images/home-page/overlay-partner.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto; }
          .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide {
            position: relative;
            z-index: 3;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly; }
            .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
              width: 86px;
              height: 86px;
              border-radius: 50%;
              border: solid 3px #1D5632;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              overflow: hidden; }
              .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper .image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%; }
                .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper .image img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain; }

@media (max-width: 1366px) {
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide {
    height: 190px; }
    .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
      width: 66px;
      height: 66px; } }

@media (max-width: 1200px) {
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide {
    height: 210px; }
    .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
      width: 66px;
      height: 66px; } }

@media (max-width: 1200px) {
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide {
    height: 210px; }
    .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
      width: 66px;
      height: 66px; }
  .home-7__wrapper .home-7 .nav-custom .swiper-button-next, .home-7__wrapper .home-7 .nav-custom .swiper-button-prev {
    display: none; } }

@media (max-width: 768px) {
  .home-7__wrapper .home-7 .label {
    top: -20px; }
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide::after {
    background-size: 100% auto; }
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
    width: 70px;
    height: 70px; } }

@media (max-width: 430px) {
  .home-7__wrapper .home-7 .label {
    top: -25px; }
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide {
    height: 160px; }
    .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
      border: solid 2px #1D5632;
      width: 50px;
      height: 50px; } }

@media (max-width: 380px) {
  .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide {
    height: 130px; }
    .home-7__wrapper .home-7 .nav-custom .swiper-wrapper .swiper-slide .slide .img-wrapper {
      width: 40px;
      height: 40px; } }

.home-8__wrapper .background {
  position: relative;
  width: 100%;
  padding-bottom: 25%;
  min-height: 340px; }
  .home-8__wrapper .background img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .home-8__wrapper .background .overlay {
    background: rgba(1, 42, 16, 0.52);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .home-8__wrapper .background .overlay .container {
      height: 100%;
      display: flex;
      align-items: center; }
      .home-8__wrapper .background .overlay .container .content {
        width: 70%;
        align-items: flex-start; }
        @media (max-width: 900px) {
          .home-8__wrapper .background .overlay .container .content {
            width: 80%;
            flex-direction: column;
            margin: auto;
            align-items: center; } }
        .home-8__wrapper .background .overlay .container .content .left {
          justify-self: unset;
          width: 45%; }
          @media (max-width: 900px) {
            .home-8__wrapper .background .overlay .container .content .left {
              width: 100%;
              margin-bottom: 20px; } }
          .home-8__wrapper .background .overlay .container .content .left h1 {
            color: #ffffff;
            font-size: 48px; }
            @media (max-width: 1367px) {
              .home-8__wrapper .background .overlay .container .content .left h1 {
                font-size: 38px; } }
            @media (max-width: 1201px) {
              .home-8__wrapper .background .overlay .container .content .left h1 {
                font-size: 34px; } }
            @media (max-width: 1024px) {
              .home-8__wrapper .background .overlay .container .content .left h1 {
                font-size: 30px; } }
            @media (max-width: 769px) {
              .home-8__wrapper .background .overlay .container .content .left h1 {
                font-size: 26px; } }
            @media (max-width: 430px) {
              .home-8__wrapper .background .overlay .container .content .left h1 {
                font-size: 20px; } }
            @media (max-width: 320px) {
              .home-8__wrapper .background .overlay .container .content .left h1 {
                font-size: 13px; } }
          .home-8__wrapper .background .overlay .container .content .left > * {
            text-align: left; }
          .home-8__wrapper .background .overlay .container .content .left .desc {
            color: #ffffff;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; }
        .home-8__wrapper .background .overlay .container .content .right {
          margin-top: 7px;
          margin-left: auto;
          width: 50%; }
          @media (max-width: 900px) {
            .home-8__wrapper .background .overlay .container .content .right {
              width: 100%;
              margin-left: unset; } }
          .home-8__wrapper .background .overlay .container .content .right .form-control {
            margin-bottom: 8px;
            width: 80%;
            height: 40px;
            background: #ffffff; }
            @media (max-width: 900px) {
              .home-8__wrapper .background .overlay .container .content .right .form-control {
                width: 100%; } }
            @media (max-width: 540px) {
              .home-8__wrapper .background .overlay .container .content .right .form-control {
                height: 35px; } }
            .home-8__wrapper .background .overlay .container .content .right .form-control:first-child {
              position: relative; }
              .home-8__wrapper .background .overlay .container .content .right .form-control:first-child::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 14px;
                width: 0px;
                height: 0px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 12px solid #555;
                border-top-color: #FF8300;
                content: ""; }
            .home-8__wrapper .background .overlay .container .content .right .form-control select, .home-8__wrapper .background .overlay .container .content .right .form-control input {
              cursor: pointer;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              height: 100%;
              width: 100%;
              border: 0;
              background-color: transparent;
              padding-left: 14px;
              color: #9E9E9E;
              z-index: 1; }
              .home-8__wrapper .background .overlay .container .content .right .form-control select::placeholder, .home-8__wrapper .background .overlay .container .content .right .form-control input::placeholder {
                color: #9E9E9E; }
          .home-8__wrapper .background .overlay .container .content .right .btn {
            text-align: center;
            padding: 6px 40px; }

.ourservices-banner {
  scroll-behavior: smooth; }
  .ourservices-banner .ourservices-background {
    width: 100%;
    height: calc(100vh - 125px);
    position: relative;
    margin-top: 125px;
    max-width: 1920px;
    max-height: 1080px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 1366px) {
      .ourservices-banner .ourservices-background {
        height: calc(100vh - 80px);
        margin-top: 80px; } }
    @media (max-width: 1024px) {
      .ourservices-banner .ourservices-background {
        height: 100%;
        min-height: 350px; } }
    @media (max-width: 430px) {
      .ourservices-banner .ourservices-background {
        margin-top: 70px; } }
    @media (max-width: 380px) {
      .ourservices-banner .ourservices-background {
        margin-top: 55px;
        min-height: 250px; } }
    .ourservices-banner .ourservices-background img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .ourservices-banner .ourservices-background .ourservices-box {
      position: absolute;
      bottom: 0;
      width: 100%; }
      .ourservices-banner .ourservices-background .ourservices-box__items {
        width: 100%;
        padding-bottom: 70%;
        overflow: hidden; }
        .ourservices-banner .ourservices-background .ourservices-box__items .img-box {
          overflow: hidden;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: 0.3s ease-in-out;
          background: #1D5632;
          text-transform: uppercase; }
          .ourservices-banner .ourservices-background .ourservices-box__items .img-box .opacity {
            overflow: hidden;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            position: absolute;
            opacity: 0.5;
            width: 100%;
            height: 100%;
            transition: .3s ease; }
          .ourservices-banner .ourservices-background .ourservices-box__items .img-box p {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-transform: uppercase;
            text-align: center; }
            @media (max-width: 1640px) {
              .ourservices-banner .ourservices-background .ourservices-box__items .img-box p {
                font-size: 2.8vw; } }
            @media (max-width: 540px) {
              .ourservices-banner .ourservices-background .ourservices-box__items .img-box p {
                font-size: 3.4vw; } }
        .ourservices-banner .ourservices-background .ourservices-box__items:hover .opacity {
          background-color: white;
          opacity: 1; }
          .ourservices-banner .ourservices-background .ourservices-box__items:hover .opacity img {
            display: none; }
        .ourservices-banner .ourservices-background .ourservices-box__items:hover p {
          color: #1D5632;
          font-family: ROBOTO-BOLD; }

.ourservices-membership__title {
  margin-bottom: 75px; }
  @media (max-width: 540px) {
    .ourservices-membership__title {
      margin-bottom: 10vw; } }
  .ourservices-membership__title > * {
    text-align: center; }

.ourservices-membership__container {
  display: flex;
  flex-wrap: wrap; }
  .ourservices-membership__container:last-child {
    margin: none; }
  @media (max-width: 768px) {
    .ourservices-membership__container .box-content {
      margin-bottom: 12vw; } }
  @media (max-width: 380px) {
    .ourservices-membership__container .box-content {
      min-width: 85%;
      margin-left: auto;
      margin-right: auto; } }
  .ourservices-membership__container .box-content__card {
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    height: 100%; }
    .ourservices-membership__container .box-content__card:hover {
      background-color: #EBF0ED; }
    .ourservices-membership__container .box-content__card .header {
      position: relative;
      padding-bottom: 53%;
      background-color: #EBF0ED;
      border-radius: 20px 20px 0 0; }
      .ourservices-membership__container .box-content__card .header .card-view {
        position: absolute;
        top: -20%;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        border-radius: 7px;
        overflow: hidden;
        height: 105%; }
        .ourservices-membership__container .box-content__card .header .card-view img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .ourservices-membership__container .box-content__card .footer {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      height: 100%; }
      .ourservices-membership__container .box-content__card .footer .title {
        margin: 20px 0;
        color: #757575;
        font-family: ROBOTO-MEDIUM;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden; }
        @media (max-width: 540px) {
          .ourservices-membership__container .box-content__card .footer .title {
            margin: 3vw 0; } }
      .ourservices-membership__container .box-content__card .footer .text {
        color: #9E9E9E;
        margin-bottom: 20px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        display: -webkit-box;
        overflow: hidden; }
      .ourservices-membership__container .box-content__card .footer .btn {
        border: none;
        padding: 0;
        color: #FF8300;
        background: none;
        margin-top: auto;
        display: inline-block; }
        .ourservices-membership__container .box-content__card .footer .btn span {
          display: none; }

#overlay-card.active {
  opacity: 1;
  visibility: visible; }

#overlay-card {
  transition: 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  background: #811315;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden; }
  #overlay-card .btn-close {
    z-index: 1;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0) translateY(-50%);
    transform: translateZ(0) translateY(-50%);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background: #757575;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    width: 40px;
    height: 40px;
    top: 50%;
    right: -70px;
    border-radius: 5px;
    cursor: pointer; }
    @media (max-width: 1140px) {
      #overlay-card .btn-close {
        top: 20px;
        right: 40px;
        width: 40px;
        height: 40px;
        -webkit-transform: translateZ(0);
        transform: translateZ(0); } }
    @media (max-width: 540px) {
      #overlay-card .btn-close {
        top: 10px;
        right: 30px;
        width: 30px;
        height: 30px; } }
    #overlay-card .btn-close::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      content: "\2716"; }
    #overlay-card .btn-close:hover::after {
      color: black; }
    #overlay-card .btn-close::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #FF8300;
      border-radius: 100%;
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.6s;
      transition-duration: 0.6s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out; }
    #overlay-card .btn-close:hover:before, #overlay-card .btn-close:focus:before, #overlay-card .btn-close:active:before {
      -webkit-transform: scale(2);
      transform: scale(2); }
  #overlay-card .card-section {
    width: 100%;
    overflow: hidden;
    height: 80%;
    background: #EBF0ED; }
    #overlay-card .card-section .container {
      position: relative;
      background: #ffffff;
      background-image: url("../assets/images/our-services/bg-overlay-card.png");
      background-repeat: no-repeat;
      background-position-y: 100%;
      height: 100%;
      background-size: 100%; }
      #overlay-card .card-section .container .nav-custom .wrapper.active {
        display: block; }
      #overlay-card .card-section .container .nav-custom .wrapper {
        width: 100%;
        max-height: 764px;
        position: unset;
        overflow-y: scroll;
        display: none;
        height: 80%;
        padding: 0 100px; }
        #overlay-card .card-section .container .nav-custom .wrapper > * {
          color: #9e9e9e; }
        @media (max-width: 1919px) {
          #overlay-card .card-section .container .nav-custom .wrapper {
            height: 85vh; } }
        @media (max-width: 768px) {
          #overlay-card .card-section .container .nav-custom .wrapper {
            padding: 0 80px; } }
        @media (max-width: 430px) {
          #overlay-card .card-section .container .nav-custom .wrapper {
            padding: 0 20px; } }
        #overlay-card .card-section .container .nav-custom .wrapper .title {
          text-align: center;
          font-family: ROBOTO-MEDIUM;
          margin: 50px 0;
          text-transform: uppercase;
          font-size: 32px; }
          @media (max-width: 768px) {
            #overlay-card .card-section .container .nav-custom .wrapper .title {
              margin: 40px 0; } }
          @media (max-width: 430px) {
            #overlay-card .card-section .container .nav-custom .wrapper .title {
              margin: 25px 0; } }
          @media (max-width: 768px) {
            #overlay-card .card-section .container .nav-custom .wrapper .title {
              font-size: 26px; } }
          @media (max-width: 430px) {
            #overlay-card .card-section .container .nav-custom .wrapper .title {
              font-size: 20px; } }
        #overlay-card .card-section .container .nav-custom .wrapper .text-section {
          display: flex;
          justify-content: space-between; }
          @media (max-width: 430px) {
            #overlay-card .card-section .container .nav-custom .wrapper .text-section {
              flex-direction: column; } }
          #overlay-card .card-section .container .nav-custom .wrapper .text-section .decs {
            width: 100%; }
            @media (max-width: 430px) {
              #overlay-card .card-section .container .nav-custom .wrapper .text-section .decs {
                width: 100%; } }
            #overlay-card .card-section .container .nav-custom .wrapper .text-section .decs p {
              margin-bottom: 35px;
              line-height: 1.4; }

.ourservice-business .title {
  text-align: center; }
  .ourservice-business .title > * {
    text-align: center; }

.ourservice-business .content {
  display: flex;
  flex-direction: row;
  max-width: 1920px;
  margin: auto;
  flex-wrap: wrap;
  height: 100%; }
  .ourservice-business .content-left {
    width: 50%;
    padding: 0;
    display: flex;
    flex-direction: column; }
    .ourservice-business .content-left .top {
      height: 50%;
      display: flex;
      flex-direction: row; }
      .ourservice-business .content-left .top-right {
        background-color: #D8E2DC;
        width: 50%;
        position: relative; }
        .ourservice-business .content-left .top-right .text {
          position: absolute;
          left: 8%;
          bottom: 8%;
          padding-right: 50px; }
          @media (max-width: 768px) {
            .ourservice-business .content-left .top-right .text {
              padding-right: 8%; } }
          .ourservice-business .content-left .top-right .text p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            overflow: hidden;
            line-height: 1.6;
            font-weight: normal;
            color: #9E9E9E;
            font-weight: normal;
            margin-top: 15px; }
            @media (max-width: 1024px) {
              .ourservice-business .content-left .top-right .text p {
                -webkit-line-clamp: 4; } }
            @media (max-width: 768px) {
              .ourservice-business .content-left .top-right .text p {
                margin-top: 10px; } }
          .ourservice-business .content-left .top-right .text .text-24 {
            color: #757575;
            font-family: ROBOTO-MEDIUM; }
          .ourservice-business .content-left .top-right .text .btn {
            border: none;
            padding: 0;
            color: #FF8300;
            background: none;
            margin-top: 20px; }
            @media (max-width: 768px) {
              .ourservice-business .content-left .top-right .text .btn {
                margin-top: 10px; } }
      .ourservice-business .content-left .top-left {
        width: 50%; }
        .ourservice-business .content-left .top-left img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .ourservice-business .content-left .bot {
      height: 50%;
      display: flex;
      flex-direction: row; }
      .ourservice-business .content-left .bot-left {
        position: relative;
        background-color: #1D5632;
        width: 50%;
        color: white; }
        .ourservice-business .content-left .bot-left .text {
          position: absolute;
          left: 8%;
          bottom: 8%;
          padding-right: 50px; }
          @media (max-width: 768px) {
            .ourservice-business .content-left .bot-left .text {
              padding-right: 8%; } }
          .ourservice-business .content-left .bot-left .text p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            line-height: 1.6;
            overflow: hidden;
            font-weight: normal;
            color: #9E9E9E;
            font-weight: normal;
            margin-top: 15px; }
            @media (max-width: 1024px) {
              .ourservice-business .content-left .bot-left .text p {
                -webkit-line-clamp: 4; } }
            @media (max-width: 768px) {
              .ourservice-business .content-left .bot-left .text p {
                margin-top: 10px; } }
          .ourservice-business .content-left .bot-left .text .text-24 {
            color: #E0E0E0;
            font-family: ROBOTO-MEDIUM; }
          .ourservice-business .content-left .bot-left .text .btn {
            border: none;
            padding: 0;
            color: #FF8300;
            background: none;
            margin-top: 20px; }
            @media (max-width: 768px) {
              .ourservice-business .content-left .bot-left .text .btn {
                margin-top: 10px; } }
      .ourservice-business .content-left .bot-right {
        width: 50%; }
        .ourservice-business .content-left .bot-right img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .ourservice-business .content-right {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    align-items: center;
    width: 50%;
    background-color: #D8E2DC;
    padding: 0; }
    @media (max-width: 576px) {
      .ourservice-business .content-right {
        padding: 20px 0; } }
    .ourservice-business .content-right::after {
      position: absolute;
      top: 0;
      right: 20%;
      width: 65%;
      height: 100%;
      transform: skew(-30deg);
      background: #ffffff;
      content: "";
      box-shadow: 2px 2px 7px 0 #757575; }
    .ourservice-business .content-right .image-contain {
      margin-top: auto;
      margin-bottom: auto;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 1.4vw;
      justify-content: center; }
      @media (max-width: 576px) {
        .ourservice-business .content-right .image-contain {
          display: none; } }
      .ourservice-business .content-right .image-contain .bg-overlay {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        z-index: 1; }
      .ourservice-business .content-right .image-contain .inner-image {
        position: relative;
        z-index: 2; }
        .ourservice-business .content-right .image-contain .inner-image .title-header {
          padding-left: 35px;
          margin-bottom: 10px;
          color: white; }
        .ourservice-business .content-right .image-contain .inner-image .name-tag {
          padding: 0 1.4vw;
          margin-bottom: 2.8vw; }
          .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper {
            padding-bottom: 140%;
            background-color: white;
            position: relative;
            border-radius: 1vw;
            overflow: hidden; }
            .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              overflow: hidden;
              width: 100%; }
              .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-overlay {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                content: "";
                background-repeat: no-repeat;
                background-size: contain; }
                @media (max-width: 1440px) {
                  .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-overlay {
                    top: -10px; } }
                .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-overlay img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain; }
              .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 50%;
                padding-bottom: 48%;
                border-radius: 50%;
                margin-bottom: 30px;
                overflow: hidden;
                border: solid 3px #1D5632;
                z-index: 1; }
                @media (max-width: 900px) {
                  .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-wrapper {
                    margin-bottom: 15px; } }
                .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-wrapper .image {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); }
                  .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .img-wrapper .image img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; }
              .ourservice-business .content-right .image-contain .inner-image .name-tag .partner-wrapper__items .name-wrapper {
                position: relative;
                z-index: 1; }
    .ourservice-business .content-right .mobile {
      display: none; }
      @media (max-width: 576px) {
        .ourservice-business .content-right .mobile {
          display: block; } }
      .ourservice-business .content-right .mobile.swiper-container {
        background-image: url("../assets/images/our-services/business-img3.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%; }
      .ourservice-business .content-right .mobile .swiper-wrapper {
        padding: 10px 0;
        align-items: center; }
        .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide {
          background: #ffffff;
          height: 100%;
          box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.08);
          position: relative;
          border-radius: 8px;
          padding-bottom: 33%; }
          @media (max-width: 380px) {
            .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide {
              padding-bottom: 40%; } }
          .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide::after {
            width: 100%;
            height: 100%;
            top: -10px;
            left: 0;
            position: absolute;
            content: "";
            background: url("../assets/images/home-page/overlay-partner.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto; }
          .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide .slide {
            position: absolute;
            z-index: 3;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; }
            .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide .slide .img-wrapper {
              width: 46px;
              height: 46px;
              border-radius: 50%;
              border: solid 2px #1D5632;
              display: flex;
              align-items: center;
              justify-content: center; }
              @media (max-width: 380px) {
                .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide .slide .img-wrapper {
                  border: solid 1px #1D5632; } }
              .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide .slide .img-wrapper .image {
                width: 50%;
                height: 50%; }
                .ourservice-business .content-right .mobile .swiper-wrapper .swiper-slide .slide .img-wrapper .image img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain; }

.ourservices-leasing {
  display: flex;
  flex-direction: column;
  text-align: center; }
  .ourservices-leasing .title {
    margin-top: 0; }
    .ourservices-leasing .title > * {
      text-align: center; }
  .ourservices-leasing .row {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 576px) {
      .ourservices-leasing .row .box {
        margin-bottom: 5vw; } }
    .ourservices-leasing .row .box-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #EBF0ED;
      cursor: pointer; }
      .ourservices-leasing .row .box-content:hover {
        background-color: #ffffff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .ourservices-leasing .row .box-img {
      padding-bottom: 66%;
      position: relative;
      overflow: hidden; }
      .ourservices-leasing .row .box-img img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .ourservices-leasing .row .box .text {
      height: 100%;
      text-align: start;
      padding: 50px 35px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; }
      @media (max-width: 1024px) {
        .ourservices-leasing .row .box .text {
          padding: 40px 25px; } }
      @media (max-width: 800px) {
        .ourservices-leasing .row .box .text {
          padding: 30px 25px; } }
      @media (max-width: 540px) {
        .ourservices-leasing .row .box .text {
          padding: 20px 15px; } }
      .ourservices-leasing .row .box .text .title {
        color: #757575;
        background-color: none;
        font-family: ROBOTO-MEDIUM; }
      .ourservices-leasing .row .box .text p {
        margin-top: 20px;
        color: #9E9E9E;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 20px; }
        @media (max-width: 800px) {
          .ourservices-leasing .row .box .text p {
            -webkit-line-clamp: 4;
            margin: 2.1vw 0; } }
      .ourservices-leasing .row .box .text .btn {
        border: none;
        padding: 0;
        color: #FF8300;
        background: none;
        margin-top: auto; }

.ourservices-class .title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 100; }
  .ourservices-class .title > * {
    text-align: center; }

.ourservices-class__content {
  position: relative; }
  .ourservices-class__content .background {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 80%;
    z-index: -1; }
    .ourservices-class__content .background img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.ourservices-class .container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: space-between; }
  @media (max-width: 640px) {
    .ourservices-class .container {
      flex-direction: column;
      align-items: center; } }
  .ourservices-class .container .image-contain {
    position: relative;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    width: 49%;
    padding: 20px;
    background: #ffffff;
    justify-content: center;
    z-index: -2;
    align-items: center; }
    @media (max-width: 1024px) {
      .ourservices-class .container .image-contain {
        padding: 15px; } }
    @media (max-width: 640px) {
      .ourservices-class .container .image-contain {
        margin-top: 20px;
        width: 90%; } }
    .ourservices-class .container .image-contain:not(:first-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      border-right: 155px solid #1D5632;
      border-top: 155px solid transparent;
      z-index: -1; }
      @media (max-width: 640px) {
        .ourservices-class .container .image-contain:not(:first-child):after {
          border-right: 100px solid #1D5632;
          border-top: 100px solid transparent; } }
    .ourservices-class .container .image-contain:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-left: 155px solid #1D5632;
      border-bottom: 155px solid transparent;
      z-index: -1; }
      @media (max-width: 640px) {
        .ourservices-class .container .image-contain:not(:last-child)::after {
          border-left: 100px solid #1D5632;
          border-bottom: 100px solid transparent; } }
    .ourservices-class .container .image-contain .image {
      position: relative;
      width: 100%;
      padding-top: 100%; }
      .ourservices-class .container .image-contain .image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .ourservices-class .container .image-contain .btn {
      text-align: center;
      position: absolute;
      text-transform: uppercase;
      z-index: 3;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      padding: 24px 40px;
      border-radius: 50px 0;
      border: 10px solid #E0E0E0;
      background: #1D5632;
      font-family: ROBOTO-MEDIUM;
      width: 70%; }
      @media (max-width: 640px) {
        .ourservices-class .container .image-contain .btn {
          padding: 15px;
          border-radius: 30px 0; } }

.ourservices-otherclass {
  margin-top: 140px;
  text-align: center; }
  @media (max-width: 1366px) {
    .ourservices-otherclass {
      margin-top: 130px; } }
  @media (max-width: 1024px) {
    .ourservices-otherclass {
      margin-top: 100px; } }
  .ourservices-otherclass .title > * {
    text-align: center; }
  .ourservices-otherclass .myServiceSwipe .swiper-slide {
    border-radius: 25px; }
    .ourservices-otherclass .myServiceSwipe .swiper-slide .box {
      cursor: pointer;
      width: 100%;
      height: 423px;
      border-radius: 25px;
      position: relative;
      border: 1px solid #EBF0ED;
      padding: 0;
      overflow: hidden; }
      .ourservices-otherclass .myServiceSwipe .swiper-slide .box .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover {
        text-align: start;
        display: flex;
        justify-content: center; }
        .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .image {
          display: none; }
        .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip {
          position: absolute;
          width: 304px;
          height: 220px;
          top: 40px;
          color: #9E9E9E;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden; }
          .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip .flip-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            overflow: hidden; }
          .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip .btn {
            border: none;
            padding: 0;
            color: #FF8300;
            background: none;
            margin-top: 20px; }
        .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .text {
          background-color: #1D5632; }
          .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .text p {
            color: white;
            font-family: ROBOTO-MEDIUM; }
      .ourservices-otherclass .myServiceSwipe .swiper-slide .box .flip {
        display: none; }
      .ourservices-otherclass .myServiceSwipe .swiper-slide .box .text {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 110px;
        background-color: #EBF0ED; }
        .ourservices-otherclass .myServiceSwipe .swiper-slide .box .text p {
          color: #1D5632;
          width: 80%;
          font-family: ROBOTO-MEDIUM; }
  .ourservices-otherclass .row {
    justify-content: center; }

@media (max-width: 1024px) {
  .ourservices-otherclass .swiper-button-next
, .ourservices-otherclass .swiper-button-prev {
    display: none; }
  .ourservices-otherclass .myServiceSwipe .swiper-slide .box {
    height: 385px;
    padding: 0; }
    .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip {
      width: 270px; } }

@media (max-width: 768px) {
  .ourservices-otherclass .myServiceSwipe .swiper-slide .box {
    height: 252px;
    padding: 0; }
    .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip {
      width: 190px;
      top: 20px; }
    .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .btn {
      margin-top: 10px; }
    .ourservices-otherclass .myServiceSwipe .swiper-slide .box .text {
      height: 25%; } }

@media (max-width: 430px) {
  .ourservices-otherclass {
    margin-top: 55px; }
    .ourservices-otherclass .myServiceSwipe .swiper-slide .box {
      border-radius: 10px;
      height: 208px;
      padding: 0; }
      .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip {
        width: 100%;
        padding: 0 20px;
        top: 15px;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-self: center; }
        .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip .flip-text {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          font-size: 13px; }
        .ourservices-otherclass .myServiceSwipe .swiper-slide .box:hover .flip .btn {
          margin-top: 5px; }
      .ourservices-otherclass .myServiceSwipe .swiper-slide .box .text .text-24 {
        font-size: 12px; } }

@media (max-width: 380px) {
  .ourservices-otherclass .myServiceSwipe .swiper-slide .box {
    height: 168px; } }

.btn {
  text-transform: capitalize; }

.otherservices-clinical .head-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #9E9E9E;
  text-transform: uppercase; }

.otherservices-clinical .content {
  color: #9E9E9E;
  align-items: flex-start;
  padding: 0; }
  .otherservices-clinical .content p {
    color: #9E9E9E;
    list-style-type: none;
    margin: 20px 0;
    line-height: 1.6; }
  .otherservices-clinical .content .image {
    max-width: 750px;
    margin: auto; }
    .otherservices-clinical .content .image p {
      margin-top: 30px; }

.other-services__wrapper .other-services {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1366px) {
    .other-services__wrapper .other-services {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .other-services__wrapper .other-services {
      height: 100%;
      min-height: 350px; } }
  @media (max-width: 430px) {
    .other-services__wrapper .other-services {
      margin-top: 70px; } }
  @media (max-width: 380px) {
    .other-services__wrapper .other-services {
      margin-top: 55px;
      min-height: 250px; } }
  .other-services__wrapper .other-services .banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .other-services__wrapper .other-services .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.otherservices-clinical .head-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #9E9E9E;
  text-transform: uppercase; }

.otherservices-clinical .content {
  color: #9E9E9E; }
  .otherservices-clinical .content p {
    list-style-type: none;
    margin: 20px 0;
    line-height: 1.6; }
  .otherservices-clinical .content .image {
    text-align: center; }
    .otherservices-clinical .content .image p {
      margin-top: 30px; }

.leasing-officeforlease .office {
  margin-top: 52px; }
  .leasing-officeforlease .office .mySwiperLeasing {
    position: unset; }
  .leasing-officeforlease .office .swiper-wrapper {
    height: 1241px; }
  .leasing-officeforlease .office .swiper-slide {
    display: flex;
    justify-content: space-between; }
    .leasing-officeforlease .office .swiper-slide .box {
      position: relative;
      width: 49%;
      height: 568px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      cursor: pointer;
      overflow: hidden; }
      .leasing-officeforlease .office .swiper-slide .box:hover::after {
        bottom: -1px; }
      .leasing-officeforlease .office .swiper-slide .box:hover .text {
        bottom: 52px; }
      .leasing-officeforlease .office .swiper-slide .box img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .leasing-officeforlease .office .swiper-slide .box::after {
        transition: 0.3s ease-in-out;
        position: absolute;
        background-image: url("../assets/images/leasing/overlay-img.png");
        content: "";
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position-y: 100%;
        background-size: 100%; }
      .leasing-officeforlease .office .swiper-slide .box .text {
        transition: 0.3s ease-in-out;
        width: 100%;
        position: absolute;
        bottom: -100%;
        left: 0;
        display: flex;
        justify-content: space-evenly;
        z-index: 3;
        align-items: center; }
        .leasing-officeforlease .office .swiper-slide .box .text::after {
          position: absolute;
          background-image: url("../assets/images/leasing/overlay-img.png");
          content: "";
          bottom: 0;
          left: 0;
          width: 100%; }
        .leasing-officeforlease .office .swiper-slide .box .text .text-48 {
          position: relative; }
          .leasing-officeforlease .office .swiper-slide .box .text .text-48::after {
            position: absolute;
            top: 50%;
            right: -10%;
            transform: translateY(-50%);
            content: "";
            width: 1px;
            height: 100%;
            background: #9E9E9E; }

@media (max-width: 1366px) {
  .leasing-officeforlease .office {
    margin-top: 52px; }
    .leasing-officeforlease .office .swiper-wrapper {
      height: 881px; }
    .leasing-officeforlease .office .swiper-slide .box {
      height: 368px; } }

@media (max-width: 1024px) {
  .leasing-officeforlease .swiper-scrollbar {
    right: 0; }
  .leasing-officeforlease .office .nav-custom {
    padding: 0 40px; }
  .leasing-officeforlease .office .swiper-wrapper {
    height: 841px; }
  .leasing-officeforlease .office .swiper-slide .box {
    height: 368px; }
    .leasing-officeforlease .office .swiper-slide .box:hover .text {
      bottom: 30px; } }

@media (max-width: 768px) {
  .leasing-officeforlease .office .nav-custom {
    padding: 0 40px; }
  .leasing-officeforlease .office .swiper-wrapper {
    height: 641px; }
  .leasing-officeforlease .office .swiper-slide .box {
    height: 268px; }
    .leasing-officeforlease .office .swiper-slide .box .text .text-48 {
      font-size: 20px; }
    .leasing-officeforlease .office .swiper-slide .box .text .subtext .text-24 {
      font-size: 16px; } }

@media (max-width: 430px) {
  .leasing-officeforlease .swiper-scrollbar {
    display: none; }
  .leasing-officeforlease .office {
    margin-top: 22px; }
    .leasing-officeforlease .office .nav-custom {
      padding: 0 20px; }
    .leasing-officeforlease .office .swiper-wrapper {
      height: 401px; }
    .leasing-officeforlease .office .swiper-slide .box {
      height: 168px; }
      .leasing-officeforlease .office .swiper-slide .box:hover .text {
        bottom: 10px; }
      .leasing-officeforlease .office .swiper-slide .box .text .text-48 {
        font-size: 14px; }
      .leasing-officeforlease .office .swiper-slide .box .text .subtext .text-24 {
        font-size: 8px; } }

@media (max-width: 380px) {
  .leasing-officeforlease .swiper-scrollbar {
    display: none; }
  .leasing-officeforlease .office {
    margin-top: 7px; }
    .leasing-officeforlease .office .nav-custom {
      padding: 0 20px; }
    .leasing-officeforlease .office .swiper-wrapper {
      height: 301px; }
    .leasing-officeforlease .office .swiper-slide .box {
      height: 128px; }
      .leasing-officeforlease .office .swiper-slide .box:hover .text {
        bottom: 10px; }
      .leasing-officeforlease .office .swiper-slide .box .text .text-48 {
        font-size: 10px; }
      .leasing-officeforlease .office .swiper-slide .box .text .subtext .text-24 {
        font-size: 6px; } }

.leasing-banner {
  margin-bottom: 104px; }
  @media (max-width: 1024px) {
    .leasing-banner {
      margin-bottom: 64px; } }
  @media (max-width: 430px) {
    .leasing-banner {
      margin-bottom: 35px; } }
  .leasing-banner .banner {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative; }
    .leasing-banner .banner .text {
      width: 100%;
      position: absolute;
      text-transform: uppercase;
      color: white;
      text-align: center; }
      .leasing-banner .banner .text .btn {
        border-radius: 20px 0 20px 0;
        padding: 15px;
        text-transform: none;
        background: #FF8300;
        margin-top: 20px; }

@media (max-width: 430px) {
  .leasing-banner .banner .text .btn {
    margin-top: 10px;
    padding: 10px; } }

@media (max-width: 320px) {
  .leasing-banner .banner .text .btn {
    margin-top: 5px;
    padding: 5px; } }

.services-class__wrapper {
  overflow: hidden; }
  .services-class__wrapper .services-class {
    width: 100%;
    height: calc(100vh - 125px);
    position: relative;
    margin-top: 125px;
    max-width: 1920px;
    max-height: 1080px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 1366px) {
      .services-class__wrapper .services-class {
        height: calc(100vh - 80px);
        margin-top: 80px; } }
    @media (max-width: 1024px) {
      .services-class__wrapper .services-class {
        height: 100%;
        min-height: 350px; } }
    @media (max-width: 430px) {
      .services-class__wrapper .services-class {
        margin-top: 70px; } }
    @media (max-width: 380px) {
      .services-class__wrapper .services-class {
        margin-top: 55px;
        min-height: 250px; } }
    .services-class__wrapper .services-class .banner {
      overflow: hidden;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
      .services-class__wrapper .services-class .banner img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .services-class__wrapper .services-class .banner .overlay-text-banner {
        position: absolute;
        top: 60%;
        left: 100px;
        transform: translateY(-50%);
        z-index: 2; }
        @media (max-width: 1024px) {
          .services-class__wrapper .services-class .banner .overlay-text-banner {
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 80%; } }
        .services-class__wrapper .services-class .banner .overlay-text-banner .text-section {
          width: 400px; }
          @media (max-width: 1024px) {
            .services-class__wrapper .services-class .banner .overlay-text-banner .text-section {
              width: 100%; } }
          .services-class__wrapper .services-class .banner .overlay-text-banner .text-section .title {
            text-transform: uppercase; }
          .services-class__wrapper .services-class .banner .overlay-text-banner .text-section * {
            margin-bottom: 30px; }
    .services-class__wrapper .services-class .overlay-pattern {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      visibility: visible; }
      @media (max-width: 1024px) {
        .services-class__wrapper .services-class .overlay-pattern {
          width: 160%; } }
      .services-class__wrapper .services-class .overlay-pattern img {
        min-width: 786px;
        object-fit: cover;
        height: 100%; }
        @media (max-width: 1024px) {
          .services-class__wrapper .services-class .overlay-pattern img {
            width: 100%; } }

.services-class__complementary {
  text-align: center; }
  @media (max-width: 540px) {
    .services-class__complementary .content .nav-custom {
      height: 400px; } }
  @media (max-width: 430px) {
    .services-class__complementary .content .nav-custom {
      height: 350px; } }
  @media (max-width: 380px) {
    .services-class__complementary .content .nav-custom {
      height: 300px; } }
  .services-class__complementary .content .nav-custom .mySwiperService-Class {
    height: 100%; }
  @media (max-width: 1367px) {
    .services-class__complementary .content .nav-custom .swiper-button-next,
    .services-class__complementary .content .nav-custom .swiper-button-prev {
      width: 40px;
      height: 40px; } }
  @media (max-width: 630px) {
    .services-class__complementary .content .nav-custom .swiper-button-next,
    .services-class__complementary .content .nav-custom .swiper-button-prev {
      display: inline-flex; } }
  @media (max-width: 540px) {
    .services-class__complementary .content .nav-custom .swiper-button-next,
    .services-class__complementary .content .nav-custom .swiper-button-prev {
      display: none; } }
  @media (max-width: 1120px) {
    .services-class__complementary .content .nav-custom .swiper-button-prev {
      left: 10px; } }
  @media (max-width: 1120px) {
    .services-class__complementary .content .nav-custom .swiper-button-next {
      right: 10px; } }
  .services-class__complementary .content .swiper-wrapper {
    padding-bottom: 26px; }
  .services-class__complementary .content .swiper-slide {
    height: unset; }
    @media (max-width: 540px) {
      .services-class__complementary .content .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: space-between; } }
    .services-class__complementary .content .swiper-slide .box {
      display: flex;
      flex-direction: column;
      height: 50%;
      width: 100%;
      background-color: #EBF0ED;
      cursor: pointer;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
      .services-class__complementary .content .swiper-slide .box:not(:last-child) {
        margin-bottom: 20px; }
        @media (max-width: 540px) {
          .services-class__complementary .content .swiper-slide .box:not(:last-child) {
            margin-bottom: unset; } }
      @media (max-width: 540px) {
        .services-class__complementary .content .swiper-slide .box {
          height: 100%;
          width: 49%; } }
      .services-class__complementary .content .swiper-slide .box .images {
        width: 100%;
        padding-bottom: 60%;
        position: relative;
        overflow: hidden; }
        .services-class__complementary .content .swiper-slide .box .images img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .services-class__complementary .content .swiper-slide .box:hover {
        background-color: #ffffff; }
      .services-class__complementary .content .swiper-slide .box .text {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: start;
        padding: 40px 90px 20px 30px; }
        @media (max-width: 768px) {
          .services-class__complementary .content .swiper-slide .box .text {
            padding: 20px; } }
        @media (max-width: 450px) {
          .services-class__complementary .content .swiper-slide .box .text {
            padding: 15px; } }
        .services-class__complementary .content .swiper-slide .box .text .title {
          color: #757575;
          background-color: none;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          font-family: ROBOTO-MEDIUM; }
        .services-class__complementary .content .swiper-slide .box .text .subtext {
          margin: 20px 0;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          color: #9E9E9E; }
          @media (max-width: 768px) {
            .services-class__complementary .content .swiper-slide .box .text .subtext {
              margin: 10px 0; } }
        .services-class__complementary .content .swiper-slide .box .text .btn {
          border: none;
          padding: 0;
          color: #FF8300;
          background: none; }

.contact-us__wrapper {
  margin-top: 120px; }
  @media (max-width: 1366px) {
    .contact-us__wrapper {
      margin-top: 80px; } }
  @media (max-width: 540px) {
    .contact-us__wrapper {
      margin-top: 50px; } }
  .contact-us__wrapper .background {
    position: relative;
    width: 100%;
    height: 944px; }
    @media (max-width: 768px) {
      .contact-us__wrapper .background {
        height: 658px; } }
    .contact-us__wrapper .background img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .contact-us__wrapper .background .overlay {
      background: rgba(1, 42, 16, 0.52);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%; }
      .contact-us__wrapper .background .overlay .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center; }
        .contact-us__wrapper .background .overlay .container .title {
          text-align: center;
          justify-self: unset;
          width: 60%;
          text-transform: uppercase;
          text-overflow: ellipsis;
          margin-bottom: 52px; }
          @media (max-width: 769px) {
            .contact-us__wrapper .background .overlay .container .title {
              width: 90%; } }
          .contact-us__wrapper .background .overlay .container .title > * {
            font-size: 48px;
            color: #ffffff; }
            @media (max-width: 1367px) {
              .contact-us__wrapper .background .overlay .container .title > * {
                font-size: 38px; } }
            @media (max-width: 1201px) {
              .contact-us__wrapper .background .overlay .container .title > * {
                font-size: 34px; } }
            @media (max-width: 1024px) {
              .contact-us__wrapper .background .overlay .container .title > * {
                font-size: 30px; } }
            @media (max-width: 769px) {
              .contact-us__wrapper .background .overlay .container .title > * {
                font-size: 26px; } }
        .contact-us__wrapper .background .overlay .container .form {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70%; }
          @media (max-width: 769px) {
            .contact-us__wrapper .background .overlay .container .form {
              width: 90%; } }
          .contact-us__wrapper .background .overlay .container .form .form-control {
            margin-bottom: 12px;
            width: 100%;
            height: 52px;
            background: #ffffff; }
            @media (max-width: 769px) {
              .contact-us__wrapper .background .overlay .container .form .form-control {
                height: 40px; } }
            .contact-us__wrapper .background .overlay .container .form .form-control:first-child {
              position: relative; }
              .contact-us__wrapper .background .overlay .container .form .form-control:first-child::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 14px;
                width: 0px;
                height: 0px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 12px solid #555;
                border-top-color: #FF8300;
                content: ""; }
            .contact-us__wrapper .background .overlay .container .form .form-control select, .contact-us__wrapper .background .overlay .container .form .form-control input {
              cursor: pointer;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              height: 100%;
              width: 100%;
              border: 0;
              background-color: transparent;
              padding-left: 14px;
              color: #212121;
              z-index: 1; }
              .contact-us__wrapper .background .overlay .container .form .form-control select::placeholder, .contact-us__wrapper .background .overlay .container .form .form-control input::placeholder {
                color: #212121; }
          .contact-us__wrapper .background .overlay .container .form .btn {
            text-align: center; }

.success__wrapper {
  margin-top: 120px; }
  @media (max-width: 1366px) {
    .success__wrapper {
      margin-top: 80px; } }
  @media (max-width: 540px) {
    .success__wrapper {
      margin-top: 50px; } }
  .success__wrapper .background {
    position: relative;
    width: 100%;
    height: 944px; }
    @media (max-width: 768px) {
      .success__wrapper .background {
        height: 658px; } }
    .success__wrapper .background img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .success__wrapper .background .overlay {
      background: rgba(1, 42, 16, 0.52);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      width: 50%;
      height: auto;
      max-width: 832px; }
      @media (max-width: 1024px) {
        .success__wrapper .background .overlay {
          width: 80%; } }
      .success__wrapper .background .overlay .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center; }
        .success__wrapper .background .overlay .container .content {
          text-align: center;
          justify-self: unset;
          text-overflow: ellipsis;
          padding: 80px 0; }
          @media (max-width: 769px) {
            .success__wrapper .background .overlay .container .content {
              padding: 60px 0; } }
          .success__wrapper .background .overlay .container .content > *:not(:last-child) {
            margin-bottom: 30px; }
            @media (max-width: 540px) {
              .success__wrapper .background .overlay .container .content > *:not(:last-child) {
                margin-bottom: 20px; } }
          .success__wrapper .background .overlay .container .content h3 {
            text-transform: uppercase;
            font-size: 48px;
            color: #ffffff; }
            @media (max-width: 1367px) {
              .success__wrapper .background .overlay .container .content h3 {
                font-size: 38px; } }
            @media (max-width: 1201px) {
              .success__wrapper .background .overlay .container .content h3 {
                font-size: 34px; } }
            @media (max-width: 1024px) {
              .success__wrapper .background .overlay .container .content h3 {
                font-size: 30px; } }
            @media (max-width: 769px) {
              .success__wrapper .background .overlay .container .content h3 {
                font-size: 26px; } }
          .success__wrapper .background .overlay .container .content p {
            margin-left: auto;
            margin-right: auto;
            max-width: 70%;
            font-size: 24px;
            color: #ffffff; }
            @media (max-width: 1367px) {
              .success__wrapper .background .overlay .container .content p {
                font-size: 24px; } }
            @media (max-width: 1201px) {
              .success__wrapper .background .overlay .container .content p {
                font-size: 22px; } }
            @media (max-width: 1025px) {
              .success__wrapper .background .overlay .container .content p {
                font-size: 20px; } }
            @media (max-width: 769px) {
              .success__wrapper .background .overlay .container .content p {
                font-size: 18px; } }
            @media (max-width: 430px) {
              .success__wrapper .background .overlay .container .content p {
                font-size: 14px; } }
            @media (max-width: 320px) {
              .success__wrapper .background .overlay .container .content p {
                font-size: 13px; } }

.timetable-banner__wrapper .timetable-banner {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1366px) {
    .timetable-banner__wrapper .timetable-banner {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .timetable-banner__wrapper .timetable-banner {
      height: 100%;
      min-height: 350px; } }
  @media (max-width: 430px) {
    .timetable-banner__wrapper .timetable-banner {
      margin-top: 70px; } }
  @media (max-width: 380px) {
    .timetable-banner__wrapper .timetable-banner {
      margin-top: 55px;
      min-height: 250px; } }
  .timetable-banner__wrapper .timetable-banner .banner {
    position: relative;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .timetable-banner__wrapper .timetable-banner .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner {
      width: 100%;
      position: absolute;
      top: 60%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2; }
      @media (max-width: 1024px) {
        .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner {
          top: 50%; } }
      .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section {
        padding: 105px 80px 70px 80px;
        background: rgba(1, 42, 16, 0.65);
        width: 55%; }
        @media (max-width: 1024px) {
          .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section {
            padding: 20px;
            margin: auto;
            min-width: 450px; } }
        @media (max-width: 450px) {
          .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section {
            padding: 20px;
            margin: auto;
            min-width: 300px; } }
        .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section .text-24 {
          font-family: ROBOTO-MEDIUM; }
        .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section .title {
          text-transform: uppercase;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section .decs {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section *:not(:last-child) {
          margin-bottom: 30px; }
          @media (max-width: 450px) {
            .timetable-banner__wrapper .timetable-banner .banner .overlay-text-banner .text-section *:not(:last-child) {
              margin-bottom: 15px; } }

.timetable-2__wrapper {
  background: #EBF0ED; }
  .timetable-2__wrapper ::-webkit-scrollbar {
    display: none; }
  .timetable-2__wrapper .timetable-2 {
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .timetable-2__wrapper .timetable-2__items {
      text-align: center;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px 0px 20px 0px;
      width: 240px;
      height: 136px;
      background: #ffffff;
      box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.08);
      text-transform: uppercase; }
      .timetable-2__wrapper .timetable-2__items a {
        color: #212121;
        font-family: ROBOTO-MEDIUM; }
    .timetable-2__wrapper .timetable-2__items:hover {
      background: #1D5632; }
      .timetable-2__wrapper .timetable-2__items:hover a {
        color: rgba(255, 255, 255, 0.7); }
    .timetable-2__wrapper .timetable-2 .active {
      background: #1D5632; }
      .timetable-2__wrapper .timetable-2 .active a {
        color: rgba(255, 255, 255, 0.7); }

@media (max-width: 1367px) {
  .timetable-2__wrapper .timetable-2 {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .timetable-2__wrapper .timetable-2__items {
      width: 190px;
      height: 100px; } }

@media (max-width: 1024px) {
  .timetable-2__wrapper .timetable-2 {
    justify-content: space-evenly; }
    .timetable-2__wrapper .timetable-2__items {
      width: 170px;
      height: 80px; } }

@media (max-width: 768px) {
  .timetable-2__wrapper .container {
    overflow: scroll; }
  .timetable-2__wrapper .timetable-2 {
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 20px 0; }
    .timetable-2__wrapper .timetable-2__items {
      font-size: 12px;
      width: 150px;
      height: 60px;
      margin-right: 10px; } }

@media (max-width: 430px) {
  .timetable-2__wrapper .container {
    overflow: scroll; }
  .timetable-2__wrapper .timetable-2 {
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 20px 0; }
    .timetable-2__wrapper .timetable-2__items {
      border-radius: 10px 0px 10px 0px;
      font-size: 12px;
      width: 100px;
      height: 50px;
      margin-right: 10px; } }

.timetable-3__wrapper .nav-custom {
  animation: fadeEffect 1s;
  overflow-x: scroll; }

@keyframes fadeEffect {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.timetable-3__wrapper .timetablle-item {
  min-width: 950px;
  height: 680px;
  overflow-y: scroll; }
  @media (max-width: 1366px) {
    .timetable-3__wrapper .timetablle-item {
      height: 550px; } }
  @media (max-width: 900px) {
    .timetable-3__wrapper .timetablle-item {
      height: 500px; } }
  @media (max-width: 600px) {
    .timetable-3__wrapper .timetablle-item {
      min-width: 850px; } }
  .timetable-3__wrapper .timetablle-item .timetable-3__slide--items:nth-child(odd) {
    background: #EBF0ED; }
  .timetable-3__wrapper .timetablle-item .timetable-3__slide--items:nth-child(even) {
    background: #ffffff; }
  .timetable-3__wrapper .timetablle-item .timetable-3__slide--items {
    height: 136px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1366px) {
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items {
        height: 110px; } }
    @media (max-width: 900px) {
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items {
        height: 100px; } }
    .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .items {
      display: flex;
      align-items: center;
      height: 100%;
      border-left: solid 1px #9E9E9E;
      color: #757575;
      justify-content: center;
      align-items: center;
      font-family: ROBOTO-MEDIUM; }
      @media (max-width: 1366px) {
        .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .items {
          font-size: 18px; } }
      @media (max-width: 1024px) {
        .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .items {
          font-size: 16px; } }
    .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__name {
      flex: 0 0 27%;
      max-width: 27%; }
    .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month, .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time {
      padding: 0 10px;
      position: relative; }
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month::after, .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        width: 0px;
        height: 0px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid #555;
        border-top-color: #FF8300;
        content: ""; }
        @media (max-width: 1024px) {
          .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month::after, .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time::after {
            right: 20px; } }
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month select, .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time select {
        color: #757575;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        width: 75%;
        border: none;
        z-index: 1;
        text-transform: uppercase; }
        .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month select option, .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time select option {
          text-transform: uppercase; }
    .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month {
      justify-content: space-between;
      padding: 0 40px;
      position: relative; }
      @media (max-width: 1200px) {
        .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month {
          padding: 0 20px; } }
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__month::before {
        content: "";
        background-image: url("../assets/images/time-table/calendar.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 20%;
        height: 32px;
        background-size: contain; }
    .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time {
      padding: 0 40px;
      position: relative;
      justify-content: space-between; }
      @media (max-width: 1200px) {
        .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time {
          padding: 0 20px; } }
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time select {
        text-transform: none; }
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__time::before {
        top: 50%;
        left: 20px;
        content: "";
        background-image: url("../assets/images/time-table/time.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 20%;
        height: 32px;
        background-size: contain; }
    .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__avatar {
      flex: 0 0 13%;
      max-width: 13%; }
      .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__avatar .avatar img {
        border-radius: 50%;
        width: 75px;
        height: 75px;
        object-fit: cover; }
        @media (max-width: 600px) {
          .timetable-3__wrapper .timetablle-item .timetable-3__slide--items .slide__avatar .avatar img {
            width: 55px;
            height: 55px; } }

.news-events-banner__wrapper .news-events-banner {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1366px) {
    .news-events-banner__wrapper .news-events-banner {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .news-events-banner__wrapper .news-events-banner {
      height: 100%;
      min-height: 350px; } }
  @media (max-width: 430px) {
    .news-events-banner__wrapper .news-events-banner {
      margin-top: 70px; } }
  @media (max-width: 380px) {
    .news-events-banner__wrapper .news-events-banner {
      margin-top: 55px;
      min-height: 250px; } }
  .news-events-banner__wrapper .news-events-banner .banner {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .news-events-banner__wrapper .news-events-banner .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.news-events-2__wrapper .container {
  display: flex; }
  @media (max-width: 900px) {
    .news-events-2__wrapper .container {
      flex-direction: column; } }

.news-events-2__wrapper .news-events-2__left {
  width: 30%; }
  @media (max-width: 900px) {
    .news-events-2__wrapper .news-events-2__left {
      width: 100%; } }
  .news-events-2__wrapper .news-events-2__left .list {
    transition: 0.3s ease-in-out;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
    width: 319px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    overflow: scroll; }
    @media (max-width: 900px) {
      .news-events-2__wrapper .news-events-2__left .list {
        flex-direction: row;
        width: 100%;
        border-right: unset; } }
    .news-events-2__wrapper .news-events-2__left .list .items {
      padding: 2px 0;
      margin-left: 20px;
      margin-bottom: 80px;
      position: relative; }
      @media (max-width: 900px) {
        .news-events-2__wrapper .news-events-2__left .list .items {
          white-space: nowrap;
          margin-bottom: unset;
          display: flex;
          align-items: center;
          padding: 5px 40px 5px 10px;
          margin-right: 10px; } }
  @media (max-width: 900px) and (max-width: 540px) {
    .news-events-2__wrapper .news-events-2__left .list .items {
      padding: 5px 20px 5px 10px; } }
      @media (max-width: 900px) {
          .news-events-2__wrapper .news-events-2__left .list .items::before {
            top: 50%;
            left: -15px;
            border-top: 7px solid transparent;
            border-left: 9px solid #555;
            border-bottom: 7px solid transparent;
            border-left-color: #FF8300; } }
      .news-events-2__wrapper .news-events-2__left .list .items:hover {
        background: #E7ECE9; }
      .news-events-2__wrapper .news-events-2__left .list .items::before {
        position: absolute;
        top: 7px;
        content: "";
        left: -20px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 10px solid #555;
        border-bottom: 8px solid transparent;
        border-left-color: #FF8300; }
    .news-events-2__wrapper .news-events-2__left .list .active {
      background: #E7ECE9; }
      .news-events-2__wrapper .news-events-2__left .list .active a {
        color: #212121; }
    .news-events-2__wrapper .news-events-2__left .list--items {
      font-family: ROBOTO-MEDIUM;
      color: #9E9E9E;
      margin-left: 20px;
      width: 100%;
      text-transform: uppercase;
      cursor: pointer; }
      @media (max-width: 900px) {
        .news-events-2__wrapper .news-events-2__left .list--items {
          margin-left: 10px; } }

.news-events-2__wrapper .news-events-2__right {
  width: 70%;
  padding-left: 104px; }
  @media (max-width: 900px) {
    .news-events-2__wrapper .news-events-2__right {
      width: 90%;
      margin-top: 40px;
      margin: auto; } }
  .news-events-2__wrapper .news-events-2__right--items {
    animation: fadeEffect 1s; }
    .news-events-2__wrapper .news-events-2__right--items .nav-custom {
      max-height: 1080px;
      overflow: scroll; }
      @media (max-width: 1024px) {
        .news-events-2__wrapper .news-events-2__right--items .nav-custom {
          max-height: 650px; } }
      @media (max-width: 540px) {
        .news-events-2__wrapper .news-events-2__right--items .nav-custom {
          max-height: 450px; } }

@keyframes fadeEffect {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  @media (max-width: 1024px) {
    .news-events-2__wrapper .news-events-2__right {
      padding-left: 84px; } }
  @media (max-width: 900px) {
    .news-events-2__wrapper .news-events-2__right {
      padding-left: 0; } }
  .news-events-2__wrapper .news-events-2__right .head-title {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12); }
    .news-events-2__wrapper .news-events-2__right .head-title * {
      margin-bottom: 22px; }
    .news-events-2__wrapper .news-events-2__right .head-title .title {
      text-transform: uppercase; }
      .news-events-2__wrapper .news-events-2__right .head-title .title:hover {
        color: #1D5632; }
    .news-events-2__wrapper .news-events-2__right .head-title .title-1 {
      font-family: ROBOTO-MEDIUM; }
  .news-events-2__wrapper .news-events-2__right .news-events .nav-custom {
    margin: 0 -8px; }
  .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide:not(:last-child) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
      margin-bottom: 52px;
      padding-bottom: 52px; }
      @media (max-width: 640px) {
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide:not(:last-child) {
          margin-bottom: 32px;
          padding-bottom: 32px; } }
      @media (max-width: 380px) {
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide:not(:last-child) {
          margin-bottom: 22px;
          padding-bottom: 22px; } }
    .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .decs {
      width: 50%;
      padding: 0 8px;
      max-width: 300px; }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .decs * {
        margin-bottom: 10px; }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .decs .title-1 {
        text-transform: uppercase; }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .decs .title {
        transition: 0.2s ease-in-out;
        font-family: ROBOTO-MEDIUM;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden; }
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .decs .title:hover {
          color: #1D5632; }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .decs-text {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden; }
    .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .image {
      padding: 0 8px;
      width: 50%;
      height: 100%;
      overflow: hidden;
      max-height: 280px;
      padding-bottom: 35%;
      position: relative; }
      @media (max-width: 900px) {
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .image {
          max-width: 350px;
          padding-bottom: 30%; } }
      @media (max-width: 540px) {
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .image {
          padding-bottom: 35%; } }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .image img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .decs {
        width: 308px; }
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .decs * {
          margin-bottom: 10px; }
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .decs .title-1 {
          text-transform: uppercase; }
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .decs .title {
          transition: 0.2s ease-in-out;
          font-family: ROBOTO-MEDIUM; }
          .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .decs .title:hover {
            color: #1D5632; }
      .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .image {
        display: flex;
        align-items: center;
        width: 408px;
        height: 100%; }
        .news-events-2__wrapper .news-events-2__right .news-events .swiper-slide .news-events__items .image img {
          width: 100%;
          height: auto; }

.infor-news-events-banner__wrapper .infor-news-events-banner {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1366px) {
    .infor-news-events-banner__wrapper .infor-news-events-banner {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .infor-news-events-banner__wrapper .infor-news-events-banner {
      height: 100%;
      min-height: 350px; } }
  @media (max-width: 430px) {
    .infor-news-events-banner__wrapper .infor-news-events-banner {
      margin-top: 70px; } }
  @media (max-width: 380px) {
    .infor-news-events-banner__wrapper .infor-news-events-banner {
      margin-top: 55px;
      min-height: 250px; } }
  .infor-news-events-banner__wrapper .infor-news-events-banner .banner {
    overflow: hidden;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .infor-news-events-banner__wrapper .infor-news-events-banner .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 34%;
    background: rgba(0, 0, 0, 0.12);
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%); }
    .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text .title {
      text-transform: uppercase;
      width: 654px;
      margin-bottom: 20px; }
    .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text .decs {
      width: 504px; }

@media (max-width: 1366px) {
  .infor-news-events-banner__wrapper .infor-news-events-banner .banner img {
    transform: unset;
    width: 100%;
    height: 100%; }
  .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text {
    height: 240px; }
    .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text .title {
      margin-bottom: 15px; } }

@media (max-width: 1024px) {
  .infor-news-events-banner__wrapper .infor-news-events-banner {
    height: 100%; }
    .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text {
      width: 100%;
      height: 34%;
      background: rgba(0, 0, 0, 0.12);
      position: absolute;
      bottom: 0;
      text-align: center;
      left: 50%;
      transform: translateX(-50%); }
      .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text .title {
        text-transform: uppercase;
        width: unset; }
      .infor-news-events-banner__wrapper .infor-news-events-banner .overlay-text .decs {
        display: none; } }

.infor-news-events-2__wrapper .infor-news-events-2 {
  display: flex; }
  .infor-news-events-2__wrapper .infor-news-events-2__left {
    padding-right: 100px;
    width: 65.6%; }
    .infor-news-events-2__wrapper .infor-news-events-2__left--title {
      margin: 40px 0; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--title p {
        margin-bottom: 18px; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--title .title {
        text-transform: uppercase; }
    .infor-news-events-2__wrapper .infor-news-events-2__left--decs {
      display: flex;
      flex-direction: column;
      color: #9e9e9e;
      line-height: 1.6; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--decs .decs {
        margin-bottom: 22px;
        line-height: 1.6; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section {
        margin: 40px auto;
        text-align: center;
        width: 514px;
        height: 100%; }
        .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section img {
          width: 100%;
          height: auto; }
        .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section .image-decs {
          margin-top: 20px; }
  .infor-news-events-2__wrapper .infor-news-events-2__right {
    width: calc(100% - 65.6%);
    margin-left: auto; }
    .infor-news-events-2__wrapper .infor-news-events-2__right .title {
      margin-bottom: 40px;
      font-family: ROBOTO-MEDIUM; }
    .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile {
      display: none; }
    .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 100px;
      position: relative; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items:not(:last-child)::after {
        content: "";
        bottom: -30px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #1D5632;
        position: absolute; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items:hover .text-24 {
        color: #1D5632; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__image {
        width: 122px;
        height: 104px;
        overflow: hidden; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__image img {
          width: 100%;
          height: auto; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__decs {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        width: 268px;
        height: 100%; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__decs .text-24 {
          transform: translateY(-6px);
          transition: .2s ease-in-out;
          font-family: ROBOTO-MEDIUM; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__decs .text-16 {
          transform: translateY(6px); }

@media (max-width: 1366px) {
  .infor-news-events-2__wrapper .infor-news-events-2 {
    display: flex; }
    .infor-news-events-2__wrapper .infor-news-events-2__left {
      padding-right: 80px; }
    .infor-news-events-2__wrapper .infor-news-events-2__right {
      width: calc(100% - 65.6%);
      margin-left: auto; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .title {
        margin-bottom: 40px; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile {
        display: none; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items {
        margin-bottom: 90px; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__image {
          width: 92px;
          height: 100%; }
          .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__image img {
            height: 100%;
            object-fit: contain; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__decs {
          width: 238px; } }

@media (max-width: 1365px) {
  .infor-news-events-2__wrapper .infor-news-events-2__left {
    padding-right: 80px; }
  .infor-news-events-2__wrapper .infor-news-events-2__right {
    width: calc(100% - 65.6%); }
    .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items {
      width: 100%; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items:hover .text-24 {
        color: #1D5632; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__image {
        width: 100px;
        height: 100%; } }

@media (max-width: 1200px) {
  .infor-news-events-2__wrapper .infor-news-events-2 {
    display: flex; }
    .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items {
      margin-bottom: 70px; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__image {
        width: 30%;
        height: unset; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__decs {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        width: 65%;
        height: 100%; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .news-events .news-items__decs .text-24 {
          transition: .2s ease-in-out; } }

@media (max-width: 1199px) {
  .infor-news-events-2__wrapper .infor-news-events-2 {
    display: unset; }
    .infor-news-events-2__wrapper .infor-news-events-2__left {
      padding-right: 0;
      width: 100%; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--title {
        margin: 30px 0; }
        .infor-news-events-2__wrapper .infor-news-events-2__left--title p {
          margin-bottom: 10px; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--decs {
        display: flex;
        flex-direction: column; }
        .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section {
          margin: 40px auto;
          text-align: center;
          width: 60%;
          height: 100%; }
          .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section img {
            width: 100%;
            height: auto; }
          .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section .image-decs {
            margin-top: 15px; }
    .infor-news-events-2__wrapper .infor-news-events-2__right {
      width: 100%; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .title {
        margin-top: 40px; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile {
        display: block;
        align-items: center; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__image {
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin-bottom: 20px; }
          .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__image img {
            width: 100%;
            height: auto; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__decs {
          display: flex;
          flex-direction: column;
          align-content: space-between;
          width: 100%;
          height: 100%; }
          .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__decs .text-24 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 10px;
            transition: .2s ease-in-out; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events {
        display: none; } }

@media (max-width: 430px) {
  .infor-news-events-2__wrapper .infor-news-events-2 {
    display: unset; }
    .infor-news-events-2__wrapper .infor-news-events-2__left {
      padding-right: 0;
      width: 100%; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--title {
        margin: 20px 0; }
      .infor-news-events-2__wrapper .infor-news-events-2__left--decs {
        display: flex;
        flex-direction: column; }
        .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section {
          margin: 15px auto;
          text-align: center;
          width: 60%;
          height: 100%; }
          .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section img {
            width: 100%;
            height: auto; }
          .infor-news-events-2__wrapper .infor-news-events-2__left--decs .image-section .image-decs {
            margin-top: 15px; }
    .infor-news-events-2__wrapper .infor-news-events-2__right {
      width: 100%; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .title {
        margin-top: 40px; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile {
        display: block;
        align-items: center; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__image {
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin-bottom: 20px; }
          .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__image img {
            width: 100%;
            height: auto; }
        .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__decs {
          display: flex;
          flex-direction: column;
          align-content: space-between;
          width: 100%;
          height: 100%; }
          .infor-news-events-2__wrapper .infor-news-events-2__right .new-events__Mobile .swiper-slide .news-items__decs .text-24 {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 10px;
            transition: .2s ease-in-out;
            font-family: ROBOTO-MEDIUM; }
      .infor-news-events-2__wrapper .infor-news-events-2__right .news-events {
        display: none; } }

.our-club-banner__wrapper .our-club-banner {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }
  @media (max-width: 1366px) {
    .our-club-banner__wrapper .our-club-banner {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .our-club-banner__wrapper .our-club-banner {
      height: 100%;
      min-height: 350px; } }
  @media (max-width: 430px) {
    .our-club-banner__wrapper .our-club-banner {
      margin-top: 70px; } }
  @media (max-width: 380px) {
    .our-club-banner__wrapper .our-club-banner {
      min-height: 250px;
      margin-top: 55px; } }
  .our-club-banner__wrapper .our-club-banner .banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 100%; }
    .our-club-banner__wrapper .our-club-banner .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner {
      position: absolute;
      top: 60%;
      left: 100px;
      transform: translateY(-50%);
      z-index: 2; }
      @media (max-width: 1024px) {
        .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner {
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 80%;
          top: 50%; } }
      .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner .text-section {
        max-width: 400px;
        width: 30vw; }
        @media (max-width: 1024px) {
          .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner .text-section {
            width: 100%;
            max-width: unset; } }
        .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner .text-section .title {
          text-transform: uppercase; }
        .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner .text-section * {
          margin-bottom: 30px; }
          @media (max-width: 450px) {
            .our-club-banner__wrapper .our-club-banner .banner .overlay-text-banner .text-section * {
              margin-bottom: 15px; } }
  .our-club-banner__wrapper .our-club-banner .overlay-pattern {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: visible; }
    @media (max-width: 1024px) {
      .our-club-banner__wrapper .our-club-banner .overlay-pattern {
        width: 160%; } }
    .our-club-banner__wrapper .our-club-banner .overlay-pattern img {
      min-width: 786px;
      object-fit: cover;
      height: 100%; }
      @media (max-width: 1024px) {
        .our-club-banner__wrapper .our-club-banner .overlay-pattern img {
          width: 100%; } }

.our-club-2__wrapper .our-club-2 {
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.08);
  background: unset;
  display: flex;
  height: 728px; }
  .our-club-2__wrapper .our-club-2 .img-section {
    width: 530px;
    height: 100%; }
    .our-club-2__wrapper .our-club-2 .img-section img {
      width: 100%;
      height: 100%; }
  .our-club-2__wrapper .our-club-2 .text-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 726px;
    position: relative; }
    .our-club-2__wrapper .our-club-2 .text-section .text {
      height: 50%;
      padding-left: 30px;
      padding-right: 90px;
      margin-bottom: 60px; }
      .our-club-2__wrapper .our-club-2 .text-section .text * {
        margin-bottom: 30px; }
      .our-club-2__wrapper .our-club-2 .text-section .text .title {
        text-transform: uppercase; }
      .our-club-2__wrapper .our-club-2 .text-section .text .decs {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden; }
    .our-club-2__wrapper .our-club-2 .text-section .overlay {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-flex;
      justify-content: flex-end; }
      .our-club-2__wrapper .our-club-2 .text-section .overlay img {
        height: 100%; }

@media (max-width: 1366px) {
  .our-club-2__wrapper .our-club-2 {
    height: 528px; }
    .our-club-2__wrapper .our-club-2 .img-section img {
      object-fit: cover; }
    .our-club-2__wrapper .our-club-2 .text-section .text {
      height: 50%;
      padding-left: 20px;
      padding-right: 90px;
      margin-bottom: 30px; }
      .our-club-2__wrapper .our-club-2 .text-section .text * {
        margin-bottom: 20px; }
    .our-club-2__wrapper .our-club-2 .text-section .overlay {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-flex;
      justify-content: flex-end; }
      .our-club-2__wrapper .our-club-2 .text-section .overlay img {
        width: 80%; } }

@media (max-width: 1024px) {
  .our-club-2__wrapper .our-club-2 {
    height: unset; }
    .our-club-2__wrapper .our-club-2 .img-section img {
      height: auto; }
    .our-club-2__wrapper .our-club-2 .text-section {
      height: unset; }
      .our-club-2__wrapper .our-club-2 .text-section .text {
        margin-bottom: 35px;
        padding-right: 50px; }
        .our-club-2__wrapper .our-club-2 .text-section .text * {
          margin-bottom: 20px; } }

@media (max-width: 768px) {
  .our-club-2__wrapper .our-club-2 .text-section .text {
    margin-bottom: 20px;
    padding-right: 40px; }
    .our-club-2__wrapper .our-club-2 .text-section .text * {
      margin-bottom: 20px; }
  .our-club-2__wrapper .our-club-2 .text-section .overlay img {
    width: 60%; } }

@media (max-width: 430px) {
  .our-club-2__wrapper .our-club-2 {
    box-shadow: 1px 1px 13px 0 rgba(0, 0, 0, 0.08);
    height: 270px; }
    .our-club-2__wrapper .our-club-2 .img-section {
      display: none; }
    .our-club-2__wrapper .our-club-2 .text-section .overlay-section {
      padding: 30px; }
    .our-club-2__wrapper .our-club-2 .text-section .text {
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 20px; }
      .our-club-2__wrapper .our-club-2 .text-section .text * {
        margin-bottom: 10px; }
      .our-club-2__wrapper .our-club-2 .text-section .text .decs {
        -webkit-line-clamp: 3; } }

@media (max-width: 380px) {
  .our-club-2__wrapper .our-club-2 {
    height: 240px; }
    .our-club-2__wrapper .our-club-2 .text-section .overlay-section {
      padding: 15px; } }

@media (max-width: 320px) {
  .our-club-2__wrapper .our-club-2 {
    height: 200px; } }

.our-club-3__wrapper {
  position: relative; }
  .our-club-3__wrapper::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 433px;
    background: #1D5632;
    content: "";
    z-index: -1; }
  .our-club-3__wrapper .container {
    display: flex;
    align-items: center; }
    .our-club-3__wrapper .container .our-club-3__left .text-section {
      width: 636px; }
      .our-club-3__wrapper .container .our-club-3__left .text-section p {
        margin-bottom: 22px; }
      .our-club-3__wrapper .container .our-club-3__left .text-section .title {
        font-family: ROBOTO-MEDIUM; }
      .our-club-3__wrapper .container .our-club-3__left .text-section .decs {
        line-height: 1.4;
        position: relative; }
        .our-club-3__wrapper .container .our-club-3__left .text-section .decs::before {
          position: absolute;
          top: 0;
          left: -50px;
          content: "";
          width: 35px;
          height: 35px;
          background-image: url("../assets/images/our-club/overlay-desc.png");
          background-repeat: no-repeat;
          background-position: center;
          transform: scaleX(-1);
          background-size: contain; }
        .our-club-3__wrapper .container .our-club-3__left .text-section .decs::after {
          position: absolute;
          bottom: 0;
          right: -50px;
          content: "";
          width: 35px;
          height: 35px;
          background-image: url("../assets/images/our-club/overlay-desc.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain; }
    .our-club-3__wrapper .container .our-club-3__right {
      margin-left: auto; }
      .our-club-3__wrapper .container .our-club-3__right .image-avatar {
        width: 514px;
        height: 537px;
        overflow: hidden; }
        .our-club-3__wrapper .container .our-club-3__right .image-avatar img {
          width: 100%;
          height: auto; }

@media (max-width: 1366px) {
  .our-club-3__wrapper {
    position: relative; }
    .our-club-3__wrapper::after {
      height: 333px; }
    .our-club-3__wrapper .container {
      display: flex;
      align-items: center; }
      .our-club-3__wrapper .container .our-club-3__left {
        width: 55%; }
        .our-club-3__wrapper .container .our-club-3__left .text-section {
          width: 100%; }
          .our-club-3__wrapper .container .our-club-3__left .text-section p {
            margin-bottom: 22px; }
      .our-club-3__wrapper .container .our-club-3__right {
        width: 35%; }
        .our-club-3__wrapper .container .our-club-3__right .image-avatar {
          width: 100%;
          height: 437px; }
          .our-club-3__wrapper .container .our-club-3__right .image-avatar img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }

@media (max-width: 1024px) {
  .our-club-3__wrapper {
    position: relative; }
    .our-club-3__wrapper::after {
      height: 333px; }
    .our-club-3__wrapper .container {
      display: flex;
      align-items: center; }
      .our-club-3__wrapper .container .our-club-3__left {
        width: 55%; }
        .our-club-3__wrapper .container .our-club-3__left .text-section {
          padding: 0 40px;
          width: 100%; }
          .our-club-3__wrapper .container .our-club-3__left .text-section p {
            margin-bottom: 22px; }
          .our-club-3__wrapper .container .our-club-3__left .text-section .decs::before {
            width: 27px;
            height: 27px;
            background-image: url("../assets/images/our-club/overlay-desc.png");
            background-repeat: no-repeat;
            background-position: center;
            transform: scaleX(-1);
            background-size: contain; }
          .our-club-3__wrapper .container .our-club-3__left .text-section .decs::after {
            position: absolute;
            bottom: 0;
            right: -50px;
            content: "";
            width: 27px;
            height: 27px;
            background-image: url("../assets/images/our-club/overlay-desc.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain; }
      .our-club-3__wrapper .container .our-club-3__right {
        width: 35%; }
        .our-club-3__wrapper .container .our-club-3__right .image-avatar {
          width: 100%;
          height: 437px; }
          .our-club-3__wrapper .container .our-club-3__right .image-avatar img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }

@media (max-width: 768px) {
  .our-club-3__wrapper {
    margin-top: 60%;
    padding-top: 100px;
    padding-bottom: 30px;
    background: #1D5632;
    position: relative; }
    .our-club-3__wrapper::after {
      display: none; }
    .our-club-3__wrapper .container {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .our-club-3__wrapper .container .our-club-3__left {
        width: 100%; }
        .our-club-3__wrapper .container .our-club-3__left .text-section {
          position: relative;
          width: 100%; }
          .our-club-3__wrapper .container .our-club-3__left .text-section p {
            margin-bottom: 15px; }
          .our-club-3__wrapper .container .our-club-3__left .text-section .decs {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            position: relative; }
            .our-club-3__wrapper .container .our-club-3__left .text-section .decs::before {
              display: none; }
            .our-club-3__wrapper .container .our-club-3__left .text-section .decs::after {
              display: none; }
          .our-club-3__wrapper .container .our-club-3__left .text-section::before {
            position: absolute;
            top: 20px;
            left: 0;
            content: "";
            width: 15px;
            height: 15px;
            background-image: url("../assets/images/our-club/overlay-desc.png");
            background-repeat: no-repeat;
            background-position: center;
            transform: scaleX(-1);
            background-size: contain; }
          .our-club-3__wrapper .container .our-club-3__left .text-section::after {
            position: absolute;
            bottom: 20px;
            right: 0;
            content: "";
            width: 15px;
            height: 15px;
            background-image: url("../assets/images/our-club/overlay-desc.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain; }
      .our-club-3__wrapper .container .our-club-3__right {
        position: absolute;
        top: -50vw;
        left: 50%;
        transform: translate(-50%);
        width: 60%; }
        .our-club-3__wrapper .container .our-club-3__right .image-avatar {
          width: 100%;
          height: 100%; }
          .our-club-3__wrapper .container .our-club-3__right .image-avatar img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }

@media (max-width: 430px) {
  .our-club-3__wrapper {
    padding-top: 80px; }
    .our-club-3__wrapper .container .our-club-3__right {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      width: 60%; }
      .our-club-3__wrapper .container .our-club-3__right .image-avatar {
        width: 100%;
        height: 100%; }
        .our-club-3__wrapper .container .our-club-3__right .image-avatar img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

@media (max-width: 320px) {
  .our-club-3__wrapper {
    padding-top: 70px; } }

.our-club-4__wrapper .our-club-4--items {
  display: flex;
  align-items: center;
  margin-bottom: 104px; }
  @media (max-width: 900px) {
    .our-club-4__wrapper .our-club-4--items {
      margin-bottom: 64px; } }
  @media (max-width: 768px) {
    .our-club-4__wrapper .our-club-4--items {
      margin-bottom: 54px; } }
  @media (max-width: 540px) {
    .our-club-4__wrapper .our-club-4--items {
      flex-direction: column;
      margin-bottom: 34px; } }
  .our-club-4__wrapper .our-club-4--items__left {
    width: 52%; }
    @media (max-width: 540px) {
      .our-club-4__wrapper .our-club-4--items__left {
        width: 100%; } }
    .our-club-4__wrapper .our-club-4--items__left .image {
      width: 100%;
      padding-bottom: 60%;
      position: relative;
      overflow: hidden; }
      .our-club-4__wrapper .our-club-4--items__left .image img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .our-club-4__wrapper .our-club-4--items__right {
    width: calc(100% - 52%); }
    @media (max-width: 540px) {
      .our-club-4__wrapper .our-club-4--items__right {
        width: 100%;
        background: #E7ECE9;
        padding: 20px; } }
    .our-club-4__wrapper .our-club-4--items__right .text-section {
      padding-left: 104px; }
      @media (max-width: 900px) {
        .our-club-4__wrapper .our-club-4--items__right .text-section {
          padding-left: 64px; } }
      @media (max-width: 768px) {
        .our-club-4__wrapper .our-club-4--items__right .text-section {
          padding-left: 44px; } }
      @media (max-width: 540px) {
        .our-club-4__wrapper .our-club-4--items__right .text-section {
          padding-left: unset; } }
      .our-club-4__wrapper .our-club-4--items__right .text-section .title {
        font-family: ROBOTO-MEDIUM; }
      .our-club-4__wrapper .our-club-4--items__right .text-section .decs {
        margin-top: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 10px; }
        @media (max-width: 768px) {
          .our-club-4__wrapper .our-club-4--items__right .text-section .decs {
            margin-top: 30px; } }

.infor-our-club-2__wrapper .infor-our-club-2 {
  display: flex; }
  .infor-our-club-2__wrapper .infor-our-club-2__left--title {
    margin: 40px 0; }
    .infor-our-club-2__wrapper .infor-our-club-2__left--title p {
      margin-bottom: 18px; }
    .infor-our-club-2__wrapper .infor-our-club-2__left--title .title {
      text-transform: uppercase; }
  .infor-our-club-2__wrapper .infor-our-club-2__left--decs {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .infor-our-club-2__wrapper .infor-our-club-2__left--decs .decs {
      line-height: 1.6;
      margin-bottom: 22px; }
    .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section {
      margin: 40px 0;
      text-align: center;
      width: 1044px;
      height: 100%; }
      .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section img {
        width: 100%;
        height: auto; }
      .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section .image-decs {
        margin-top: 20px; }

@media (max-width: 1366px) {
  .infor-our-club-2__wrapper .infor-our-club-2 {
    display: flex; }
    .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section {
      width: 80%; } }

@media (max-width: 1200px) {
  .infor-our-club-2__wrapper .infor-our-club-2 {
    display: flex; } }

@media (max-width: 1199px) {
  .infor-our-club-2__wrapper .infor-our-club-2 {
    display: unset; }
    .infor-our-club-2__wrapper .infor-our-club-2__left {
      padding-right: 0;
      width: 100%; }
      .infor-our-club-2__wrapper .infor-our-club-2__left--title {
        margin: 30px 0; }
        .infor-our-club-2__wrapper .infor-our-club-2__left--title p {
          margin-bottom: 10px; }
      .infor-our-club-2__wrapper .infor-our-club-2__left--decs {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section {
          margin: 40px 0;
          text-align: center;
          width: 60%;
          height: 100%; }
          .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section img {
            width: 100%;
            height: auto; }
          .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section .image-decs {
            margin-top: 15px; } }

@media (max-width: 1024px) {
  .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section {
    margin: 20px 0; } }

@media (max-width: 430px) {
  .infor-our-club-2__wrapper .infor-our-club-2 {
    display: unset; }
    .infor-our-club-2__wrapper .infor-our-club-2__left {
      padding-right: 0;
      width: 100%; }
      .infor-our-club-2__wrapper .infor-our-club-2__left--title {
        margin: 20px 0; }
      .infor-our-club-2__wrapper .infor-our-club-2__left--decs .image-section {
        margin: 15px 0; } }

.infor-our-club-3__wrapper .infor-our-club-3 {
  display: flex;
  justify-content: space-between; }
  .infor-our-club-3__wrapper .infor-our-club-3__items {
    background: #EBF0ED;
    width: 576px;
    height: 643px;
    box-shadow: 0px 6px 7px 0 rgba(0, 0, 0, 0.12); }
    .infor-our-club-3__wrapper .infor-our-club-3__items--image {
      width: 100%;
      height: 50%;
      overflow: hidden; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--image img {
        width: 100%;
        height: auto; }
    .infor-our-club-3__wrapper .infor-our-club-3__items--decs {
      width: 100%;
      height: 50%;
      padding: 35px  52px;
      overflow: hidden; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section *:not(:first-child) {
        margin-bottom: 25px; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .head-title {
        text-transform: uppercase;
        margin-bottom: 12px; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .link-title {
        font-family: ROBOTO-MEDIUM; }
        .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .link-title a:hover {
          transition: 0.2s ease-in-out;
          color: #1D5632; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .decs {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden; }

@media (max-width: 1366px) {
  .infor-our-club-3__wrapper .infor-our-club-3__items {
    width: 47%;
    height: 483px; }
    .infor-our-club-3__wrapper .infor-our-club-3__items--image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .infor-our-club-3__wrapper .infor-our-club-3__items--decs {
      padding: 35px 32px; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section *:not(:first-child) {
        margin-bottom: 15px; } }

@media (max-width: 768px) {
  .infor-our-club-3__wrapper .infor-our-club-3__items {
    height: 100%; }
    .infor-our-club-3__wrapper .infor-our-club-3__items--decs {
      padding: 25px 12px; } }

@media (max-width: 430px) {
  .infor-our-club-3__wrapper .infor-our-club-3__items--decs {
    padding: 10px; }
    .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section {
      width: 100%; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .head-title {
        margin-bottom: 7px; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .link-title a {
        font-size: 12px; }
      .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section *:not(:first-child) {
        margin-bottom: 10px; } }

@media (max-width: 380px) {
  .infor-our-club-3__wrapper .infor-our-club-3__items--decs .text-section .link-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; } }

.your-moments-banner__wrapper .your-moments-banner {
  width: 100%;
  height: calc(100vh - 125px);
  position: relative;
  margin-top: 125px;
  max-width: 1920px;
  max-height: 1080px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }
  @media (max-width: 1366px) {
    .your-moments-banner__wrapper .your-moments-banner {
      height: calc(100vh - 80px);
      margin-top: 80px; } }
  @media (max-width: 1024px) {
    .your-moments-banner__wrapper .your-moments-banner {
      height: 100%; } }
  @media (max-width: 430px) {
    .your-moments-banner__wrapper .your-moments-banner {
      margin-top: 70px; } }
  @media (max-width: 380px) {
    .your-moments-banner__wrapper .your-moments-banner {
      margin-top: 55px; } }
  .your-moments-banner__wrapper .your-moments-banner .banner {
    height: 100%; }
    .your-moments-banner__wrapper .your-moments-banner .banner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -39px;
    width: 61vh;
    height: 61vh;
    left: 50%;
    transform: translateX(-47%);
    background: rgba(29, 86, 50, 0.8);
    border-radius: 50%;
    padding-bottom: 60px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .decs {
      width: 80%;
      text-align: center;
      text-align: center;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden; }
  .your-moments-banner__wrapper .your-moments-banner .overlay-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 104px;
    background: #1D5632; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-link .container {
      height: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .your-moments-banner__wrapper .your-moments-banner .overlay-link .container .link-items {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 100%;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.2s ease-in-out; }
        .your-moments-banner__wrapper .your-moments-banner .overlay-link .container .link-items:hover {
          background: #ffffff;
          color: #1D5632; }

@media (max-width: 1440px) {
  .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
    bottom: -60px;
    padding-bottom: 60px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
      width: 80%; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .decs {
      width: 80%;
      text-align: center;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden; }
  .your-moments-banner__wrapper .your-moments-banner .overlay-link {
    height: 64px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-link .container .link-items {
      font-size: 31px; } }

@media (max-width: 1366px) {
  .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
    bottom: -56px;
    padding-bottom: 60px; } }

@media (max-width: 1200px) {
  .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
    bottom: -64px;
    padding-bottom: 60px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
      width: 80%;
      margin-bottom: 20px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .decs {
      width: 80%;
      text-align: center;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden; }
  .your-moments-banner__wrapper .your-moments-banner .overlay-link {
    height: 64px; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-link .container .link-items {
      font-size: 31px; } }

@media (max-width: 1024px) {
  .your-moments-banner__wrapper .your-moments-banner {
    height: 100%; }
    .your-moments-banner__wrapper .your-moments-banner .banner img {
      transform: unset; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
      bottom: 16px;
      width: 30vw;
      height: 60%;
      padding-bottom: 0; }
      .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
        width: 80%;
        margin-bottom: 0px; } }
    @media (max-width: 1024px) and (max-width: 900px) {
      .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
        font-size: 3vw; }
      .your-moments-banner__wrapper .your-moments-banner .overlay-circle .decs {
        display: none; } }

@media (max-width: 768px) {
  .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
    bottom: 12px;
    padding-bottom: 0; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
      font-size: 2.8vw; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle .decs {
      display: none; }
  .your-moments-banner__wrapper .your-moments-banner .overlay-link {
    height: 8vw; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-link .container .link-items {
      font-size: 3.8vw; } }

@media (max-width: 430px) {
  .your-moments-banner__wrapper .your-moments-banner {
    height: 100%; }
    .your-moments-banner__wrapper .your-moments-banner .banner img {
      transform: unset; }
    .your-moments-banner__wrapper .your-moments-banner .overlay-circle {
      bottom: 7px;
      padding-bottom: 0; }
      .your-moments-banner__wrapper .your-moments-banner .overlay-circle .title {
        width: 80%;
        margin-bottom: 0px; } }

.your-moments-morning__wrapper .container {
  padding: 0; }

.your-moments-morning__wrapper .your-moments-morning__title {
  text-align: center;
  margin-bottom: 52px;
  text-transform: uppercase; }
  .your-moments-morning__wrapper .your-moments-morning__title > * {
    text-align: center; }

.your-moments-morning__wrapper .your-moments-morning__pic {
  display: flex;
  height: 872px; }
  .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning1 {
    width: 78%; }
    .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning1 .swiper-slide {
      cursor: pointer; }
      .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning1 .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .your-moments-morning__wrapper .your-moments-morning__pic .nav-custom {
    width: 20%;
    height: 100%; }
  .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning-1 {
    position: unset;
    width: 100%;
    height: 100%; }
    .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning-1 .swiper-slide {
      cursor: pointer; }
      .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning-1 .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

@media (max-width: 1366px) {
  .your-moments-morning__wrapper .your-moments-morning__pic {
    display: flex;
    height: 572px; } }

@media (max-width: 1024px) {
  .your-moments-morning__wrapper .swiper-scrollbar {
    display: none; }
  .your-moments-morning__wrapper .your-moments-morning__title {
    margin-bottom: 32px; }
  .your-moments-morning__wrapper .your-moments-morning__pic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 621px; }
    .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning1 {
      height: 78%;
      width: 100%; }
      .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning1 .swiper-slide {
        cursor: pointer; }
        .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning1 .swiper-slide img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .your-moments-morning__wrapper .your-moments-morning__pic .nav-custom {
      height: 20%;
      width: 100%; }
    .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning-1 {
      position: unset;
      width: 100%;
      height: 100%; }
      .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning-1 .swiper-slide {
        cursor: pointer; }
        .your-moments-morning__wrapper .your-moments-morning__pic .mySwiperMorning-1 .swiper-slide img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

@media (max-width: 768px) {
  .your-moments-morning__wrapper .container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .your-moments-morning__wrapper .your-moments-morning__pic {
    width: 98%;
    height: 521px; } }

@media (max-width: 430px) {
  .your-moments-morning__wrapper .your-moments-morning__title {
    margin-bottom: 22px; }
  .your-moments-morning__wrapper .your-moments-morning__pic {
    width: 98%;
    height: 321px; } }

.your-moments-noon__wrapper {
  width: 100%; }
  .your-moments-noon__wrapper .your-moments-noon__title {
    text-align: center;
    margin-bottom: 52px;
    text-transform: uppercase; }
    .your-moments-noon__wrapper .your-moments-noon__title > * {
      text-align: center; }
  .your-moments-noon__wrapper .your-moments-noon__pic {
    display: flex;
    height: 872px;
    width: 100%;
    justify-content: space-evenly; }
    .your-moments-noon__wrapper .your-moments-noon__pic .swiper-button-next {
      right: 30px;
      background: #ffffff; }
    .your-moments-noon__wrapper .your-moments-noon__pic .swiper-button-prev {
      left: 30px;
      background: #ffffff; }
    .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom {
      width: 50%; }
      .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 {
        width: 100%;
        height: 100%; }
        .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative; }
          .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide img {
            width: 100%;
            height: 32.5%;
            object-fit: cover; }
          .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(4n+1) {
            justify-content: space-evenly; }
            .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(4n+1)::after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              background: #1D5632;
              width: 100%;
              height: 32.5%; }
            .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(4n+1)::before {
              position: absolute;
              bottom: 0;
              left: 0;
              content: "";
              background: #B5AC3D;
              width: 100%;
              height: 32.5%; }
          .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(2n)::after {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            content: "";
            background: #FF8300;
            width: 100%;
            height: 32.5%; }
          .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(4n+3) {
            justify-content: space-evenly; }
            .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(4n+3)::after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              background: #728338;
              width: 100%;
              height: 32.5%; }
            .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .mySwiperNoon1 .swiper-wrapper .swiper-slide:nth-child(4n+3)::before {
              position: absolute;
              bottom: 0;
              left: 0;
              content: "";
              background: #1D5632;
              width: 100%;
              height: 32.5%; }
    .your-moments-noon__wrapper .your-moments-noon__pic .img-right {
      width: 49%;
      height: 100%; }
      .your-moments-noon__wrapper .your-moments-noon__pic .img-right img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

@media (max-width: 1440px) {
  .your-moments-noon__wrapper .your-moments-noon__pic {
    height: 100%; } }

@media (max-width: 768px) {
  .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-next,
  .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-prev {
    width: 30px;
    height: 30px; }
    .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-next::after,
    .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-prev::after {
      background-size: 12px;
      transform: unset; } }

@media (max-width: 430px) {
  .your-moments-noon__wrapper .your-moments-noon__title {
    margin-bottom: 22px; }
  .your-moments-noon__wrapper .your-moments-noon__pic {
    height: 372px;
    flex-direction: column; }
    .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom {
      width: 100%;
      height: 100%; }
      .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-next,
      .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-prev {
        display: block;
        width: 30px;
        height: 30px; }
        .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-next::after,
        .your-moments-noon__wrapper .your-moments-noon__pic .nav-custom .swiper-button-prev::after {
          display: block;
          background-size: 12px;
          transform: unset;
          width: 100%;
          height: 100%; }
    .your-moments-noon__wrapper .your-moments-noon__pic .img-right {
      display: none; } }

.your-moments-afternoon__wrapper .your-moments-afternoon__title {
  text-align: center;
  margin-bottom: 52px;
  text-transform: uppercase; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__title > * {
    text-align: center; }

.your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 {
  margin-bottom: 10px; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide {
    cursor: pointer; }
    .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide img {
      width: 100%;
      height: 872px;
      object-fit: cover; }

.your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide {
  cursor: pointer; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide img {
    width: 302px;
    height: 320px;
    object-fit: cover; }

@media (max-width: 1366px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide img {
    height: 572px; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide img {
    height: 220px; } }

@media (max-width: 1024px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .swiper-button-next,
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .swiper-button-prev {
    display: none; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide img {
    height: 522px; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide img {
    height: 200px; } }

@media (max-width: 768px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .swiper-button-next,
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .swiper-button-prev {
    display: none; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide img {
    height: 482px; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide img {
    height: 180px; } }

@media (max-width: 430px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__title {
    margin-bottom: 22px; }
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide img {
    height: 322px; } }

@media (max-width: 430px) and (max-width: 380px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .mySwiperAfternoon1 .swiper-slide {
    height: 280px; } }

@media (max-width: 430px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide img {
    height: 100px; } }

@media (max-width: 430px) and (max-width: 380px) {
  .your-moments-afternoon__wrapper .your-moments-afternoon__pic .nav-custom .mySwiperAfternoon-1 .swiper-slide {
    height: 80px; } }

.your-moments-night__wrapper .your-moments-night__title {
  text-align: center;
  margin-bottom: 52px;
  text-transform: uppercase; }
  .your-moments-night__wrapper .your-moments-night__title > * {
    text-align: center; }

.your-moments-night__wrapper .your-moments-night__pic {
  height: 872px; }
  .your-moments-night__wrapper .your-moments-night__pic .swiper-button-next {
    right: 30px;
    background: #ffffff; }
  .your-moments-night__wrapper .your-moments-night__pic .swiper-button-prev {
    left: 30px;
    background: #ffffff; }
  .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 {
    margin-bottom: 10px; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper {
      align-items: center; }
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
        transition: height 0.5s ease-in-out;
        width: 60% !important;
        height: 872px; }
        .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
        width: 41vw !important;
        height: 618px; }
        .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next img,
        .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .your-moments-night__wrapper .your-moments-night__pic .swiper-button-next {
    right: 20px; }
  .your-moments-night__wrapper .your-moments-night__pic .swiper-button-prev {
    left: 20px; }

@media (max-width: 1440px) {
  .your-moments-night__wrapper .your-moments-night__pic {
    height: 672px; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
      height: 672px; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
      height: 518px; } }

@media (max-width: 1366px) {
  .your-moments-night__wrapper .your-moments-night__pic {
    height: 572px; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
      height: 572px;
      width: 55% !important; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
      height: 418px;
      width: 45vw !important; } }

@media (max-width: 1024px) {
  .your-moments-night__wrapper .your-moments-night__pic {
    height: 472px; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
      height: 472px;
      width: 55% !important; }
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
      height: 318px;
      width: 45vw !important; } }

@media (max-width: 430px) {
  .your-moments-night__wrapper .your-moments-night__title {
    margin-bottom: 22px; }
  .your-moments-night__wrapper .your-moments-night__pic {
    height: 272px; } }
  @media (max-width: 430px) and (max-width: 380px) {
    .your-moments-night__wrapper .your-moments-night__pic {
      height: 258px; } }
  @media (max-width: 430px) and (max-width: 320px) {
    .your-moments-night__wrapper .your-moments-night__pic {
      height: 208px; } }

@media (max-width: 430px) {
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
      height: 272px;
      width: 55% !important; } }
    @media (max-width: 430px) and (max-width: 380px) {
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
        height: 258px; } }
    @media (max-width: 430px) and (max-width: 320px) {
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-active {
        height: 208px; } }

@media (max-width: 430px) {
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
    .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
      height: 218px;
      width: 42vw !important; } }
    @media (max-width: 430px) and (max-width: 380px) {
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
        height: 188px; } }
    @media (max-width: 430px) and (max-width: 320px) {
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-next,
      .your-moments-night__wrapper .your-moments-night__pic .mySwiperNight1 .swiper-wrapper .swiper-slide-prev {
        height: 158px; } }
