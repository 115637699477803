.home-7__wrapper{
    background: map-get($mau, main-gray-op-17 );
    // padding: 100px 0;
    .home-7{
        position: relative;
        .label{
            font-family: ROBOTO-MEDIUM;
            top: -40px;
            position: absolute;
            // margin: 20px 0;
        }
        .nav-custom{
            position: relative;
            .swiper-wrapper{
                padding: 10px 0;
                .swiper-slide{
                    background: map-get($mau, white-1 );
                    width: 172px;
                    height: 216px;
                    box-shadow: 1px 1px 7px 0 map-get($mau, black-op-8 );
                    position: relative;
                    &::after{
                        width: 100%;
                        height: 100%;
                        top: -10px;
                        left: 0;
                        position: absolute;
                        content: "";
                        background: url("../assets/images/home-page/overlay-partner.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% auto;
                    }
                    .slide{
                        position: relative;
                        z-index: 3;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;
                        .img-wrapper{
                            width: 86px;
                            height: 86px;
                            border-radius: 50%;
                            border: solid 3px map-get($mau, main );
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            overflow: hidden;
                            .image{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                width: 100%;
                                height: 100%;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            
                        }
                    }
                }
            }
            
        }
    }
}
@media (max-width: 1366px) {
    .home-7__wrapper{
        .home-7{
            .nav-custom{
                .swiper-wrapper{
                    .swiper-slide{
                        height: 190px;
                        .slide{
                            .img-wrapper{
                                width: 66px;
                                height: 66px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 1200px) {
    .home-7__wrapper{
        .home-7{
            .nav-custom{
                .swiper-wrapper{
                    .swiper-slide{
                        height: 210px;
                        .slide{
                            .img-wrapper{
                                width: 66px;
                                height: 66px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}

@media (max-width: 1200px) {
    .home-7__wrapper{
        .home-7{
            .nav-custom{
                .swiper-wrapper{
                    .swiper-slide{
                        height: 210px;
                        .slide{
                            .img-wrapper{
                                width: 66px;
                                height: 66px;
                            }
                        }
                    }
                }
                .swiper-button-next,.swiper-button-prev{
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .home-7__wrapper{
        .home-7{
            .label{
                top: -20px;
            }
            .nav-custom{
                .swiper-wrapper{
                    .swiper-slide{
                        &::after{
                            background-size: 100% auto;
                        }
                        .slide{
                            .img-wrapper{
                                width: 70px;
                                height: 70px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 430px) {
    .home-7__wrapper{
        .home-7{
            .label{
                top: -25px;
            }
            .nav-custom{
                .swiper-wrapper{
                    .swiper-slide{
                        height: 160px;
                        .slide{
                            .img-wrapper{
                                border: solid 2px map-get($mau, main );
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 380px) {
    .home-7__wrapper{
        .home-7{
            .nav-custom{
                .swiper-wrapper{
                    .swiper-slide{
                        height: 130px;
                        // &::after{
                        //     top: -1px;
                        // }
                        .slide{
                            .img-wrapper{
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}