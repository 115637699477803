.infor-our-club-3__wrapper{
    .infor-our-club-3{
        display: flex;
        justify-content: space-between;
        &__items{
            background: map-get($mau , main-1 );
            width: 576px;
            height: 643px;
            box-shadow: 0px 6px 7px 0 map-get($mau, black-op-12 );
            &--image{
                width: 100%;
                height: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            &--decs{
                width: 100%;
                height: 50%;
                padding: 35px  52px;
                overflow: hidden;
                // padding-right: ;
                .text-section{
                    // width: 70%;
                    *:not(:first-child){
                        margin-bottom: 25px;
                    }
                    .head-title{
                        text-transform: uppercase;
                        margin-bottom: 12px;
                    }
                    .link-title{
                        font-family: ROBOTO-MEDIUM;
                        a:hover{
                            transition: 0.2s ease-in-out;
                            color: map-get($mau, main );
                        }
                    }
                    .decs{
                        display: -webkit-box;
                        -webkit-line-clamp:4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
               
            }
        }
    }
}
@media (max-width: 1366px) {
    .infor-our-club-3__wrapper{
        .infor-our-club-3{
            &__items{
                width: 47%;
                height: 483px;
                &--image{
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &--decs{
                    padding: 35px 32px;
                    .text-section{
                        *:not(:first-child){
                            margin-bottom: 15px;
                        }
                    
                    }
                   
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .infor-our-club-3__wrapper{
        .infor-our-club-3{
            &__items{
                height: 100%;
               
                &--decs{
                    padding: 25px 12px;
                   
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .infor-our-club-3__wrapper{
        .infor-our-club-3{
            &__items{
                &--decs{
                    padding: 10px;
                    .text-section{
                        width: 100%;
                        .head-title{
                            margin-bottom: 7px;
                        }
                        .link-title{
                            a{
                                font-size: 12px;
                            }
                           
                        }
                        *:not(:first-child){
                            margin-bottom: 10px;
                        }
                        
                    }
                   
                }
            }
        }
    }
}
@media (max-width: 380px) {
    .infor-our-club-3__wrapper{
        .infor-our-club-3{
            &__items{
                &--decs{
                    .text-section{
                        .link-title{
                            display: -webkit-box;
                            -webkit-line-clamp:2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                   
                }
            }
        }
    }
}