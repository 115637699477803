@font-face {
    font-family: ROBOTO-BLACK ;
    src: url('../fonts/Roboto-Black.otf');
}
@font-face {
    font-family: ROBOTO-BLACKITALIC ;
    src: url('../fonts/Roboto-BlackItalic.otf');
}
@font-face {
    font-family: ROBOTO-BOLD;
    src: url('../fonts/Roboto-Bold.otf');
}
@font-face {
    font-family: ROBOTO-BOLDITALIC;
    src: url('../fonts/Roboto-BoldItalic.otf');
}
@font-face {
    font-family: ROBOTO-ITALIC;
    src: url('../fonts/Roboto-Italic.otf');
}
@font-face {
    font-family: ROBOTO-LIGHT;
    src: url('../fonts/Roboto-Light.otf');
}
@font-face {
    font-family: ROBOTO-LIGHTITALIC;
    src: url('../fonts/Roboto-LightItalic.otf');
}
@font-face {
    font-family: ROBOTO-MEDIUM;
    src: url('../fonts/Roboto-Medium.otf');
}
@font-face {
    font-family: ROBOTO-MEDIUMITALIC;
    src: url('../fonts/Roboto-MediumItalic.otf');
}
@font-face {
    font-family: ROBOTO-REGULAR;
    src: url('../fonts/Roboto-Regular.otf');
}
@font-face {
    font-family: ROBOTO-THIN;
    src: url('../fonts/Roboto-Thin.otf');
}
@font-face {
    font-family: ROBOTO-THINITALIC;
    src: url('../fonts/Roboto-ThinItalic.otf');
}