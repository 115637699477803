::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: map-get($mau, white-op-70 );
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: map-get($mau, text-gray-2 ); 
    border-radius: 0;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: map-get($mau, main-op-80 ); 
  }