.our-club-2__wrapper{
    // padding: 100px 0;
    .our-club-2{
        box-shadow: 4px 4px 7px 0 map-get($mau, black-op-8 );
        background: unset;
        display: flex;
        // align-items: center;
        height: 728px;
        .img-section{
            width: 530px;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .text-section{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            width: 726px;
            position: relative;
            .text{
                height: 50%;
                padding-left: 30px;
                padding-right: 90px;
                margin-bottom: 60px;
                *{
                    margin-bottom: 30px;
                }
                .title{
                    text-transform: uppercase;
                }
                .decs{
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
            .overlay{
                position: absolute;
                top: 0;
                right: 0;
                display: inline-flex;
                justify-content: flex-end;
                img{
                    // width: 80%;
                    height: 100%;
                }
            }
        }
    }
}
@media (max-width: 1366px) {
    .our-club-2__wrapper{
        .our-club-2{
            height: 528px;
            .img-section{
                img{
                    object-fit: cover;
                }
            }
            .text-section{
                .text{
                    height: 50%;
                    padding-left: 20px;
                    padding-right: 90px;
                    margin-bottom: 30px;
                    *{
                        margin-bottom: 20px;
                    }
                }
                .overlay{
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: inline-flex;
                    justify-content: flex-end;
                    img{
                        width: 80%;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .our-club-2__wrapper{
        .our-club-2{
            height: unset;
            .img-section{
                img{
                    height: auto;
                }
            }
            .text-section{
                height: unset;
                .text{
                    margin-bottom: 35px;
                    padding-right: 50px;
                    *{
                        margin-bottom: 20px;
                    }
                }
               
            }
        }
    }
}
@media (max-width: 768px) {
    .our-club-2__wrapper{
        .our-club-2{
            .text-section{
                .text{
                    margin-bottom: 20px;
                    padding-right: 40px;
                    *{
                        margin-bottom: 20px;
                    }
                }
                .overlay{
                    img{
                        width: 60%;
                    }
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .our-club-2__wrapper{
        .our-club-2{
            box-shadow: 1px 1px 13px 0 map-get($mau, black-op-8 );
            height: 270px;
            .img-section{
                display: none;
            }
            .text-section{
                .overlay-section{
                    padding: 30px;
                }
                .text{
                    margin-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 20px;
                    *{
                        margin-bottom: 10px;
                    }
                    .decs{
                        -webkit-line-clamp: 3;
                    }
                }
            }
        }
    }
}
@media (max-width: 380px) {
    .our-club-2__wrapper{
        .our-club-2{
            height: 240px;
            .text-section{
                .overlay-section{
                    padding: 15px;
                }
                // .text{
                //     margin-bottom: 10px;
                //     padding-left: 10px;
                //     padding-right: 20px;
                //     *{
                //         margin-bottom: 10px;
                //     }
                //     .decs{
                //         -webkit-line-clamp: 3;
                //     }
                // }
            }
        }
    }
}
@media (max-width: 320px) {
    .our-club-2__wrapper{
        .our-club-2{
            height: 200px;
            
        }
    }
}