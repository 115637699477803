.breadcrumb{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	&--items{
		font-family: ROBOTO-MEDIUM;
		color: map-get($mau, text-gray-2 );
		position: relative;
		font-size: 24px;
		cursor: pointer;
		@media (max-width: 1366px) {
			font-size: 20px;
		}
		@media (max-width: 1201px) {
			font-size: 18px;
		}
		&:not(:last-child){
			margin-right: 60px;
		}
		&:not(:last-child ):after{
			position: absolute;
			top: 47%;
			transform: translateY(-50%);
			content: "";
			right: -35px;
			width: 0;
			height: 0;
			border-top: 8px solid transparent;
			border-left: 10px solid #555;
			border-bottom: 8px solid transparent;
			border-left-color: map-get($mau,text-gray-2 );
		}
	}
	@media (max-width: 1366px) {
		&--items{
			&:not(:last-child){
				margin-right: 50px;
			}
			&:not(:last-child ):after{
				right: -30px;
				border-top: 7px solid transparent;
				border-left: 9px solid #555;
				border-bottom: 7px solid transparent;
				border-left-color: map-get($mau,text-gray-2 );
			}
		}
	}
	
	@media (max-width: 768px) {
		&--items{
			&:not(:last-child){
				margin-right: 30px;
			}
			font-size: 18px;
			&:not(:last-child ):after{
				right: -20px;
				border-top: 7px solid transparent;
				border-left: 9px solid #555;
				border-bottom: 7px solid transparent;
				border-left-color: map-get($mau,text-gray-2 );
			}
		}
	}
	@media (max-width: 540px) {
		&--items{
			
			// font-size: 13px;
			&:not(:last-child ):after{
				right: -18px;
				border-top: 5px solid transparent;
				border-left: 7px solid #555;
				border-bottom: 5px solid transparent;
				border-left-color: map-get($mau,text-gray-2 );
			}
		}
	}
	@media (max-width: 320px) {
		&--items{
			margin-right: 30px;
			// font-size: 11px;
			&:not(:last-child ):after{
				right: -15px;
				border-top: 5px solid transparent;
				border-left: 7px solid #555;
				border-bottom: 5px solid transparent;
				border-left-color: map-get($mau,text-gray-2 );
			}
		}
	}
}
