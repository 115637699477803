.timetable-2__wrapper{
    ::-webkit-scrollbar {
        display: none;
      }
    background: map-get($mau, main-1);
    .timetable-2{
        padding: 60px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__items{
            text-align: center;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px 0px 20px 0px;
            width: 240px;
            height: 136px;
            background: map-get($mau, white-1);
            box-shadow: 4px 4px 7px 0 map-get($mau, black-op-8 );
            text-transform: uppercase;
            a{
                color: map-get($mau, black-2 );
                font-family: ROBOTO-MEDIUM;
            }
        }
        &__items:hover{
            background: map-get($mau, main);
            a{
                color: map-get($mau, white-op-70);
                
            }
        }
        .active{
            background: map-get($mau, main);
            a{
                color: map-get($mau, white-op-70);
            }
        }
    }
}
@media (max-width: 1367px) {
    .timetable-2__wrapper{
        .timetable-2{
            padding: 30px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &__items{
                width: 190px;
                height: 100px;
            }
        }
    }
}
@media (max-width: 1024px) {
    .timetable-2__wrapper{
        .timetable-2{
            justify-content: space-evenly;
            &__items{
                width: 170px;
                height: 80px;
            }
        }
    }
}
@media (max-width: 768px) {
    .timetable-2__wrapper{
        .container{
            overflow: scroll;
        }
        .timetable-2{
            display: inline-flex;
            flex-wrap: nowrap;
            padding: 20px 0;
            &__items{
                font-size: 12px;
                width: 150px;
                height: 60px;
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 430px) {
    .timetable-2__wrapper{
        .container{
            overflow: scroll;
        }
        .timetable-2{
            display: inline-flex;
            flex-wrap: nowrap;
            padding: 20px 0;
            &__items{
                border-radius: 10px 0px 10px 0px;
                font-size: 12px;
                width: 100px;
                height: 50px;
                margin-right: 10px;
            }
        }
    }
}