.leasing-banner{
    margin-bottom: 104px;
    @media (max-width:1024px) {
        margin-bottom: 64px;
    }
    @media (max-width:430px) {
        margin-bottom: 35px;
    }
    .banner{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        width: 100%;
        // height: 284px;
        position: relative;
        .text{
            width: 100%;
            position: absolute;
            text-transform: uppercase;
            color: white;
            text-align: center;
            .btn{
                border-radius:  20px 0 20px 0;
                padding: 15px;
                text-transform: none;
                background: map-get($mau, orange-1);
                margin-top: 20px;
        }
        
        }
    }
}
@media(max-width:430px){
    .leasing-banner{
        .banner{
            .text{
                .btn{
                    margin-top: 10px;
                    padding: 10px;
                }
            }
        }
    }
}
@media(max-width:320px){
    .leasing-banner{
        .banner{
            .text{
                .btn{
                    margin-top: 5px;
                    padding: 5px;
                }
            }
        }
    }
}