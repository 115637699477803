.ourservices-class{
   
    .title{
        text-align: center;
        text-transform: uppercase;
        font-weight: 100;
        // margin-bottom: 100px;
        &>*{
            text-align: center;
        }
    }
    &__content{
        position: relative;
        .background{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 80%;
            z-index: -1;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    
    
    .container{
        position: relative;
        z-index: 1;
        display: flex;
        // position: absolute;
        flex-direction: row;       
        z-index: 1;
        justify-content: space-between;
        @media (max-width:640px) {
            flex-direction: column;
            align-items: center;
        }
        
        .image-contain{
            position: relative;
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
            display: flex;
            width: 49%;
            padding: 20px;
            // height: 632px;
            background: map-get($mau,white-1);
            justify-content: center;
            z-index: -2;
            align-items: center;
            @media (max-width:1024px) {
                padding: 15px;
            }
            @media (max-width:640px) {
                margin-top: 20px;
                width: 90%;
            }
            &:not(:first-child):after{
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                border-right: 155px solid map-get($mau, main );
                border-top: 155px solid transparent;
                z-index: -1;
                @media (max-width:640px) {
                    border-right: 100px solid map-get($mau, main );
                    border-top: 100px solid transparent;
                }
            }
            &:not(:last-child)::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                border-left: 155px solid map-get($mau, main );
                border-bottom: 155px solid transparent;
                z-index: -1;
                @media (max-width:640px) {
                    border-left: 100px solid map-get($mau, main );
                    border-bottom: 100px solid transparent;
                }
            }
            .image{
                position: relative;
                width: 100%;
                padding-top: 100%;
                
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .btn{
                text-align: center;
                position: absolute;
                text-transform: uppercase;
                z-index: 3;
                bottom: 10%;
                left:50%;
                transform: translateX(-50%);
                padding: 24px 40px;
                border-radius: 50px 0;
                border: 10px solid map-get($mau, text-gray-3);
                background: map-get($mau, main  );
                font-family: ROBOTO-MEDIUM;
                width: 70%;
                @media (max-width:640px) {
                    padding: 15px;
                    border-radius: 30px 0;
                }
            }
            
        }
    }
    
}
// @media ( max-width:1440px){
//     .ourservices-class{
//         .background{
//             .container{
//                 .image-contain{
//                     height: 36vw;
                    
//                 }
//             }
//         }
//     }
// }
// @media ( max-width:1366px){
//     .ourservices-class{
//         .background{
//             .row{     
//                 .image-contain{
//                     width: 462px;
//                     height: 466px;
//                     .image{
//                         width: 430px;
//                         top: 13px;
//                     }
//                     .btn{
//                         left: 25%;
//                         padding: 15px 20px;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media ( max-width:1200px){
//     .ourservices-class{
//         .background{
//             .container{
//                 .image-contain{
//                     height: 40vw;
//                     .image{
//                         position: relative;
//                         width: 95%;
//                         height: 95%;
//                         object-fit: cover;
//                     }
                    
//                 }
//             }
//         }
//     }
// }
// @media ( max-width:1024px){
//     .ourservices-class{
//         .background{
//             .container{
//                 .image-contain{
//                     .btn{
//                         padding: 20px 35px;
//                         border-radius: 30px 0;
//                     }
                    
//                 }
//             }
//         }
//     }
// }
// @media ( max-width:768px){
//     .ourservices-class{
//         .title{
//             margin-bottom: 10vw;
//         }
//         .background{
//             .container{
//                 .image-contain{
//                     &:not(:first-child):after{
//                         border-right: 105px solid map-get($mau, main );
//                         border-top: 105px solid transparent;
//                     }
//                     &:not(:last-child)::after{
//                         border-left: 105px solid map-get($mau, main );
//                         border-bottom: 105px solid transparent;
//                     }
//                     .btn{
//                         width: 70%;
//                         padding: 15px 25px;
//                         border-radius: 30px 0;
//                     }
                    
//                 }
//             }
//         }
//     }
// }

// @media ( max-width:430px){
//     .ourservices-class{
//         .background{
//             .container{
//                 .image-contain{
//                     &:not(:first-child):after{
//                         border-right: 65px solid map-get($mau, main );
//                         border-top: 65px solid transparent;
//                     }
//                     &:not(:last-child)::after{
//                         border-left: 65px solid map-get($mau, main );
//                         border-bottom: 65px solid transparent;
//                     }
//                     .btn{
//                         border: 4px solid #e0e0e0;
//                         padding: 10px 15px;
//                         border-radius: 20px 0;
//                         width: 80%;
//                         font-size: 3vw;
//                     }
                    
//                 }
//             }
//         }
//     }
// }
// @media ( max-width:380px){
//     .ourservices-class{
//         .background{
//             .container{
//                 .image-contain{
//                     &:not(:first-child):after{
//                         border-right: 65px solid map-get($mau, main );
//                         border-top: 65px solid transparent;
//                     }
//                     &:not(:last-child)::after{
//                         border-left: 65px solid map-get($mau, main );
//                         border-bottom: 65px solid transparent;
//                     }
//                     .btn{
//                         padding: 5px 10px;
//                     }
                    
//                 }
//             }
//         }
//     }
// }
