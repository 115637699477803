.home-4__wrapper{
    // padding: 100px 0;
    .home-4{
        display: flex;
        align-items: center;
        position: relative;
        .img-section{
            width: 40%;
            padding-bottom: 26%;
            position: relative;
            @media (max-width: 900px) {
                width: 53%;
            }
            @media (max-width: 768px) {
                padding-bottom: 30%;
            }
            @media (max-width: 640px) {
                width: 100%;
                padding-bottom: 60%;
            }

            img{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text-section{
            width: 60%;
            padding-right:  90px; 
            padding-left:  30px; 
            .text-24{
                font-family: ROBOTO-MEDIUM;
            }
            .text-gray-2{
                // width: 579px;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-align: justify;
                
            }
            *:not(:last-child){
                margin-bottom: 20px;
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }
            @media (max-width: 900px) {
                padding-right:  30px; 
            }
            @media (max-width: 640px) {
                background: map-get($mau, main-op-52 );
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0 30px; 
                display: flex;
                flex-direction: column;
                justify-content: center;
                .text{
                    p{color: map-get($mau, white-1 );}
                }
                .text-gray-2{
                    -webkit-line-clamp: 5;
                }
                *:not(:last-child){
                    margin-bottom: 10px;
                }
            }
            
        }
    }
}


// @media (max-width: 1366px) {
//     .home-4__wrapper{
//         .home-4{
//             .img-section{
//                 height: 100%;
//             }
//             .text-section{
//                 width: 100%;
//                 .text-gray-2{
//                     width: 100%;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 430px) {
//     .home-4__wrapper{
//         .home-4{
//             position: relative;
//             .img-section{
//                height: 230px;
//             }
//             .text-section{
//                 background: map-get($mau, main-op-52 );
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 padding: 0 30px; 
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 .text{
//                     p{color: map-get($mau, white-1 );}
//                 }
//                 .text-gray-2{
//                     -webkit-line-clamp: 4;
//                 }
//                 *:not(:last-child){
//                     margin-bottom: 10px;
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 320px) {
//     .home-4__wrapper{
//         .home-4{
//             position: relative;
//             .img-section{
//                 height: 200px;
//             }
           
//         }
//     }
// }