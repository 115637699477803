.infor-our-club-2__wrapper{
    .infor-our-club-2{
        display: flex;
        &__left{
            &--title{
                margin: 40px 0;
                p{
                    margin-bottom: 18px;
                }
                .title{
                    text-transform: uppercase;
                }
            }
            &--decs{
                display: flex;
                flex-direction: column;
                align-items: center;
                .decs{
                    line-height: 1.6;
                    margin-bottom: 22px;
                }
                .image-section{
                    margin: 40px 0;
                    text-align: center;
                    width: 1044px;
                    height: 100%;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    .image-decs{
                        margin-top: 20px;
                    }
                }
            }
        }
       
    }
}
@media (max-width:1366px) {
    .infor-our-club-2__wrapper{
        .infor-our-club-2{
            display: flex;
            &__left{
                &--decs{
                    .image-section{
                        width: 80%;
                        
                    }
                }
            }
           
        }
    }
}

@media (max-width: 1200px) {
    .infor-our-club-2__wrapper{
        .infor-our-club-2{
            display: flex;
          
         
        }
    }
}
@media (max-width: 1199px) {
    .infor-our-club-2__wrapper{
        .infor-our-club-2{
            display: unset;
            &__left{
                padding-right: 0;
                width: 100%;
                &--title{
                    margin: 30px 0;
                    p{
                        margin-bottom: 10px;
                    }
                }
                &--decs{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .image-section{
                        margin: 40px 0;
                        text-align: center;
                        width: 60%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: auto;
                        }
                        .image-decs{
                            margin-top: 15px;
                        }
                    }
                }
            }
            
        }
    }
}
@media (max-width: 1024px) {
    .infor-our-club-2__wrapper{
        .infor-our-club-2{
            &__left{
                &--decs{
                    .image-section{
                        margin: 20px 0;
                    }
                }
            }
            
        }
    }
}
@media (max-width: 430px) {
    .infor-our-club-2__wrapper{
        .infor-our-club-2{
            display: unset;
            &__left{
                padding-right: 0;
                width: 100%;
                &--title{
                    margin: 20px 0;
                }
                &--decs{
                    .image-section{
                        margin: 15px 0;
                       
                       
                    }
                }
            }
            
        }
    }
}