.our-club-3__wrapper{
    position: relative;
    &::after{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 433px;
        background: map-get($mau, main );
        content: "";
        z-index: -1;
    }
    .container{
        display: flex;
        align-items: center;
        .our-club-3__left{
            .text-section{
                width: 636px;
                p{
                    margin-bottom: 22px;
                }
                .title{
                    
                    font-family: ROBOTO-MEDIUM;
                }
                .decs{
                    line-height: 1.4;
                    position: relative;
                    &::before{
                        position: absolute;
                        top: 0;
                        left: -50px;
                        content: "";
                        width: 35px;
                        height: 35px;
                        background-image: url("../assets/images/our-club/overlay-desc.png");
                        background-repeat: no-repeat;
                        background-position:center;
                        transform: scaleX(-1);
                        background-size: contain;
                    }
                    &::after{
                        position: absolute;
                        bottom: 0;
                        right: -50px;
                        content: "";
                        width: 35px;
                        height: 35px;
                        background-image: url("../assets/images/our-club/overlay-desc.png");
                        background-repeat: no-repeat;
                        background-position:center;
                        background-size: contain;
                        // transform: rotate(180deg);
                    }
                }
            }
        }
        .our-club-3__right{
            .image-avatar{
                width: 514px;
                height: 537px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            margin-left: auto;
        }
    }
}
@media (max-width: 1366px) {
    .our-club-3__wrapper{
        position: relative;
        &::after{
            height: 333px;
        }
        .container{
            display: flex;
            align-items: center;
            .our-club-3__left{
                width: 55%;
                .text-section{
                    
                    width: 100%;
                    p{
                        margin-bottom: 22px;
                    }
                    
                }
            }
            .our-club-3__right{
                width: 35%;
                .image-avatar{
                    width: 100%;
                    height: 437px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .our-club-3__wrapper{
        position: relative;
        &::after{
            height: 333px;
        }
        .container{
            display: flex;
            align-items: center;
            .our-club-3__left{
                width: 55%;
                .text-section{
                    padding: 0 40px;
                    width: 100%;
                    p{
                        margin-bottom: 22px;
                    }
                    .decs{
                        &::before{
                            width: 27px;
                            height: 27px;
                            background-image: url("../assets/images/our-club/overlay-desc.png");
                            background-repeat: no-repeat;
                            background-position:center;
                            transform: scaleX(-1);
                            background-size: contain;
                        }
                        &::after{
                            position: absolute;
                            bottom: 0;
                            right: -50px;
                            content: "";
                            width: 27px;
                            height: 27px;
                            background-image: url("../assets/images/our-club/overlay-desc.png");
                            background-repeat: no-repeat;
                            background-position:center;
                            background-size: contain;
                            // transform: rotate(180deg);
                        }
                    }
                }
            }
            .our-club-3__right{
                width: 35%;
                .image-avatar{
                    width: 100%;
                    height: 437px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .our-club-3__wrapper{
        margin-top: 60%;
        padding-top: 100px;
        padding-bottom: 30px;
        background: map-get($mau, main );
        position: relative;
        &::after{
            display: none;
        }
        .container{
            display: flex;
            align-items: center;
            flex-direction: column;
            .our-club-3__left{
                width: 100%;
                .text-section{
                    position: relative;
                    width: 100%;
                    p{
                        margin-bottom: 15px;
                    }
                    .decs{
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        position: relative;
                        &::before{
                            display: none;
                        }
                        &::after{
                            display: none;
                        }
                    }
                    &::before{
                        position: absolute;
                        top: 20px;
                        left: 0;
                        content: "";
                        width: 15px;
                        height: 15px;
                        background-image: url("../assets/images/our-club/overlay-desc.png");
                        background-repeat: no-repeat;
                        background-position:center;
                        transform: scaleX(-1);
                        background-size: contain;
                    }
                    &::after{
                        position: absolute;
                        bottom: 20px;
                        right: 0;
                        content: "";
                        width: 15px;
                        height: 15px;
                        background-image: url("../assets/images/our-club/overlay-desc.png");
                        background-repeat: no-repeat;
                        background-position:center;
                        background-size: contain;
                        // transform: rotate(180deg);
                    }
                }
            }
            .our-club-3__right{
                position: absolute;
                top: -50vw;
                left: 50%;
                transform: translate(-50%);
                width: 60%;
                .image-avatar{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .our-club-3__wrapper{
        padding-top: 80px;
        .container{
            .our-club-3__right{
                position: absolute;
                // top: -190px;
                left: 50%;
                transform: translate(-50%);
                width: 60%;
                .image-avatar{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}


@media (max-width: 380px) {
    .our-club-3__wrapper{
        // padding-top: 60px;
    }
}
@media (max-width: 320px) {
    .our-club-3__wrapper{
        padding-top: 70px;
        .container{
            .our-club-3__right{
                // top: -150px;
                
            }
        }
    }
}