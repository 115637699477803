.timetable-3__wrapper{
    .nav-custom{
        animation: fadeEffect 1s;
       
        overflow-x: scroll;
    }
    @keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
    }
    // .myTimeTable{
    //     height: 680px;
    //     position: unset;
    .timetablle-item{
        min-width: 950px;
        height: 680px;
        overflow-y: scroll;
        @media (max-width: 1366px) {
           height: 550px;
                   
        }
        @media (max-width: 900px) {
            height: 500px;
                    
        }
        @media (max-width: 600px) {
            min-width: 850px;
                    
        }
        .timetable-3__slide--items{
            &:nth-child(odd) {
                background: map-get($mau, main-1 );
            }
              
            &:nth-child(even) {
                
                background: map-get($mau, white-1 );
            }
        }
            
            .timetable-3__slide--items{
                @media (max-width: 1366px) {
                    height: 110px;
                }
                @media (max-width: 900px) {
                    height: 100px;
                }
                height: 136px;
                display: flex;
                align-items: center;
                justify-content: space-between;
               
                .items{
                    display: flex;
                    align-items: center;
                    
                    height: 100%;
                    border-left: solid 1px map-get($mau, text-gray-2 );
                    color: map-get($mau,text-gray-1 );
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 1366px) {
                       font-size: 18px;
                    }
                    @media (max-width: 1024px) {
                        font-size: 16px;
                     }
                    font-family: ROBOTO-MEDIUM;
                }
                .slide__name{
                    flex: 0 0 27%;
                    max-width: 27%;
                }
                .slide__month,.slide__time{
                    padding: 0 10px;
                    position: relative;
                    &::after{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 40px;
                        @media (max-width: 1024px) {
                            right: 20px;
                         }
                        width: 0px;
                        height:0px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 12px solid #555;
                        border-top-color: map-get($mau, orange-1 );
                        content: "";
                    }
                    select{
                        color: map-get($mau,text-gray-1 );
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: transparent;
                        cursor: pointer;
                        width: 75%;
                        border: none;
                        z-index: 1;
                        text-transform: uppercase;
                        option{
                            text-transform: uppercase;
                        }
                    }
                }
                .slide__month{
                    
                    justify-content: space-between;
                    padding: 0 40px;
                    @media (max-width: 1200px) {
                        padding: 0 20px;
                     }
                    position: relative;
                    &::before{
                        content: "";
                        background-image: url("../assets/images/time-table/calendar.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 20%;
                        height: 32px;
                        background-size: contain;
                    }
                }
                .slide__time{
                    padding: 0 40px;
                    @media (max-width: 1200px) {
                        padding: 0 20px;
                     }
                    position: relative;
                    justify-content: space-between;
                    select{
                        text-transform: none;
                    }
                    &::before{
                        top: 50%;
                        left: 20px;
                        content: "";
                        background-image: url("../assets/images/time-table/time.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 20%;
                        height: 32px;
                        background-size: contain;
                        
                    }
                    
                }
                .slide__avatar{
                    flex: 0 0 13%;
                    max-width: 13%;
                    .avatar{
                       img{
                           border-radius: 50%;
                           width: 75px;
                           height: 75px;
                           @media (max-width: 600px) {
                                width: 55px;
                                height: 55px;
                                    
                            }
                           object-fit: cover;
                       }
                    }
                }
            }
        }
    }
    