.your-moments-morning__wrapper{
    .container{
        padding: 0;
    }
    .your-moments-morning__title{
        text-align: center;
        margin-bottom: 52px;
        text-transform: uppercase;
        &>*{
            text-align: center;
        }
    }
    .your-moments-morning__pic{
        display: flex;
        height: 872px;
        .mySwiperMorning1{
            width: 78%;
            .swiper-slide{
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .nav-custom{
            width: 20%;
            height: 100%;
        }
        .mySwiperMorning-1{
            
            position: unset;
            width: 100%;
            height: 100%;
            .swiper-slide{
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
@media (max-width: 1366px) {
    .your-moments-morning__wrapper{
        .your-moments-morning__pic{
            display: flex;
            height: 572px;
           
        }
    }
}

@media (max-width: 1024px) {
    .your-moments-morning__wrapper{
        .swiper-scrollbar{
            display: none;
        }
        .your-moments-morning__title{
            margin-bottom: 32px;
        }
        .your-moments-morning__pic{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height:621px;
            .mySwiperMorning1{
                height: 78%;
                width: 100%;
                .swiper-slide{
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            } 
            .nav-custom{
                height: 20%;
                width: 100%;
            }
            .mySwiperMorning-1{
                
                position: unset;
                width: 100%;
                height: 100%;
                .swiper-slide{
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .your-moments-morning__wrapper{
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .your-moments-morning__pic{
            width: 98%;
            height:521px;
           
            
        }
    }
}

@media (max-width: 430px) {
    .your-moments-morning__wrapper{
        .your-moments-morning__title{
            margin-bottom: 22px;
        }
        .your-moments-morning__pic{
            width: 98%;
            height:321px;
           
            
        }
    }
}