.success__wrapper{
    margin-top: 120px;
    @media (max-width: 1366px) {
        margin-top: 80px;
    }
    @media (max-width: 540px) {
        margin-top: 50px;
    }
    .background{
        position: relative;
        width: 100%;
        height: 944px;
        @media (max-width: 768px) {
            height: 658px;
        }
       
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .overlay{
            background: map-get($mau, main-op-52 );
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 20px;
            width: 50%;
            height: auto;
            @media (max-width:1024px) {
                width: 80%;
            }
            max-width: 832px;
            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                justify-content: center;
                .content {
                    text-align: center;
                    justify-self: unset;
                    // width: 70%;
                    
                    text-overflow: ellipsis;
                    padding: 80px 0;
                    @media (max-width: 769px) {
                        // width: 90%;
                        padding: 60px 0;
                    }
                    
                    &>*:not(:last-child){
                        margin-bottom: 30px;
                        @media (max-width: 540px) {
                            margin-bottom: 20px;
                        }
                    }
                    h3{
                        text-transform: uppercase;
                        // white-space: nowrap;
                        font-size: 48px;
                        @media (max-width: 1367px) {
                            font-size: 38px;
                        }
                        @media (max-width: 1201px) {
                            font-size: 34px;
                        }
                        @media (max-width: 1024px) {
                            font-size: 30px;
                        }
                        @media (max-width: 769px) {
                            font-size: 26px;
                        }
                        color: map-get($mau, white-1);
                        // @media (max-width: 640px) {
                        //     white-space: normal;
                        // }
                    }
                    p{
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 70%;
                        font-size: 24px;
                        @media (max-width: 1367px) {
                            font-size: 24px;
                        }
                        @media (max-width: 1201px) {
                            font-size: 22px;
                        }
                        @media (max-width: 1025px) {
                            font-size: 20px;
                        }
                        @media (max-width: 769px) {
                            font-size: 18px;
                        }
                        @media (max-width: 430px) {
                            font-size: 14px;
                        }
                        @media (max-width: 320px) {
                            font-size: 13px;
                        }
                        color: map-get($mau, white-1);
                    }
                }
                
                
            }
           
        }
    }
}
// @media (max-width: 1366px) {
//     .contact-us__wrapper{
//         margin-top: 80px;
//         .background{
//             position: relative;
//             width: 100%;
//             height: 708px;
//             .overlay{
//                 .container {
//                     width: 732px;
//                     .form{
//                         .form-control{
//                             height: 42px;
//                         }
//                         .btn{
//                             text-align: center;
//                             padding: 10px 15px;
//                         }
//                     }
                    
//                 }
            
//             }
//         }
//     }
// }

// @media (max-width: 768px) {
//     .contact-us__wrapper{
//         margin-top: 80px;
//         .background{
//             position: relative;
//             width: 100%;
//             height: 658px;
//             .overlay{
//                 .container {
//                     width: 75%;
//                     .form{
//                         .form-control{
//                             height: 42px;
//                         }
//                         .btn{
//                             text-align: center;
//                             padding: 10px 15px;
//                         }
//                     }
                    
//                 }
            
//             }
//         }
//     }
// }

// @media (max-width: 540px) {
//     .contact-us__wrapper{
//         margin-top: 50px;
//         .background{
//             position: relative;
//             width: 100%;
//             height: 658px;
//             .overlay{
//                 .container {
//                     width: 94%;
//                     .form{
//                         .form-control{
//                             height: 40px;
//                         }
//                         .btn{
//                             text-align: center;
//                             padding: 7px 10px;
//                         }
//                     }
                    
//                 }
            
//             }
//         }
//     }
// }