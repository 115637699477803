.home-8__wrapper{
    .background{
        position: relative;
        width: 100%;
        padding-bottom: 25%;
        min-height: 340px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .overlay{
            background: map-get($mau, main-op-52 );
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .container {
                height: 100%;
                display: flex;
                align-items: center;
                .content{
                    
                    width: 70%;
                    // height: 100%;
                    align-items: flex-start;
                    @media (max-width: 900px) {
                        width: 80%;
                        flex-direction: column;
                        margin: auto;
                        align-items: center;
                    }
                    .left {
                        justify-self: unset;
                        width: 45%;
                        @media (max-width: 900px) {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        h1{
                            color: map-get($mau, white-1);
                            font-size: 48px;
                            @media (max-width: 1367px) {
                                font-size: 38px;
                            }
                            @media (max-width: 1201px) {
                                font-size: 34px;
                            }
                            @media (max-width: 1024px) {
                                font-size: 30px;
                            }
                            @media (max-width: 769px) {
                                font-size: 26px;
                            }
                            @media (max-width: 430px) {
                                font-size: 20px;
                            }
                            @media (max-width: 320px) {
                                font-size: 13px;
                            }
                        }
                        &>*{
                            text-align: left;
                        }

                        .desc{
                            color: map-get($mau, white-1);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .right{
                        margin-top: 7px;
                        margin-left: auto;
                        width: 50%;
                        @media (max-width: 900px) {
                            width: 100%;
                            margin-left: unset;
                        }
                        .form-control{
                            margin-bottom: 8px;
                            width: 80%;
                            height: 40px;
                            @media (max-width: 900px) {
                                width: 100%;
                                
                            }
                            @media (max-width: 540px) {
                                height: 35px;
                                
                            }
                            background: map-get($mau, white-1 );
                            &:first-child{
                                position: relative;
                                &::after{
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: 14px;
                                    width: 0px;
                                    height:0px;
                                    border-left: 8px solid transparent;
                                    border-right: 8px solid transparent;
                                    border-top: 12px solid #555;
                                    border-top-color: map-get($mau, orange-1 );
                                    content: "";
                                }
                            }
                           
                            select,input{
                                cursor: pointer;
                                // position: relative;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                height: 100%;
                                width: 100%;
                                border: 0;
                                background-color: transparent;
                                padding-left: 14px;
                                color: map-get($mau, text-gray-2 );
                                z-index: 1;
                                &::placeholder{
                                    color: map-get($mau, text-gray-2 );
                                }
                            }
                        }
                        .btn{
                            text-align: center;
                            padding: 6px 40px;
                        }
                    }
                }
            }
           
        }
    }
}
// @media (max-width: 1366px) {
// .home-8__wrapper{
//     .background{
//         position: relative;
//         width: 100%;
//         height: 348px;
       
//         .overlay{
//             .container {
//                 .content{
//                     width: 559px;
//                     .right{
//                         .form-control{
//                             width: 300px;
//                             height: 35px;
//                         }
//                     }
//                 }
//             }
           
//         }
//     }
// }
// }

// @media (max-width: 768px) {
//     .home-8__wrapper{
//         .background{
//             height: 248px;
//             .overlay{
//                 .container {
//                     .content{
//                         width: 100%;
//                         height: 100%;
//                         .left{
//                             display: none;
//                         }
//                         .right{
//                             width: 60%;
//                             margin: auto;
//                             .form-control{
                               
//                                 width: 100%;
//                                 height: 35px;
//                             }
//                         }
//                     }
//                 }
               
//             }
//         }
//     }
// }

// @media (max-width: 430px) {
//     .home-8__wrapper{
//         .background{
//             height: 168px;
//             .overlay{
//                 .container {
//                     .content{
                       
//                         .right{
                            
//                             width: 70%;
//                             .form-control{
//                                 height: 25px;
//                                  &:first-child{
//                                     &::after{
//                                         border-left: 5px solid transparent;
//                                         border-right:5px solid transparent;
//                                         border-top: 6px solid #555;
//                                         border-top-color: map-get($mau, orange-1 );
//                                         content: "";
//                                     }
//                                 }
//                             }
//                             .btn{
//                                 padding: 5px 15px;
//                             }
//                         }
//                     }
//                 }
               
//             }
//         }
//     }
// }