.timetable-banner__wrapper{
    .timetable-banner {
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
            min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
            min-height: 250px;
        }
        .banner{
            position: relative;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            img{
                // transform: translateY(60px);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .overlay-text-banner{
                width: 100%;
                position: absolute;
                top: 60%;
                left: 0;
                transform: translateY(-50%);
                z-index: 2;
                @media (max-width: 1024px) {
                    top: 50%;
                }
                .text-section{
                    padding: 105px 80px 70px 80px;
                    background: map-get($mau, main-op-65);
                    width: 55%;
                    @media (max-width: 1024px) {
                        padding: 20px;
                        margin: auto;
                        min-width: 450px;
                    }
                    @media (max-width: 450px) {
                        padding: 20px;
                        margin: auto;
                        min-width: 300px;
                    }
                    .text-24{
                        font-family: ROBOTO-MEDIUM;
                    }
                    .title{
                        text-transform: uppercase;
                         display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    .decs{
                         display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    *:not(:last-child){
                        margin-bottom: 30px;
                        @media (max-width: 450px) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

// @media ( max-width: 1366px) {
//     .timetable-banner__wrapper{
//         .timetable-banner {
//             .banner{
//                 .overlay-text-banner{
//                     width: unset;
//                     left: 150px;
//                     .text-section{
//                         padding: 85px 60px 50px 60px;
//                         background: map-get($mau, main-op-65);
//                         width: 50%;
//                         .title{
//                             text-transform: uppercase;
//                              display: -webkit-box;
//                             -webkit-line-clamp: 2;
//                             -webkit-box-orient: vertical;
//                             overflow: hidden;
//                         }
//                         *:not(:last-child){
//                             margin-bottom: 30px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media ( max-width: 1024px) {
//     .timetable-banner__wrapper{
//         margin-top: 90px;
//         .timetable-banner {
//             height: 100%;
//             .banner{
//                 img{
//                     transform: unset;
//                 }
//                 .overlay-text-banner{
//                     top: 300px;
//                     left: 80px;
//                     .text-section{
//                         padding: 75px 50px 40px 50px;
//                         *:not(:last-child){
//                             margin-bottom: 15px;
//                         }
//                         .decs{
//                             -webkit-line-clamp: 4;
//                             -webkit-box-orient: vertical;
//                             display: -webkit-box;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media ( max-width: 800px) {
//     .timetable-banner__wrapper{
//         margin-top: 80px;
//         .timetable-banner {
//             height: 100%;
//             .banner{
//                 .overlay-text-banner{
//                     top: 50%;
//                     left: 50%;
//                     transform: translate(-50%, -50%);
//                     width: 60%;
//                     .text-section{
//                         width: 100%;
//                         padding: 30px;
//                         *:not(:last-child){
//                             margin-bottom: 10px;
//                         }
//                         .decs{
//                              display: -webkit-box;
//                             -webkit-line-clamp: 3;
//                             -webkit-box-orient: vertical;
//                             overflow: hidden;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media ( max-width: 470px) {
//     .timetable-banner__wrapper{
//         margin-top: 70px;
//         .timetable-banner {
//             .banner{
//                 .overlay-text-banner{
//                     .text-section{
//                         padding: 10px 25px;
                      
//                     }
//                 }
//             }
//         }
//     }
// }
// @media ( max-width: 380px) {
//     .timetable-banner__wrapper{
//         margin-top: 55px;
       
//     }
// }