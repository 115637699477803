.your-moments-banner__wrapper{
    .your-moments-banner {
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
        //    min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
        }
        .banner{
            height: 100%;
            img{
                // transform: translateY(100px);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
        }
        .overlay-circle{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -39px;
            width: 61vh;
            height: 61vh;
            left: 50%;
            transform: translateX(-47%);
            background: map-get($mau, main-op-80 );
            border-radius: 50%;
            padding-bottom: 60px;
            .title{
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .decs{
                width: 80%;
                text-align: center;
                text-align: center;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }
        }
        .overlay-link{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 104px;
            background: map-get($mau, main);
            .container{
                height: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .link-items{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    height: 100%;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: 0.2s ease-in-out;
                    &:hover{
                        background: map-get($mau, white-1 );
                        color: map-get($mau, main );
                    }
                }
            }
            
        }
    }
}
@media ( max-width: 1440px) {
    .your-moments-banner__wrapper{
        .your-moments-banner {
            .banner{
                // img{
                //     transform: translateY(80px);
                // }
                
            }
            .overlay-circle{
                bottom: -60px;
                // width: 485px;
                // height: 61%;
                padding-bottom: 60px;
                .title{
                    width: 80%;
                }
                .decs{
                    width: 80%;
                    text-align: center;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                }
            }
            .overlay-link{
                height: 64px;
                .container{
                    .link-items{
                        font-size: 31px;
                    }
                }
                
            }
        }
    }
    
}
@media ( max-width: 1366px) {
    .your-moments-banner__wrapper{
        .your-moments-banner {
            .overlay-circle{
                bottom: -56px;
                // width: 465px;
                // height: 60%;
                padding-bottom: 60px;
               
            }
            
        }
    }
    
}
@media ( max-width: 1200px) {
    .your-moments-banner__wrapper{
        .your-moments-banner {
            .overlay-circle{
                bottom: -64px;
                // width: 482px;
                // height: 482px;
                padding-bottom: 60px;
                .title{
                    width: 80%;
                    margin-bottom: 20px;
                }
                .decs{
                    width: 80%;
                    text-align: center;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                }
            }
            .overlay-link{
                height: 64px;
                .container{
                    .link-items{
                        font-size: 31px;
                    }
                }
                
            }
        }
    }
}
@media ( max-width: 1024px) {
    .your-moments-banner__wrapper{
        // margin-top: 90px;
        .your-moments-banner {
            height: 100%;
            .banner{
                img{
                    transform: unset;
                }
            }
            .overlay-circle{
                bottom: 16px;
                width: 30vw;
                height: 60%;
                padding-bottom: 0;
                .title{
                    width: 80%;
                    margin-bottom: 0px;
                }
                @media ( max-width: 900px) {
                    .title{
                        font-size: 3vw;
                    }
                    .decs{
                        display: none;
                    }
                }
                
            }
        }
    }
}
@media ( max-width: 768px) {
    .your-moments-banner__wrapper{
        .your-moments-banner {
            .overlay-circle{
                bottom: 12px;
                padding-bottom: 0;
                    .title{
                        font-size: 2.8vw;
                    }
                    .decs{
                        display: none;
                    }
            }
            .overlay-link{
                height: 8vw;
                .container{
                    .link-items{
                        font-size: 3.8vw;
                    }
                }
                
            }
        }
    }
}
@media ( max-width: 430px) {
    .your-moments-banner__wrapper{
        // margin-top: 70px;
        .your-moments-banner {
            height: 100%;
            .banner{
                img{
                    transform: unset;
                }
            }
            .overlay-circle{
                bottom: 7px;
                // width: 30%;
                // height: 60%;
                padding-bottom: 0;
                .title{
                    width: 80%;
                    margin-bottom: 0px;
                    
                }
               
            }
            
        }
    }
}
// @media ( max-width: 320px) {
//     .home-banner__wrapper{
//         margin-top: 55px;
//         .home-banner {
//             height: 100%;
//             .banner{
//                 .overlay-text-banner{
//                     left: 55px;
//                 }
//             }
//             .overlay-pattern{
//                 img{
//                     height: 100%;
//                 }
//             }
//         }
//     }
// }