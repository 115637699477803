.ourservices-banner{
    scroll-behavior: smooth;
    .ourservices-background{
        width: 100%;
        height: calc(100vh - 125px);
        position: relative;
        margin-top: 125px;
        max-width: 1920px;
        max-height: 1080px;
        margin-left: auto;
        margin-right: auto;
        @media ( max-width: 1366px) {
            height: calc(100vh - 80px);
            margin-top: 80px;
        }
        @media ( max-width: 1024px) {
            height: 100%;
            min-height: 350px;
        }
        @media ( max-width: 430px) {
            margin-top: 70px;
        }
        @media ( max-width: 380px) {
            margin-top: 55px;
            min-height: 250px;
        }
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .ourservices-box{
            position: absolute;
            bottom: 0;
            width: 100%;
            
            &__items{
                width: 100%;
                padding-bottom: 70%;
                overflow: hidden;
                
                .img-box{
                    overflow: hidden;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    transition: 0.3s ease-in-out;
                    background: map-get($mau, main); 
                    text-transform: uppercase;
                    // border-radius: 20px;
                    .opacity{
                        overflow: hidden;
                        // border-radius: 20px;
                        bottom: 0;
                        top: 0;
                        left: 0;
                        right: 0;
                        position: absolute;
                        opacity: 0.5;
                        width: 100%;
                        height: 100%;
                        transition: .3s ease;
                    }
                    p{
                        width: 80%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: white;
                        text-transform: uppercase;
                        // width: inherit;
                        text-align: center;
                        @media (max-width: 1640px) {
                            font-size: 2.8vw;
                        }
                        @media (max-width: 540px) {
                            font-size: 3.4vw;
                        }
                    }
                }
                &:hover{
                    .opacity{
                        background-color: white;
                        opacity: 1;
                        img{
                            display: none;
                        }
                    }
                    p{
                        color: #1D5632 ;
                            font-family: ROBOTO-BOLD;
                    }
                }
            }
            // a:hover{
            //     background-color: white;
            //     img{
            //         display: none;
            //     }
            //     p{
            //         color: #1D5632 ;
            //          font-family: ROBOTO-BOLD;
            //     }
            // }
        }
    }

}
// @media (max-width: 1440px) {
//     .ourservices-banner{
//         .ourservices-background{
//             .ourservices-box{
//                 height: 12vw;
                
//             }
//         }
        
//     }
// }
// @media (max-width: 1024px) {
//     .ourservices-banner{
//         .ourservices-background{
//             height: 100%;
//             .ourservices-box{
//                 .img-box{
//                     p{
//                         font-size: 2.5vw;
//                     }
//                 }
//             }
//         }   
//     }
// }
