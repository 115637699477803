.ourservices-otherclass{
    @media (max-width: 1366px) {
        margin-top: 130px;
    }
    @media (max-width: 1024px) {
        margin-top: 100px;
    }
    margin-top: 140px;
    text-align: center;
    .title{
        &>*{
            text-align: center;
        }
    }
    .myServiceSwipe{
        .swiper-slide{
            border-radius: 25px;
            .box{
                cursor: pointer;
                width: 100%;
                height: 423px;
                border-radius: 25px;
                position: relative;
                border:1px solid map-get($mau,main-1);
                padding: 0;
                overflow: hidden;
                .image{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &:hover{
                    text-align: start;
                    display: flex;
                    justify-content: center;
                    .image{
                        display: none;
                    }
                    .flip{
                        position: absolute;
                        width: 304px;
                        height: 220px;
                        top:40px;
                        color: map-get($mau,text-gray-2); 
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;           
                        .flip-text{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 8;
                            overflow: hidden;    
                        }
                        .btn{
                            border: none;
                            padding: 0;
                            color: map-get($mau, orange-1);
                            background: none;
                            margin-top: 20px;
                        }
                    }
                    .text{
                        background-color: map-get($mau,main);
                        p{
                            color: white;
                            font-family: ROBOTO-MEDIUM;
                        }
                    }
                }
                
                .flip{
                   display: none;
                }
                .text{
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    // border-radius:0 0 25px 25px;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 110px;
                    background-color: map-get($mau,main-1);
                    p{
                        color: map-get($mau,main);
                        width:80%;
                        font-family: ROBOTO-MEDIUM;
                    }
                }
               
            }
        }
    }
    .row{
        justify-content: center;
        
    }
}
@media(max-width:1024px){
    .ourservices-otherclass{
        .swiper-button-next
        ,.swiper-button-prev{
            display: none;
        }
        .myServiceSwipe{
            .swiper-slide{
                .box{
                    height: 385px;
                    padding: 0;
                    &:hover{
                        .flip{
                            width: 270px ;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width:768px){
    .ourservices-otherclass{
        .myServiceSwipe{
            .swiper-slide{
                .box{
                    height: 252px;
                    padding: 0;
                    &:hover{
                        .flip{
                            width: 190px ;
                            top: 20px;
                        }
                        .btn{
                            margin-top: 10px;
                        }
                    }
                    .text{
                        height: 25%;
                    }
                }
        }
    }
    }
}
@media(max-width:430px){
    .ourservices-otherclass{
        margin-top: 55px;
        .myServiceSwipe{
            .swiper-slide{
                .box{
                    border-radius: 10px;
                    height: 208px;
                    padding: 0;
                    &:hover{
                        .flip{
                            width: 100%;
                            padding: 0 20px;
                            top:15px;
                            height: 60%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-self: center;
                            .flip-text{
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                font-size: 13px;
                            }
                            .btn{
                                margin-top: 5px;
                            }
                        }
                    }
                    .text{
                        .text-24{
                            font-size: 12px;
                        }
                    }
                    
                }
            }
        }
    }
}
@media(max-width:380px){
    .ourservices-otherclass{
        .myServiceSwipe{
            .swiper-slide{
                .box{
                    height: 168px;
                    
                    
                }
            }
        }
    }
}